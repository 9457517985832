import { cloneDeep, find, forEach, sortBy, sum } from "lodash";

function map(x, in_min, in_max, out_min, out_max) {
    return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

const CombDoorStatusEnum = {
    closed: 0x02,
    partial: 0x04,
    open: 0x08
}

const sensors = {
    'battery_level': {
        unit: 'V/10',
        adapter: (x) => { return x * 0.1 },
        recover: (x) => { return x * 10 },
        percent: (x) => {
            const max = 35
            const min = 0
            return map(x, min, max, 0, 100)
        }
    },
    'antenna_signal': {
        unit: 'N/a',
        adapter: (x) => { return x },
        recover: (x) => { return x },
        percent: (x) => {
            const max = 31
            const min = 0
            return map(x, min, max, 0, 100)
        }
    },
    'residual_battery': {
        unit: 'Seconds',
        adapter: (x) => { return x / 3600 / 24 },
        recover: (x) => { return x * 24 * 3600 },
        percent: (x) => { return x },
    },
    'temperature': {
        unit: '°C/10',
        adapter: (x) => { return x * 0.1 },
        recover: (x) => { return x * 10 },
        percent: (x) => {
            const max = 800
            const min = -50
            return map(x, min, max, 0, 100)
        }
    },
    'pres_atm': {
        unit: 'mBar',
        adapter: (x) => { return x },
        recover: (x) => { return x },
        percent: (x) => {
            const max = 2000
            const min = 0
            return map(x, min, max, 0, 100)
        }
    },
    'humidity': {
        unit: '%',
        adapter: (x) => { return x },
        recover: (x) => { return x },
        percent: (x) => { return x }
    },
    'moved': {
        unit: 'Times',
        adapter: (x) => { return x },
        recover: (x) => { return x },
        percent: (x) => { return -1 }
    },
    'verticality': {
        unit: '°',
        adapter: (x) => { return x },
        recover: (x) => { return x },
        percent: (x) => { return -1 }
    },
    'closed_door': {
        unit: 'Times',
        adapter: (x) => { return x },
        recover: (x) => { return x },
        percent: (x) => { return -1 }
    },
    'comb_door': {
        unit: 'N/a',
        adapter: (x) => { return Object.keys(CombDoorStatusEnum).find(key => CombDoorStatusEnum[key] === x) },
        recover: (x) => { return CombDoorStatusEnum[x] },
        percent: (x) => { return -1 }
    },
    'distance': {
        unit: 'Intervals',
        adapter: (x) => { return x },
        recover: (x) => { return x },
        percent: (x) => {
            const max = 65535
            const min = 0
            return map(x, min, max, 0, 100)
        }
    },
    'open_close_cycles': {
        unit: 'Times',
        adapter: (x) => { return x },
        recover: (x) => { return x },
        percent: (x) => { return -1 }
    },
    'analogic_sensor_1': {
        unit: 'V/100',
        adapter: (x, min, max) => { return map(x, 0, 330, min, max) },
        recover: (x, min, max) => { return map(x, min, max, 0, 330) },
        percent: (x) => {
            const max = 330
            const min = 0
            return map(x, min, max, 0, 100)
        }
    },
    'analogic_sensor_2': {
        unit: 'V/100',
        adapter: (x, min, max) => { return map(x, 0, 330, min, max) },
        recover: (x, min, max) => { return map(x, min, max, 0, 330) },
        percent: (x) => {
            const max = 330
            const min = 0
            return map(x, min, max, 0, 100)
        }
    },
    'analogic_sensor_3': {
        unit: 'V/100',
        adapter: (x, min, max) => { return map(x, 0, 330, min, max) },
        recover: (x, min, max) => { return map(x, min, max, 0, 330) },
        percent: (x) => {
            const max = 330
            const min = 0
            return map(x, min, max, 0, 100)
        }
    },
    'analogic_sensor_4': {
        unit: 'V/100',
        adapter: (x, min, max) => { return map(x, 0, 330, min, max) },
        recover: (x, min, max) => { return map(x, min, max, 0, 330) },
        percent: (x) => {
            const max = 330
            const min = 0
            return map(x, min, max, 0, 100)
        }
    },
    'analogic_sensor': {
        unit: 'V/100',
        adapter: (x, min, max) => { return map(x, 0, 330, min, max) },
        recover: (x, min, max) => { return map(x, min, max, 0, 330) },
        percent: (x) => {
            const max = 330
            const min = 0
            return map(x, min, max, 0, 100)
        }
    },
}

function convertToHumanReadable(sensor, value) {
    if (typeof value === 'number') {
        return sensors[sensor].adapter(value)
    } // TODO: fixa msg
}

function convertToSystemReadable(sensor, value) {
    if (typeof value === 'number') {
        return sensors[sensor].recover(value)
    }
    return 0
}

function convertToHumanReadable_analogic(sensor, value, min, max) {
    if (typeof value === 'number' && typeof min === 'number' && typeof max === 'number') {
        return sensors[sensor].adapter(value, min, max)
    }
    return 0
}

function convertToSystemReadable_comb(sensor, value) {
    if (typeof value === 'string') {
        return sensors[sensor].recover(value)
    }
    return 0
}

function convertToSystemReadable_analogic(sensor, value, min, max) {
    if (typeof value === 'number' && typeof min === 'number' && typeof max === 'number') {
        return sensors[sensor].recover(value, min, max)
    }
    return 0
}

function getPercentValue(sensor, value, gauge = false) {
    if (typeof value === 'number') {
        if (gauge) {
            return sensors[sensor].percent(value) * 0.01
        }
        return sensors[sensor].percent(value)
    }
    return 0
}

function convertDeviceConfigToHumanReadable(config) {
    const convertedConfig = cloneDeep(config)
    for (let key of Object.keys(convertedConfig)) {
        if (convertedConfig[key].thresholds) {
            if (key.includes("analogic_sensor") && typeof convertedConfig[key].ref_min === 'number' && typeof convertedConfig[key].ref_max === 'number') {
                if (!convertedConfig[key].thresholds.min && !convertedConfig[key].thresholds.max) {
                    for (let threshold in convertedConfig[key].thresholds) {
                        convertedConfig[key].thresholds[threshold].value =
                            convertToHumanReadable_analogic(
                                key,
                                convertedConfig[key].thresholds[threshold].value,
                                convertedConfig[key].ref_min,
                                convertedConfig[key].ref_max
                            )
                    }
                } else {
                    if (convertedConfig[key].thresholds.min) {
                        for (let min in convertedConfig[key].thresholds.min) {
                            convertedConfig[key].thresholds.min[min].value =
                                convertToHumanReadable_analogic(
                                    key,
                                    convertedConfig[key].thresholds.min[min].value,
                                    convertedConfig[key].ref_min,
                                    convertedConfig[key].ref_max
                                )
                        }
                    }
                    if (convertedConfig[key].thresholds.max) {
                        for (let max in convertedConfig[key].thresholds.max) {
                            convertedConfig[key].thresholds.max[max].value =
                                convertToHumanReadable_analogic(
                                    key,
                                    convertedConfig[key].thresholds.max[max].value,
                                    convertedConfig[key].ref_min,
                                    convertedConfig[key].ref_max
                                )
                        }
                    }
                }
            } else {
                if (!convertedConfig[key].thresholds.min && !convertedConfig[key].thresholds.max) {
                    for (let threshold in convertedConfig[key].thresholds) {
                        convertedConfig[key].thresholds[threshold].value =
                            convertToHumanReadable(key, convertedConfig[key].thresholds[threshold].value)
                    }
                } else {
                    if (convertedConfig[key].thresholds.min) {
                        for (let min in convertedConfig[key].thresholds.min) {
                            convertedConfig[key].thresholds.min[min].value =
                                convertToHumanReadable(key, convertedConfig[key].thresholds.min[min].value)
                        }
                    }
                    if (convertedConfig[key].thresholds.max) {
                        for (let max in convertedConfig[key].thresholds.max) {
                            convertedConfig[key].thresholds.max[max].value =
                                convertToHumanReadable(key, convertedConfig[key].thresholds.max[max].value)
                        }
                    }
                }
            }
        }
    }
    return convertedConfig
}

function convertDeviceConfigToSystemReadable(config) {
    const convertedConfig = cloneDeep(config)
    for (let key of Object.keys(convertedConfig)) {
        if (convertedConfig[key].thresholds) {
            if (key.includes("comb_door")) {
                if (convertedConfig[key].thresholds.length > 0) {
                    for (let threshold of convertedConfig[key].thresholds) {
                        threshold.value = convertToSystemReadable_comb(key, threshold.value)
                    }
                }
            } else if (key.includes("analogic_sensor") && typeof convertedConfig[key].ref_min === 'number' && typeof convertedConfig[key].ref_max === 'number') {
                if (!convertedConfig[key].thresholds.min && !convertedConfig[key].thresholds.max) {
                    for (let threshold in convertedConfig[key].thresholds) {
                        convertedConfig[key].thresholds[threshold].value =
                            convertToSystemReadable_analogic(
                                key,
                                convertedConfig[key].thresholds[threshold].value,
                                convertedConfig[key].ref_min,
                                convertedConfig[key].ref_max
                            )
                    }
                } else {
                    if (convertedConfig[key].thresholds.min) {
                        for (let min in convertedConfig[key].thresholds.min) {
                            convertedConfig[key].thresholds.min[min].value =
                                convertToSystemReadable_analogic(
                                    key,
                                    convertedConfig[key].thresholds.min[min].value,
                                    convertedConfig[key].ref_min,
                                    convertedConfig[key].ref_max
                                )
                        }
                    }
                    if (convertedConfig[key].thresholds.max) {
                        for (let max in convertedConfig[key].thresholds.max) {
                            convertedConfig[key].thresholds.max[max].value =
                                convertToSystemReadable_analogic(
                                    key,
                                    convertedConfig[key].thresholds.max[max].value,
                                    convertedConfig[key].ref_min,
                                    convertedConfig[key].ref_max
                                )
                        }
                    }
                }
            } else {
                if (!convertedConfig[key].thresholds.min && !convertedConfig[key].thresholds.max) {
                    for (let threshold in convertedConfig[key].thresholds) {
                        convertedConfig[key].thresholds[threshold].value =
                            convertToSystemReadable(key, convertedConfig[key].thresholds[threshold].value)
                    }
                } else {
                    if (convertedConfig[key].thresholds.min) {
                        for (let min in convertedConfig[key].thresholds.min) {
                            convertedConfig[key].thresholds.min[min].value =
                                convertToSystemReadable(key, convertedConfig[key].thresholds.min[min].value)
                        }
                    }
                    if (convertedConfig[key].thresholds.max) {
                        for (let max in convertedConfig[key].thresholds.max) {
                            convertedConfig[key].thresholds.max[max].value =
                                convertToSystemReadable(key, convertedConfig[key].thresholds.max[max].value)
                        }
                    }
                }
            }
        }
    }
    return convertedConfig
}

// @descr Called by checkThresholdsIntegrity: check the uniqueness of
// thresholds severities, pushing found errors in 'errors' input Array.
// This function expects the thresholds Array to exist and to not be empty,
// so a check from caller checkThresholdsIntegrity is required in order to
// provide to checkThresholdsUniqueSeverities a not empty Array
// @param {String} sensor
// @param {Object or Array} thresholds
// @param {Array<String>} errors
function checkThresholdsUniqueSeverities(sensor, thresholds, errors) {
    // @descr Called only by checkThresholdsUniqueSeverities: this is a helper
    // function that works only on Array of thresholds
    // @param {String} sensor
    // @param {Array} thresholds
    // @param {Array<String>} errors
    function _checkThresholdsUniqueSeverities(sensor, thresholds, errors) {
        // Counting severities occurrences with an Object: something like:
        // severities = {
        //     'warning': 2, <--- this is wrong
        //     'alert':   1
        // }
        let severities = {};
        for (const threshold of thresholds) {
            if ('severity' in threshold && threshold.severity) {
                if (severities[threshold.severity] !== undefined) {
                    severities[threshold.severity]++;
                } else {
                    severities[threshold.severity] = 1;
                }
            }
        }
        for (const [severity, occurrences] of Object.entries(severities)) {
            if (occurrences > 1) {
                errors.push(`${sensor}, severity "${severity}" occurred ${occurrences} times`);
            }
        }
    }
    if (Array.isArray(thresholds)) {
        _checkThresholdsUniqueSeverities(sensor, thresholds, errors);
    } else {
        if (thresholds.min) {
            _checkThresholdsUniqueSeverities(sensor, thresholds.min, errors)
        }
        if (thresholds.max) {
            _checkThresholdsUniqueSeverities(sensor, thresholds.max, errors)
        }
    }
}

// @descr Called by checkThresholdsIntegrity: check if thresholds values have
// no duplicates and if thresholds severities are good, pushing found errors
// in 'errors' input Array.
// @param {String} sensor
// @param {Object or Array} thresholds
// @param {Array<String>} errors
function checkThresholdsOrdering(sensor, thresholds, errors) {
    // Helper function, called by _checkThresholdsOrdering: return true
    // if input array has ascending order, else false
    function isSorted(array) {
        return array.every(function(num, idx, arr) {
            return (num < arr[idx + 1]) || (idx === arr.length - 1) ? 1 : 0;
        });
    }
    // Helper function, called by _checkThresholdsOrdering: return true
    // if input array has descending order, else false
    function isReverseSorted(array) {
        return array.every(function(num, idx, arr) {
            return (num > arr[idx + 1]) || (idx === arr.length - 1) ? 1 : 0;
        });
    }
    // Helper function, called by _checkThresholdsOrdering: return true
    // if input array contains duplicates; used to check if thresholds
    // values has duplicates, like in the following (horrible) situation:
    // [
    //    { value: 3, severity: 'warning'},
    //    { value: 3, severity: 'alarm'},
    //    { value: 3, severity: 'critical'} ]
    function hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }
    // @descr Called only by checkThresholdsOrdering: this is a helper
    // function that works only on Array of thresholds
    // @param {String} sensor
    // @param {Array} thresholds
    // @param {Array<String>} errors
    // @param {String} direction: used to see what ordering is required
    // in input thresholds
    function _checkThresholdsOrdering(sensor, thresholds, errors, direction = 'none') {
        // When we have more than 1 threshold, something may go wrong
        if (thresholds.length >= 2) {
            // Sorting thresholds by their values, severities will dispose in
            // a certain way: if after sorting values their severities are
            // sorted too, input thresholds are correct; this check is
            // performed later
            thresholds.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
            // Replacing severities names with numbers because it is
            // easier to compare them this way
            for (const t of thresholds) {
                if (t.severity === 'warning') {
                    t.severity = 1;
                } else if (t.severity === 'alarm') {
                    t.severity = 2;
                } else if (t.severity === 'critical') {
                    t.severity = 3;
                }
            }

            const values = thresholds.reduce((acc, x) => [...acc, x.value], []);
            if (hasDuplicates(values)) {
                errors.push(`${sensor}, duplicate values detected (${values.join(',')})`);
            }

            const severities = thresholds.reduce((acc, x) => [...acc, x.severity], []);
            if (direction === 'min') { // Descending order is required 
                if (!isReverseSorted(severities)) {
                    // console.log(sensor, direction, 'NOT SORTED');
                    errors.push(`${sensor}, threshold direction ${direction}, ` +
                        `fix your threshold severities`);
                }
            } else {
                if (!isSorted(severities)) { // Ascending order is required
                    // console.log(sensor, direction, 'NOT SORTED');
                    if (direction === 'none') {
                        errors.push(`${sensor}, fix your threshold severities`);
                    } else if (direction === 'max') {
                        errors.push(`${sensor}, threshold direction ${direction}, ` +
                            `fix your threshold severities`);
                    }
                }
            }
        }
    }

    if (Array.isArray(thresholds)) {
        // Special case: residual battery sensor, as severity increases, has
        // decreasing threshold values: its threshold is an Array
        if (sensor === 'residual_battery') {
            _checkThresholdsOrdering(sensor, thresholds, errors, 'min');
        } else {
            _checkThresholdsOrdering(sensor, thresholds, errors);
        }
    } else {
        if (thresholds.min) {
            _checkThresholdsOrdering(sensor, thresholds.min, errors, 'min');
        }
        if (thresholds.max) {
            _checkThresholdsOrdering(sensor, thresholds.max, errors, 'max');
        }
    }
}

function checkThresholdsIntegrity(config) {
    const errors = []
    const convertedConfig = cloneDeep(config)
    for (let key of Object.keys(convertedConfig)) {
        if (convertedConfig[key].thresholds) {
            if (
                key.includes("analogic_sensor") &&
                typeof convertedConfig[key].ref_min === 'number' &&
                typeof convertedConfig[key].ref_max === 'number'
            ) {
                if (convertedConfig[key].thresholds.min) {
                    let minCount = 0;
                    for (let min of convertedConfig[key].thresholds.min) {
                        minCount++;
                        if (min.value < convertedConfig[key].ref_min) {
                            errors.push(`${key}, min threshold #${minCount}, value ${min.value} ` +
                                `smaller than granted ${convertedConfig[key].ref_min}`);
                        }
                        if (min.value > convertedConfig[key].ref_max) {
                            errors.push(`${key}, min threshold #${minCount}, value ${min.value} ` +
                                `greater than granted ${convertedConfig[key].ref_max}`);
                        }
                        if (!('severity' in min)) {
                            errors.push(`${key}, min threshold #${minCount}, missing severity`);
                        }
                    }
                } // thresholds.min
                if (convertedConfig[key].thresholds.max) {
                    let maxCount = 0;
                    for (let max of convertedConfig[key].thresholds.max) {
                        maxCount++;
                        if (max.value < convertedConfig[key].ref_min) {
                            errors.push(`${key}, max threshold #${maxCount}, value ${max.value} ` +
                                `smaller than granted ${convertedConfig[key].ref_min}`);
                        }
                        if (max.value > convertedConfig[key].ref_max) {
                            errors.push(`${key}, max threshold #${maxCount}, value ${max.value} ` +
                                `greater than granted ${convertedConfig[key].ref_max}`);
                        }
                        if (!('severity' in max)) {
                            errors.push(`${key}, max threshold #${maxCount}, missing severity`);
                        }
                    }
                } // thresholds.max
                checkThresholdsUniqueSeverities(key, convertedConfig[key].thresholds, errors);
                checkThresholdsOrdering(key, convertedConfig[key].thresholds, errors);
            } // analogic_sensor
            else {
                if (key === 'residual_battery') {
                    if (convertedConfig[key].thresholds.length > 0) {
                        let count = 0;
                        for (const threshold of convertedConfig[key].thresholds) {
                            count++;
                            if (!('value' in threshold)) {
                                errors.push(`${key}, threshold #${count}, missing value`);
                            } else if (threshold.value <= 0) {
                                errors.push(`${key}, min threshold #${count}, ` +
                                    `value ${threshold.value} should be greater than zero days`);
                            }
                            if (!('severity' in threshold)) {
                                errors.push(`${key}, threshold #${count}, missing severity`);
                            }
                        }
                        checkThresholdsUniqueSeverities(key, convertedConfig[key].thresholds, errors);
                        checkThresholdsOrdering(key, convertedConfig[key].thresholds, errors);
                    }
                } // residual_battery
                else if (key === 'temperature') {
                    if (convertedConfig[key].thresholds.min) {
                        let minCount = 0;
                        for (let minThreshold of convertedConfig[key].thresholds.min) {
                            minCount++;
                            if (!('value' in minThreshold)) {
                                errors.push(`${key}, min threshold #${minCount}, missing value`);
                            } else if (minThreshold.value <= -5) {
                                errors.push(`${key}, min threshold #${minCount}, ` +
                                    `value ${minThreshold.value} is lesser than permitted -5`);
                            } else if (minThreshold.value >= 80) {
                                errors.push(`${key}, min threshold #${minCount}, ` +
                                    `value ${minThreshold.value} is greater than permitted 80`);
                            }
                            if (!('timeFilter' in minThreshold)) {
                                errors.push(`${key}, min threshold #${minCount}, missing timeFilter`);
                            } else if (minThreshold.timeFilter < 0) {
                                errors.push(`${key}, min threshold #${minCount}, ` +
                                    `timeFilter ${minThreshold.timeFilter} is not positive`);
                            }
                            if (!('severity' in minThreshold)) {
                                errors.push(`${key}, min threshold #${minCount}, missing severity`);
                            }
                        }
                    }
                    if (convertedConfig[key].thresholds.max) {
                        let maxCount = 0;
                        for (let maxThreshold of convertedConfig[key].thresholds.max) {
                            maxCount++;
                            if (!('value' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing value`);
                            } else if (maxThreshold.value <= -5) {
                                errors.push(`${key}, max threshold #${maxCount}, ` +
                                    `value ${maxThreshold.value} is lesser than permitted -5`);
                            } else if (maxThreshold.value >= 80) {
                                errors.push(`${key}, max threshold #${maxCount}, ` +
                                    `value ${maxThreshold.value} is greater than permitted 80`);
                            }
                            if (!('timeFilter' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing timeFilter`);
                            } else if (maxThreshold.timeFilter < 0) {
                                errors.push(`${key}, max threshold #${maxCount}, ` +
                                    `timeFilter is not positive (${maxThreshold.timeFilter})`);
                            }
                            if (!('severity' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing severity`);
                            }
                        }
                    }
                    checkThresholdsUniqueSeverities(key, convertedConfig[key].thresholds, errors);
                    checkThresholdsOrdering(key, convertedConfig[key].thresholds, errors);
                } // temperature
                else if (key === 'pres_atm') {
                    if (convertedConfig[key].thresholds.min) {
                        let minCount = 0;
                        for (let minThreshold of convertedConfig[key].thresholds.min) {
                            minCount++;
                            if (!('value' in minThreshold)) {
                                errors.push(`${key}, min threshold #${minCount}, missing value`);
                            } else if (minThreshold.value <= 0) {
                                errors.push(`${key}, min threshold #${minCount}, ` +
                                    `value ${minThreshold.value} is smaller than permitted 0`);
                            } else if (minThreshold.value >= 2000) {
                                errors.push(`${key}, min threshold #${minCount}, ` +
                                    `value ${minThreshold.value} is greater than permitted 2000`);
                            }
                            if (!('timeFilter' in minThreshold)) {
                                errors.push(`${key}, min threshold #${minCount}, missing timeFilter`);
                            } else if (minThreshold.timeFilter < 0) {
                                errors.push(`${key}, min threshold #${minCount}, ` +
                                    `timeFilter ${minThreshold.timeFilter} is not positive`);
                            }
                            if (!('severity' in minThreshold)) {
                                errors.push(`${key}, min threshold #${minCount}, missing severity`);
                            }
                        }
                    }
                    if (convertedConfig[key].thresholds.max) {
                        let maxCount = 0;
                        for (let maxThreshold of convertedConfig[key].thresholds.max) {
                            maxCount++;
                            if (!('value' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing value`);
                            } else if (maxThreshold.value <= 0) {
                                errors.push(`${key}, max threshold #${maxCount}, ` +
                                    `value ${maxThreshold.value} is smaller than permitted 0`);
                            } else if (maxThreshold.value >= 2000) {
                                errors.push(`${key}, max threshold #${maxCount}, ` +
                                    `value ${maxThreshold.value} is greater than permitted 2000`);
                            }
                            if (!('timeFilter' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing timeFilter`);
                            } else if (maxThreshold.timeFilter < 0) {
                                errors.push(`${key}, max threshold #${maxCount}, timeFilter ${maxThreshold.timeFilter} is not positive`);
                            }
                            if (!('severity' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing severity`);
                            }
                        }
                    }
                    checkThresholdsUniqueSeverities(key, convertedConfig[key].thresholds, errors);
                    checkThresholdsOrdering(key, convertedConfig[key].thresholds, errors);
                } // pres_atm
                else if (key === 'humidity') {
                    if (convertedConfig[key].thresholds.min) {
                        let minCount = 0;
                        for (let minThreshold of convertedConfig[key].thresholds.min) {
                            minCount++;
                            if (!('value' in minThreshold)) {
                                errors.push(`${key}, min threshold #${minCount}, missing value`);
                            } else if (minThreshold.value <= 0) {
                                errors.push(`${key}, min threshold #${minCount}, ` +
                                    `value ${minThreshold.value} is smaller than permitted 0`);
                            } else if (minThreshold.value >= 100) {
                                errors.push(`${key}, min threshold #${minCount}, ` +
                                    `value ${minThreshold.value} is greater than permitted 100`);
                            }
                            if (!('timeFilter' in minThreshold)) {
                                errors.push(`${key}, min threshold #${minCount}, missing timeFilter`);
                            } else if (minThreshold.timeFilter < 0) {
                                errors.push(`${key}, min threshold #${minCount}, timeFilter ${minThreshold.timeFilter} is not positive`);
                            }
                            if (!('severity' in minThreshold)) {
                                errors.push(`${key}, min threshold #${minCount}, missing severity`);
                            }
                        }
                    }
                    if (convertedConfig[key].thresholds.max) {
                        let maxCount = 0;
                        for (let maxThreshold of convertedConfig[key].thresholds.max) {
                            maxCount++;
                            if (!('value' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing value`);
                            } else if (maxThreshold.value <= 0) {
                                errors.push(`${key}, max threshold #${maxCount}, ` +
                                    `value ${maxThreshold.value} is smaller than permitted 0`);
                            } else if (maxThreshold.value >= 100) {
                                errors.push(`${key}, max threshold #${maxCount}, ` +
                                    `value ${maxThreshold.value} is greater than permitted 100`);
                            }
                            if (!('timeFilter' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing timeFilter`);
                            } else if (maxThreshold.timeFilter < 0) {
                                errors.push(`${key}, max threshold #${maxCount}, timeFilter ${maxThreshold.timeFilter} is not positive`);
                            }
                            if (!('severity' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing severity`);
                            }
                        }
                    }
                    checkThresholdsUniqueSeverities(key, convertedConfig[key].thresholds, errors);
                    checkThresholdsOrdering(key, convertedConfig[key].thresholds, errors);
                } else if (key === 'moved') {
                    if (convertedConfig[key].thresholds.max) {
                        let maxCount = 0;
                        for (let maxThreshold of convertedConfig[key].thresholds.max) {
                            maxCount++;
                            if (!('value' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing value`);
                            } else if (maxThreshold.value < 0) {
                                errors.push(`${key}, max threshold #${maxCount}, value ${maxThreshold.value} is not positive`);
                            }
                            /*if (!('timeFilter' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing timeFilter`);
                            } else if (maxThreshold.timeFilter < 0) {
                                errors.push(`${key}, max threshold #${maxCount}, timeFilter ${maxThreshold.timeFilter} is not positive`);
                            }*/
                            if (!('severity' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing severity`);
                            }
                        }
                    }
                    checkThresholdsUniqueSeverities(key, convertedConfig[key].thresholds, errors);
                    checkThresholdsOrdering(key, convertedConfig[key].thresholds, errors);
                } else if (key === 'verticality') {
                    if (convertedConfig[key].thresholds.min) {
                        let minCount = 0;
                        for (let minThreshold of convertedConfig[key].thresholds.min) {
                            minCount++;
                            if (!('value' in minThreshold)) {
                                errors.push(`${key}, min threshold #${minCount}, missing value`);
                            } else if (minThreshold.value <= 0) {
                                errors.push(`${key}, min threshold #${minCount}, ` +
                                    `value ${minThreshold.value} is smaller than permitted 0`);
                            } else if (minThreshold.value >= 90) {
                                errors.push(`${key}, min threshold #${minCount}, ` +
                                    `value ${minThreshold.value} is greater than permitted 90`);
                            }
                            if (!('timeFilter' in minThreshold)) {
                                errors.push(`${key}, min threshold #${minCount}, missing timeFilter`);
                            } else if (minThreshold.timeFilter < 0) {
                                errors.push(`${key}, min threshold #${minCount}, timeFilter ${minThreshold.timeFilter} is not positive`);
                            }
                            if (!('severity' in minThreshold)) {
                                errors.push(`${key}, min threshold #${minCount}, missing severity`);
                            }
                        }
                    }
                    if (convertedConfig[key].thresholds.max) {
                        let maxCount = 0;
                        for (let maxThreshold of convertedConfig[key].thresholds.max) {
                            maxCount++;
                            if (!('value' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing value`);
                            } else if (maxThreshold.value <= 0) {
                                errors.push(`${key}, max threshold #${maxCount}, ` +
                                    `value ${maxThreshold.value} is smaller than permitted 0`);
                            } else if (maxThreshold.value >= 90) {
                                errors.push(`${key}, max threshold #${maxCount}, ` +
                                    `value ${maxThreshold.value} is greater than permitted 90`);
                            }
                            if (!('timeFilter' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing timeFilter`);
                            } else if (maxThreshold.timeFilter < 0) {
                                errors.push(`${key}, max threshold #${maxCount}, timeFilter ${maxThreshold.timeFilter} is not positive`);
                            }
                            if (!('severity' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing severity`);
                            }
                        }
                    }
                    checkThresholdsUniqueSeverities(key, convertedConfig[key].thresholds, errors);
                    checkThresholdsOrdering(key, convertedConfig[key].thresholds, errors);
                } else if (key === 'comb_door') {
                    if (convertedConfig[key].thresholds.length > 0) {
                        let count = 0;
                        for (const threshold of convertedConfig[key].thresholds) {
                            count++;
                            if (!('value' in threshold)) {
                                errors.push(`${key}, threshold #${count}, missing value`);
                            } else if (threshold.value < 0) {
                                errors.push(`${key}, threshold #${count}, value ${threshold.value} is not positive`);
                            }
                            if (!('severity' in threshold)) {
                                errors.push(`${key}, threshold #${count}, missing severity`);
                            }
                        }
                    }
                    // No check on severities uniqueness because comb_door
                    // sensor can have a maximum of one threshold 
                } else if (key === 'distance') {
                    if (!('baseInterval' in convertedConfig[key])) {
                        errors.push(`${key}, missing baseInterval`);
                    } else if (!(convertedConfig[key].baseInterval)) {
                        errors.push(`${key}, baseInterval value not provided`);
                    } else if (convertedConfig[key].baseInterval < 0) {
                        errors.push(`${key}, baseInterval ${convertedConfig[key].baseInterval} is not positive`);
                    }
                    if (convertedConfig[key].thresholds.max) {
                        let maxCount = 0;
                        for (let maxThreshold of convertedConfig[key].thresholds.max) {
                            maxCount++;
                            if (!('value' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing value`);
                            } else if (maxThreshold.value < 0) {
                                errors.push(`${key}, max threshold #${maxCount}, value ${maxThreshold.value} is not positive`);
                            }
                            if (!('severity' in maxThreshold)) {
                                errors.push(`${key}, max threshold #${maxCount}, missing severity`);
                            }
                        }
                    }
                    checkThresholdsUniqueSeverities(key, convertedConfig[key].thresholds, errors);
                    checkThresholdsOrdering(key, convertedConfig[key].thresholds, errors);
                }
            }
        } // if sensor has thresholds
        else {
            if (key === 'closed_door') {
                const closedDoor = convertedConfig[key];
                console.warn(convertedConfig[key])
                if (!('timeFilter' in closedDoor)) {
                    errors.push(`${key}, missing timeFilter`);
                } else if (!closedDoor.timeFilter) {
                    errors.push(`${key}, provided wrong timeFilter value: ${closedDoor.timeFilter}`);
                }
            }
        } // if sensor has NO thresholds
    }

    if (errors.length > 0) {
        errors.unshift('Wrong configuration provided:');
        return errors.join('\n');
    }
    return '';
}

function getGaugeArcs(sensor_name, config) {
    let thresholds = []
    let arcsLength = []
    let colors = []
    let colors_by_severity = {
        critical: '#EA4228',
        alarm: '#E1740D',
        warning: '#F5CD19',
        none: '#5BE12C'
    }
    if (config[sensor_name].thresholds) {
        if (sensor_name.includes("analogic_sensor") && typeof config[sensor_name].ref_min === 'number' && typeof config[sensor_name].ref_max === 'number') {
            if (!config[sensor_name].thresholds.min && !config[sensor_name].thresholds.max) {
                for (let threshold in config[sensor_name].thresholds) {
                    thresholds.push({
                        value: getPercentValue(sensor_name,
                            convertToSystemReadable_analogic(sensor_name,
                                config[sensor_name].thresholds[threshold].value,
                                config[sensor_name].ref_min,
                                config[sensor_name].ref_max
                            ), true),
                        type: 'min',
                        color: colors_by_severity[config[sensor_name].thresholds[threshold].severity]
                    })
                }
            } else {
                if (config[sensor_name].thresholds.min) {
                    for (let min in config[sensor_name].thresholds.min) {
                        thresholds.push({
                            value: getPercentValue(sensor_name,
                                convertToSystemReadable_analogic(sensor_name,
                                    config[sensor_name].thresholds.min[min].value,
                                    config[sensor_name].ref_min,
                                    config[sensor_name].ref_max
                                ), true),
                            type: 'min',
                            color: colors_by_severity[config[sensor_name].thresholds.min[min].severity]
                        })
                    }
                }
                if (config[sensor_name].thresholds.max) {
                    for (let max in config[sensor_name].thresholds.max) {
                        thresholds.push({
                            value: getPercentValue(sensor_name,
                                convertToSystemReadable_analogic(sensor_name,
                                    config[sensor_name].thresholds.max[max].value,
                                    config[sensor_name].ref_min,
                                    config[sensor_name].ref_max
                                ), true),
                            type: 'max',
                            color: colors_by_severity[config[sensor_name].thresholds.max[max].severity]
                        })
                    }
                }
            }
        } else {
            if (!config[sensor_name].thresholds.min && !config[sensor_name].thresholds.max) {
                for (let threshold in config[sensor_name].thresholds) {
                    thresholds.push({
                        value: getPercentValue(sensor_name,
                            convertToSystemReadable(sensor_name,
                                config[sensor_name].thresholds[threshold].value
                            ), true),
                        type: 'min',
                        color: colors_by_severity[config[sensor_name].thresholds[threshold].severity]
                    })
                }
            } else {
                if (config[sensor_name].thresholds.min) {
                    for (let min in config[sensor_name].thresholds.min) {
                        thresholds.push({
                            value: getPercentValue(sensor_name,
                                convertToSystemReadable(sensor_name,
                                    config[sensor_name].thresholds.min[min].value
                                ), true),
                            type: 'min',
                            color: colors_by_severity[config[sensor_name].thresholds.min[min].severity]
                        })
                    }
                }
                if (config[sensor_name].thresholds.max) {
                    for (let max in config[sensor_name].thresholds.max) {
                        thresholds.push({
                            value: getPercentValue(sensor_name,
                                convertToSystemReadable(sensor_name,
                                    config[sensor_name].thresholds.max[max].value
                                ), true),
                            type: 'max',
                            color: colors_by_severity[config[sensor_name].thresholds.max[max].severity]
                        })
                    }
                }
            }
        }
    }
    thresholds = sortBy(thresholds, ["value"])
        //console.log(thresholds)
    if (thresholds.length > 0) {
        forEach(thresholds, (th, i) => {
            if (th.type === 'min') {
                if (i === 0)
                    arcsLength.push(th.value)
                else
                    arcsLength.push(th.value - thresholds[i - 1].value)
                colors.push(th.color)
            }
        })
        if (find(thresholds, ['type', 'max'])) {
            if (arcsLength.length > 0)
                arcsLength.push(find(thresholds, ['type', 'max']).value - sum(arcsLength))
            else
                arcsLength.push(find(thresholds, ['type', 'max']).value)
            colors.push(colors_by_severity['none'])
            forEach(thresholds, (th, i) => {
                if (th.type === 'max') {
                    if (i < thresholds.length - 1)
                        arcsLength.push(thresholds[i + 1].value - th.value)
                    else
                        arcsLength.push(1 - th.value)
                    colors.push(th.color)
                }
            })
        } else {
            arcsLength.push(1 - sum(arcsLength))
            colors.push(colors_by_severity['none'])
        }
    } else {
        arcsLength.push(1)
        colors.push(colors_by_severity['none'])
    }
    return {
        arcsLength,
        colors
    }
}

// Periodi cablati di hb in base a sp
const hbPeriods = {
    1: [1, 2, 5, 10, 20, 30, 60, 120, 240, 480, 720, 1440],
    2: [2, 4, 10, 20, 30, 40, 60, 120, 240, 480, 720, 1440],
    5: [5, 10, 15, 30, 45, 60, 120, 240, 480, 720, 1440],
    10: [10, 20, 30, 60, 120, 240, 480, 720, 1440],
    20: [20, 40, 60, 120, 240, 480, 720, 1440],
    30: [30, 60, 90, 120, 240, 480, 720, 1440],
    60: [60, 120, 240, 480, 720, 1440],
}

function getSamplePeriodList() {
    return Object.keys(hbPeriods)
}

function getHeartbeatPeriodList(sP) {
    if (getSamplePeriodList().includes(sP.toString()))
        return hbPeriods[sP]
    return []
}

export {
    convertToHumanReadable,
    convertToSystemReadable,
    convertToHumanReadable_analogic,
    convertToSystemReadable_analogic,
    getPercentValue,
    convertDeviceConfigToHumanReadable,
    convertDeviceConfigToSystemReadable,
    checkThresholdsIntegrity,
    getGaugeArcs,
    getHeartbeatPeriodList,
    getSamplePeriodList
}