// import socketIOClient from "socket.io-client";
import { io } from "socket.io-client";
import store from './redux/store'
import {
    setTriggerDataRefresh, setTriggerAlertsDataRefresh, setTriggerDeviceDataRefresh, setTriggerDeviceDetailsDataRefresh
} from './redux/reducers/dashboardSlice';
const ENDPOINT = `http${process.env.REACT_APP_USE_HTTPS === 'yes' ? "s" : ""}://${process.env.REACT_APP_BK_IPV4_ADDRESS}`;
const API_ROUTE = "/API/"
const WS_ROUTE = "/socket.io/"
let md5 = require('md5')
let socket = undefined
let session_key = null

async function Logout(self = false) {
    if (self) {
        //console.log(ENDPOINT)
        let credentials = {
            session_key
        }
        const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session_key': session_key,
                'api_name': 'logout'
            },
            body: JSON.stringify(credentials)
        })
        const response = await rawResponse.json()
        if (response.accepted) {
            localStorage.clear()
            console.log("Logging Out... ", response.message)

            setTimeout(() => window.location.reload(), 500)
        }
    } else {
        localStorage.clear()
        console.log("Logging Out")
        window.location.reload();
    }
}

/**
 * Function to start Socket connection between specific client and API server
 *
 * @param {*} user username text
 * @param {*} sites sites JS Array with sites id or name
 */
function StartSocketConnection(user, sites, _session_key) {
    session_key = _session_key
    //console.log("trying to connect", user, "on sites id:", sites)
    // socket = socketIOClient(ENDPOINT + WS_ROUTE);
    socket = io(ENDPOINT);

    socket.on('connect', function () { // TIP: you can avoid listening on `connect` and listen on events directly too!
        console.log("connected! Socket ID: " + socket.id + " on rooms: " + sites)
        socket.emit("sites", { 'sites': sites, 'user': user })
        socket.emit("session_key", { 'session_key': _session_key, 'user': user })
    });

    socket.on('AlertsChanged', function (data) {
        // Gestire il refresh dei dati
        // Aggiorno il TreeView e la mappa (tranne leafSite)
        if (store.getState().dashboard.triggerDataRefresh === false) {
            let check = false
            const navPath = store.getState().dashboard.navbarSelectionPath
            const userPath = store.getState().dashboard.userSitePath.split('.')
            let actual_path = userPath
            let data_path = data.path.split('.')
            for (let e in navPath) {
                if (!actual_path.includes(navPath[e].name))
                    actual_path.push(navPath[e].name)
            }
            actual_path.forEach((e, i) => { if (data_path[i] === e) check = true })
            if (check) { // Refresh all sites of the path
                for (let e in navPath) {
                    setTimeout(() => store.dispatch(setTriggerDataRefresh(Number(navPath[e].siteId))), 200)
                }
            }
        }
        // Aggiorno il LeafSite e i Device
        if (store.getState().dashboard.siteType !== 'not_leaf') {
            let viewed_devices = store.getState().dashboard.poiCollection
            for (let device of viewed_devices) {
                if (device.content.serialNumber === data.serial_number.toLowerCase()) {
                    setTimeout(() => {
                        store.dispatch(setTriggerDataRefresh(Number(data.site_id)))
                        store.dispatch(setTriggerDeviceDataRefresh('close'))
                    }, 200)
                }
            }
        }
        if (store.getState().dashboard.selectedDevice === data.serial_number.toLowerCase()) {
            setTimeout(() => {store.dispatch(setTriggerDeviceDetailsDataRefresh(true))}, 200)
        }
        // Aggiorno gli Alerts
        if (store.getState().dashboard.triggerAlertsDataRefresh === false)
            setTimeout(() => {store.dispatch(setTriggerAlertsDataRefresh(true))}, 200)
    });

    socket.on('MeasuresChanged', function (data) {
        // Aggiorno il LeafSite e i Device
        if (store.getState().dashboard.siteType !== 'not_leaf') {
            let viewed_devices = store.getState().dashboard.poiCollection
            for (let device of viewed_devices) {
                if (device.content.serialNumber === data.serial_number.toLowerCase()) {
                    store.dispatch(setTriggerDeviceDataRefresh('close'))
                }
            }
        }
        if (store.getState().dashboard.selectedDevice === data.serial_number.toLowerCase()) {
            setTimeout(() => {store.dispatch(setTriggerDeviceDetailsDataRefresh(true))}, 200)
        }
    });

    socket.on('logout', () => {
        Logout()
    })
}

/**
 * Function to call try_login API
 *
 * @param {*} user username text
 * @param {*} pwd password text
 * @return {*} false if wrong login or the user table ROW of the selected user JSON format
 */
async function TryLogin(user, pwd) {
    //console.log(ENDPOINT)
    let credentials = {
        "username": user,
        "password": md5(pwd)
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'api_name': 'try_login'
        },
        body: JSON.stringify(credentials)
    })
    const user_data = await rawResponse.json()
    //console.log("user_data: ", user_data)
    return user_data
}

/**
 * Function to call get_tree_view API
 *
 * @param {*} siteid number
 * @return {*} JSON object
 */
async function GetTreeView(siteid, siteidlevel) {
    let body = {
        "siteId": siteid,
        "siteIdLevel": siteidlevel
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'get_tree_view'
        },
        body: JSON.stringify(body)
    })
    const tree_view = await rawResponse.json()
    if (tree_view.logout) Logout()
    //console.log("tree_view: ", tree_view)
    return tree_view
}

/**
 * Function to call get_tree_view API
 *
 * @param {*} siteid number
 * @return {*} JSON object
 */
 async function GetLogs() {
    let body = {
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'get_logs'
        },
        body: JSON.stringify(body)
    })
    const logs = await rawResponse.json()
    if (logs.logout) Logout()
    //console.log("tree_view: ", tree_view)
    return logs["logs"]
}

/**
 * Function to call get_analytics_from_site API
 *
 * @param {*} siteid number
 * @return {*} JSON object
 */
async function GetSiteAnalytics(siteid) {
    let body = {
        "siteId": siteid
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'get_analytics_from_site'
        },
        body: JSON.stringify(body)
    })
    const analytics = await rawResponse.json()
    if (analytics.logout) Logout()
    //console.log("analytics: ", analytics)
    return analytics
}

async function GetSiteDetails(siteId) {
    //get_site_details
    let body = {
        "siteId": siteId
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'get_site_details'
        },
        body: JSON.stringify(body)
    })
    const siteDetails = await rawResponse.json()
    if (siteDetails.logout) Logout()
    //console.log("siteDetails: ", siteDetails)
    return siteDetails
}

/**
 * Function to call get_userprofile_pic API
 *
 * @param {*} username string
 * @return {*} JSON object
 */
async function GetUserProfilePic(username) {
    let body = {
        "username": username
    }
    const binaryImage = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'image/png',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'get_userprofile_pic'
        },
        body: JSON.stringify(body)
    })
    const base64ProfilePic = await binaryImage.text()
    if (base64ProfilePic.indexOf("logout") > 0)
        Logout()
    else if (base64ProfilePic.indexOf("accepted") === -1)
        return base64ProfilePic
    else
        return ""
}

/**
 * Function to create user and add it on a selected site
 *
 * @param {string} username
 * @param {string} email
 * @param {string} password
 * @param {string/base64} [userProfilePic=undefined]
 * @return {boolean} 
 */
async function CreateUserProfile(siteId, permissions, username, email, password, profilePictureFile = undefined) {
    let filename = null
    if (siteId && profilePictureFile && username) {
        filename = `${username}.png`
        let body = {
            file: profilePictureFile
        }
        try {
            const resp = await fetch(ENDPOINT + API_ROUTE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session_key': session_key,
                    'api_name': 'upload_image',
                    'type': 'profilePic',
                    'file_name': filename
                },
                body: JSON.stringify(body)
            })
            const response = await resp.json()
            if (response.logout) Logout()
            if (!response.accepted)
                return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }
    if (siteId && username && email && password) {
        let body = {
            site_id: siteId,
            permissions,
            username,
            email,
            profile_pic_path: filename,
            password: md5(password),
        }
        const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session_key': session_key,
                'api_name': 'create_profile'
            },
            body: JSON.stringify(body)
        })
        const response = await rawResponse.json()
        if (response.logout) Logout()
        //console.log("devices_table: ", devices_table)
        return response
    }
}

/**
 * Function to call update_profile API
 *
 * @param {string} username
 * @param {string} email
 * @param {string} newPassword
 * @param {string} oldPassword
 * @param {string/base64} [profilePictureFile=undefined]
 * @return {*} 
 */
async function UpdateUserProfile(username, email, newPassword, oldPassword, profilePictureFile = undefined) {
    let filename = null
    if (profilePictureFile && username) {
        filename = `${username}.png`
        let body = {
            file: profilePictureFile
        }
        try {
            const resp = await fetch(ENDPOINT + API_ROUTE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session_key': session_key,
                    'api_name': 'upload_image',
                    'type': 'profilePic',
                    'file_name': filename
                },
                body: JSON.stringify(body)
            })
            const response = await resp.json()
            if (response.logout) Logout()
            if (!response.accepted)
                return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }
    if (username && email && oldPassword) {
        let body = {
            username,
            email,
            filename,
            'oldPassword': md5(oldPassword),
            'newPassword': newPassword ? md5(newPassword) : undefined
        }
        const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session_key': session_key,
                'api_name': 'update_profile'
            },
            body: JSON.stringify(body)
        })
        const response = await rawResponse.json()
        if (response.logout) Logout()
        //console.log("devices_table: ", devices_table)
        return response
    }
}

/**
 * Function to Update Selected User
 *
 * @param {string} username
 * @param {string} myPassword
 * @param {object} permissions
 * @param {boolean} user_disabled
 * @param {string} [newPassword=undefined]
 * @return {object} 
 */
async function UpdateSelectedUser(myusername, myPassword, username, permissions, user_disabled, newPassword = undefined) {
    if (username && myPassword && permissions) {
        let body = {
            myusername,
            myPassword: md5(myPassword),
            username,
            settings: {
                users_management: permissions.users_management,
                sites_management: permissions.sites_management,
                devices_management: permissions.devices_management,
                notifications_management: permissions.notifications_management,
                user_disabled
            }
        }
        if (newPassword) body.settings.password = md5(newPassword)
        const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session_key': session_key,
                'api_name': 'update_selected_user'
            },
            body: JSON.stringify(body)
        })
        const response = await rawResponse.json()
        if (response.logout) Logout()
        //console.log("devices_table: ", devices_table)
        return response
    }
}

/**
 *  Function to Get the list of users of a site
 *
 * @param {number} site_id
 * @return {array<object>} 
 */
async function GetUsersList(site_id) {
    let body = {
        site_id
    }
    const binaryImage = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'get_users_list'
        },
        body: JSON.stringify(body)
    })
    const res = await binaryImage.json()
    if (res.logout) Logout()
    if (res.accepted) {
        return res.users_list
    } else
        return false
}

/**
 * Function to call get_site_planimetry API
 *
 * @param {*} siteid number
 * @return {*} JSON object
 */
async function GetSitePlanimetry(siteid) {
    let body = {
        "siteId": siteid
    }
    const binaryImage = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'get_site_planimetry'
        },
        body: JSON.stringify(body)
    })
    const base64Planimetry = await binaryImage.text()
    if (base64Planimetry.indexOf("logout") > 0)
        Logout()
    else if (base64Planimetry.indexOf("accepted") === -1)
        return base64Planimetry
    else
        return ""
}

/**
 * Function to call get_alert_table API
 *
 * @param {*} siteid number
 * @return {*} JSON object
 */
async function GetAlertTable(siteid) {
    let body = {
        "siteId": siteid
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'get_alert_table'
        },
        body: JSON.stringify(body)
    })
    const alert_table = await rawResponse.json()
    if (alert_table.logout) Logout()
    //console.log("alert_table of siteid", siteid, ":", alert_table)
    return alert_table
}

/**
 * Function to call get_devices_table API
 *
 * @param {*} siteid number
 * @return {*} JSON object
 */
async function GetDevicesTable(siteid, categoryId = 0) {
    let body = {
        "siteId": Number(siteid),
        "deviceCategoryId": categoryId
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'get_devices_table'
        },
        body: JSON.stringify(body)
    })
    const devices_table = await rawResponse.json()
    if (devices_table.logout) Logout()
    //console.log("devices_table: ", devices_table)
    return devices_table
}

/**
 * Function to call get_device_details API
 *
 * @param {*} serialNumber string
 * @return {*} JSON object
 */
async function GetDeviceDetails(serialNumber, siteId) {
    let body = {
        serialNumber,
        siteId
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'get_device_details'
        },
        body: JSON.stringify(body)
    })
    const device_details = await rawResponse.json()
    if (device_details.logout || !device_details.accepted) Logout()
    //console.log("devices_table: ", devices_table)
    return device_details
}

/**
 * Function to call get_default_configuration API
 *
 * @param {*} deviceType string
 * @return {*} JSON object
 */
async function GetDefaultConfiguration(deviceType) {
    let body = {
        deviceType
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'get_default_configuration'
        },
        body: JSON.stringify(body)
    })
    const device_configuration = await rawResponse.json()
    if (device_configuration.logout) Logout()
    //console.log("devices_table: ", devices_table)
    return device_configuration
}

// Funzioni di modifica e inserimento dati

/**
 * Function to call update_device_configuration API
 *
 * @param {*} serialNumber string
 * @param {*} newConfig object
 * @return {*} JSON object
 */
async function UpdateDeviceConfiguration(serialNumber, siteId, newConfig) {
    let body = {
        serialNumber,
        siteId,
        newConfig
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'update_device_configuration'
        },
        body: JSON.stringify(body)
    })
    const response = await rawResponse.json()
    if (response.logout) Logout()
    //console.log("devices_table: ", devices_table)
    return response
}

/**
 * Function to call user_takes_alerts API
 *
 * @param {*} username string
 * @param {*} alertCollection array of alerts id
 * @param {*} action "take" or "clear"
 * @return {*} JSON object
 */
async function UserTakesAlerts(siteId, username, alertCollection, action) {
    let body = {
        siteId,
        username,
        alertCollection,
        session_key,
        action
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'user_takes_alerts'
        },
        body: JSON.stringify(body)
    })
    const response = await rawResponse.json()
    if (response.logout) Logout()
    //console.log("devices_table: ", devices_table)
    return response
}

/**
 * Function to call update_geo_position API
 *
 * @param {*} idElement string
 * @param {*} type string
 * @param {*} newCoords LatLng()
 * @return {*} JSON object
 */
async function UpdateGeoPosition(idElement, type, newCoords) {
    let body = {
        "id_element": idElement,
        "type": type,
        "new_coordinates": { lat: newCoords.lat, lon: newCoords.lng }
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'update_geo_position'
        },
        body: JSON.stringify(body)
    })
    const response = await rawResponse.json()
    if (response.logout) Logout()
    //console.log("devices_table: ", devices_table)
    return response
}

/**
 * Function to call insert_new_site API
 *
 * @param {*} siteId number
 * @param {*} startConfiguration object
 * @return {*} JSON object
 */
async function InsertNewSite(siteId, startConfiguration, planimetryFile = undefined) {
    if (startConfiguration.planimetry !== undefined) {
        let body = {
            file: planimetryFile
        }
        try {
            const resp = await fetch(ENDPOINT + API_ROUTE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session_key': session_key,
                    'api_name': 'upload_image',
                    'type': 'planimetry',
                    'file_name': startConfiguration.planimetry.file_name
                },
                body: JSON.stringify(body)
            })
            const response = await resp.json()
            if (response.logout) Logout()
            if (!response.accepted)
                return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }
    let body = {
        "siteId": siteId,
        "startConfiguration": startConfiguration
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'insert_new_site'
        },
        body: JSON.stringify(body)
    })
    const response = await rawResponse.json()
    if (response.logout) Logout()
    return response
}

/**
 * Function to call update_site API
 *
 * @param {*} siteId number
 * @param {*} columns object
 * @return {*} JSON object
 */
async function UpdateSite(siteId, columns, planimetryFile = undefined) {
    if (columns.planimetry_file_name !== undefined) {
        let body = {
            file: planimetryFile
        }
        try {
            const resp = await fetch(ENDPOINT + API_ROUTE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session_key': session_key,
                    'api_name': 'upload_image',
                    'type': 'planimetry',
                    'file_name': columns.planimetry_file_name
                },
                body: JSON.stringify(body)
            })
            const response = await resp.json()
            if (response.logout) Logout()
            if (!response.accepted)
                return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }
    if (siteId !== undefined) {
        let body = {
            siteId,
            columns,
        }
        const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session_key': session_key,
                'api_name': 'update_site'
            },
            body: JSON.stringify(body)
        })
        const response = await rawResponse.json()
        if (response.logout) Logout()
        //console.log("devices_table: ", devices_table)
        return response
    }
}

async function UpdatePlanimetryCorners(siteId, corners) {
    // [0]: Top left, [1]: Top right, [2]: Bottom left, [3]: Bottom right
    const newCorners = {
        lat_br_planimetry: corners[3].lat,
        lon_br_planimetry: corners[3].lng,
        lat_bl_planimetry: corners[2].lat,
        lon_bl_planimetry: corners[2].lng,
        lat_tl_planimetry: corners[0].lat,
        lon_tl_planimetry: corners[0].lng,
        lat_tr_planimetry: corners[1].lat,
        lon_tr_planimetry: corners[1].lng,
    }
    return await UpdateSite(siteId, newCorners)
}

/**
 * Function to call insert_new_device API
 *
 * @param {*} siteId number
 * @param {*} startConfiguration object
 * @return {*} JSON object
 */
async function InsertNewDevice(startConfiguration) {
    let body = {
        "startConfiguration": startConfiguration
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'insert_new_device'
        },
        body: JSON.stringify(body)
    })
    const response = await rawResponse.json()
    if (response.logout) Logout()
    //console.log("devices_table: ", devices_table)
    return response
}

/**
 * Function to delete a device with a serial number
 *
 * @param {string} serialNumber
 * @return {boolean} 
 */
async function DeleteDevice(serialNumber) {
    let body = {
        serialNumber
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'delete_device'
        },
        body: JSON.stringify(body)
    })
    const response = await rawResponse.json()
    if (response.logout) Logout()
    //console.log("devices_table: ", devices_table)
    return response
}

/**
 *  Function to reset the battery life of a device
 *
 * @param {string} serialNumber
 * @return {boolean} 
 */
async function ResetBattery(serialNumber) {
    let body = {
        serialNumber
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'reset_battery'
        },
        body: JSON.stringify(body)
    })
    const response = await rawResponse.json()
    if (response.logout) Logout()
    //console.log("devices_table: ", devices_table)
    return response
}

/**
 * Function to delete a site with a site ID
 *
 * @param {number} siteId
 * @return {boolean} 
 */
async function DeleteSite(siteId) {
    let body = {
        siteId
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'delete_site'
        },
        body: JSON.stringify(body)
    })
    const response = await rawResponse.json()
    if (response.logout) Logout()
    //console.log("devices_table: ", devices_table)
    return response.accepted || false
}

/**
 * Function to delete an user with his username
 *
 * @param {string} username
 * @return {boolean} 
 */
async function DeleteUser(username) {
    let body = {
        username
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'delete_user'
        },
        body: JSON.stringify(body)
    })
    const response = await rawResponse.json()
    if (response.logout) Logout()
    //console.log("devices_table: ", devices_table)
    return response
}

/**
 * Function to Search device from SN, text1 or text2
 *
 * @param {string} columnName
 * @param {string} value
 * @return {array<string>} 
 */
async function SearchInTextFields(siteId, columnName, value) {
    let body = {
        siteId,
        columnName,
        value
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'search_text_fields'
        },
        body: JSON.stringify(body)
    })
    const response = await rawResponse.json()
    if (response.logout) Logout()
    if (response.accepted) return response.devices
    return false
}

//call get_serialized_configuration
async function GetStringifiedNFCSStruct(sn) {
    let body = {
        serialNumber: sn
    }
    const rawResponse = await fetch(ENDPOINT + API_ROUTE, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'session_key': session_key,
            'api_name': 'get_serialized_configuration'
        },
        body: JSON.stringify(body)
    })
    const response = await rawResponse.json()
    if (response.logout) Logout()
    if (response.accepted) return response.serialized_configuration
    return false
}

export {
    GetLogs,
    Logout,
    TryLogin,
    GetUserProfilePic,
    UpdateUserProfile,
    UpdateSelectedUser,
    GetUsersList,
    StartSocketConnection,
    GetSiteAnalytics,
    GetSiteDetails,
    GetSitePlanimetry,
    GetTreeView,
    GetAlertTable,
    GetDevicesTable,
    GetDeviceDetails,
    GetDefaultConfiguration,
    UpdateDeviceConfiguration,
    UpdateGeoPosition,
    UserTakesAlerts,
    UpdateSite,
    UpdatePlanimetryCorners,
    InsertNewSite,
    InsertNewDevice,
    CreateUserProfile,
    DeleteDevice,
    DeleteSite,
    DeleteUser,
    SearchInTextFields,
    ResetBattery,
    GetStringifiedNFCSStruct
}