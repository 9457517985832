import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import { geocodeAsync } from '../utils/geocoder';
import { useDispatch } from 'react-redux'
import { setLocationFinderResult } from '../redux/reducers/dashboardSlice';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function LocationFinder() {
    const classes = useStyles();
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const dispatch = useDispatch()

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                geocodeAsync(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch(inputValue, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            id="autocompleteLocationFinder"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.display_name)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            fullWidth
            tabIndex={1}
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                dispatch(setLocationFinderResult(newValue))
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label="Add a location" variant="outlined" fullWidth />
            )}
            renderOption={(option) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                            <span style={{ fontWeight: 400 }}>
                                {option.address.county}
                            </span>

                            <Typography variant="body2" color="textSecondary">
                                {option.display_name}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}