export default function getRemainingDays(activation_ts: number, current_ts: number, battery_percentage: number) {
    function getRemainingMilliseconds(activationDate: number, currentDate: number, percentage: number): number {
        if (percentage >= 95) {
            return -1
        } else {
            const using_time = currentDate - activationDate
            const using_percentage = 100 - battery_percentage
            const remaining_millisecond = (using_time * battery_percentage) / using_percentage
            return remaining_millisecond
        }
    }
    if (activation_ts === 0 || current_ts === 0 || battery_percentage === 0) {
        return { 
            remainingDays: 'Not Available'
        }
    } else {
        const remaining_time = getRemainingMilliseconds(activation_ts, current_ts, battery_percentage)
        const remainingDays = remaining_time > -1 ? `Approximately ${(remaining_time / (60 * 60 * 24)).toFixed(0)} days` : 'More than 2 years!'
    
        return {
            remainingDays
        }
    }
}
