import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    selectSelectedSite,
    selectSelectedSiteName,
    selectDevicePanelRows,
    selectTriggerDeviceDataRefresh,
    setPoiCollection,
    setDevicePanelRows,
    setMaxZoom,
    setTriggerDeviceDataRefresh,
    setIsLoading,
    setDeviceDetailsPanelOpen,
    setSelectedDevice,
    selectSiteType
} from '../redux/reducers/dashboardSlice';
import { GetDevicesTable } from "../BkConnect"
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import PropTypes from 'prop-types';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import {
    renderOperativeStatus,
    renderDeviceTypeById,
    renderSeverity,
} from './renderer';
import "../Components/MyDevicePanel.css"
import { Button } from "@material-ui/core";

function CustomPagination(props) {
    const { paginationProps } = props;

    return (
        <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={paginationProps.page}
            count={paginationProps.pageCount}
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event, value) => paginationProps.setPage(value)}
        />
    );
}

CustomPagination.propTypes = {
    /**
     * The object containing all pagination details in [[PaginationProps]].
     */
    paginationProps: PropTypes.shape({
        page: PropTypes.number.isRequired,
        pageCount: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        rowCount: PropTypes.number.isRequired,
        setPage: PropTypes.func.isRequired,
        setPageSize: PropTypes.func.isRequired,
    }).isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        border: 0,
        color:
            theme.palette.type === 'light'
                ? 'rgba(0,0,0,.85)'
                : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
            borderRight: `1px solid ${
                theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid ${
                theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-cell': {
            color:
                theme.palette.type === 'light'
                    ? 'rgba(0,0,0,.85)'
                    : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        '& .MuiDataGrid-overlayContent': {
            flexDirection: 'column',
            alignItems: 'center',
        },
        '& .ant-empty-img-1': {
            fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
            fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
        },
    },
    label: {
        marginTop: theme.spacing(1),
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

function CustomNoRowsOverlay() {
    const classes = useStyles();

    return (
        <GridOverlay className={classes.root}>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <div className={classes.label}>No Devices <EmojiEmotionsIcon /></div>
        </GridOverlay>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const _columns = [
    {
        field: 'id',
        headerName: 'ID',
        type: 'number',
        width: 5,
        hide: true
    },
    {
        field: 'deviceTypeName',
        headerName: 'Device Type',
        type: 'string',
        width: 250,
        renderCell: renderDeviceTypeById
    },
    {
        field: 'positioning',
        headerName: 'Positioning',
        type: 'string',
        width: 160,
    },
    {
        field: 'description',
        headerName: 'Description',
        type: 'string',
        width: 160,
    },
    {
        field: 'operativeStatus',
        headerName: 'Status',
        type: 'string',
        width: 150,
        renderCell: renderOperativeStatus
    },
    {
        field: 'serialNumber',
        headerName: "Serial Number",
        type: 'string',
        width: 170,
    },
    {
        field: 'maxSeverity',
        headerName: 'Max Severity',
        type: 'string',
        width: 120,
        renderCell: renderSeverity
    },
    {
        field: 'numberCurrentAlerts',
        headerName: 'Alerts',
        type: 'string',
        width: 100,
    },
    {
        field: 'lat',
        headerName: 'Latitude',
        type: 'number',
        width: 110,
        hide: true
    },
    {
        field: 'lon',
        headerName: 'Longitude',
        type: 'number',
        width: 110,
        hide: true
    },
    {
        field: 'siteId',
        headerName: 'Site ID',
        type: 'number',
        width: 80,
        hide: true
    },
    {
        field: 'config',
        headerName: 'Config',
        type: 'string',
        hide: true,
        width: 105
    },
]

const sortModel = [
    {
        field: 'numberCurrentAlerts',
        sort: 'desc',
    },
];

function useData(rowsCollection) {
    const [data, setData] = useState({ columns: [], rows: [] });

    useEffect(() => {
        const rows = [];
        let i = 0
        for (let _row in rowsCollection) {
            const row = {
                id: i,
            }
            i++
            for (let _col in rowsCollection[_row]) {
                row[_col] = rowsCollection[_row][_col];
            }
            rows.push(row);
        }

        const columns = [{ field: 'id', hide: true }];

        for (let _col in _columns) {
            if (typeof _columns[_col].renderCell !== 'undefined' && typeof _columns[_col].valueGetter !== 'undefined')
                columns.push({
                    field: _columns[_col].field,
                    headerName: _columns[_col].headerName,
                    type: _columns[_col].type,
                    width: _columns[_col].width,
                    renderCell: _columns[_col].renderCell,
                    valueGetter: _columns[_col].valueGetter,
                    sortComparator: _columns[_col].sortComparator
                });
            else if (typeof _columns[_col].renderCell !== 'undefined' && typeof _columns[_col].valueGetter == 'undefined')
                columns.push({
                    field: _columns[_col].field,
                    headerName: _columns[_col].headerName,
                    type: _columns[_col].type,
                    width: _columns[_col].width,
                    renderCell: _columns[_col].renderCell
                });
            else if (typeof _columns[_col].hide !== 'undefined')
                columns.push({
                    field: _columns[_col].field,
                    headerName: _columns[_col].headerName,
                    type: _columns[_col].type,
                    width: _columns[_col].width,
                    hide: _columns[_col].hide,
                });
            else
                columns.push({
                    field: _columns[_col].field,
                    headerName: _columns[_col].headerName,
                    width: _columns[_col].width,
                    type: _columns[_col].type
                });
        }

        setData({
            rows,
            columns,
        });
    }, [rowsCollection]);

    //console.log("DATI REALI:",data)
    return data;
}

export function MyDevicePanel(props) {
    const [openPanel, setOpenPanel] = useState(false);
    const [internalSelectedDevice, setInternalSelectedDevice] = useState(true);
    const dispatch = useDispatch()
    const rows = useSelector(selectDevicePanelRows)
    const selectedSiteId = useSelector(selectSelectedSite)
    const selectedSiteType = useSelector(selectSiteType)
    const selectedSiteName = useSelector(selectSelectedSiteName)
    const triggerDataRefresh = useSelector(selectTriggerDeviceDataRefresh)
    const classes = useStyles();

    async function updateData(siteId) {
        let _tab = await GetDevicesTable(siteId, 0)
        //console.log(_tab)
        if (_tab.length === 0) return
        let _poiCollection = []
        let _rows = []
        _tab.device_table.forEach((e) => {
            let thisRow = {
                id: e.id,
                description: e.description,
                serialNumber: e.serialNumber,
                operativeStatus: e.operativeStatus,
                deviceTypeId: e.deviceTypeId,
                deviceTypeName: e.deviceTypeName,
                lat: e.lat,
                lon: e.lon,
                siteId: e.siteId,
                positioning: e.positioning,
                siteDescription: e.siteDescription,
                config: e.config,
                numberCurrentAlerts: e.numberCurrentAlerts,
                maxSeverity: e.maxSeverity,
            }
            _rows.push(thisRow)
            _poiCollection.push({
                key: e.id,
                position: [e.lat, e.lon],
                content: {
                    name: e.name,
                    siteId: e.siteId,
                    serialNumber: e.serialNumber,
                    description: e.description,
                    operativeStatus: e.operativeStatus,
                    deviceTypeName: e.deviceTypeName,
                    alerts: e.numberCurrentAlerts,
                    maxSeverity: e.maxSeverity,
                    positioning: e.positioning
                }
            })
        })
        //console.log(_rows)
        dispatch(setPoiCollection(_poiCollection))
        dispatch(setMaxZoom(22))
        dispatch(setDevicePanelRows(_rows))
        dispatch(setTriggerDeviceDataRefresh(false))
        dispatch(setIsLoading(false))
    }

    useEffect(() => {
        dispatch(setIsLoading(true))
        async function stopper() {
            await updateData(selectedSiteId)
        }
        switch (selectedSiteType) {
            case 'leaf_with_devices':
                stopper()
            break
            case 'leaf_without_devices':
                dispatch(setPoiCollection([]))
            break
            case 'not_leaf':

            break
            default:
                break
        }
    }, [selectedSiteId, selectedSiteType]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (typeof triggerDataRefresh === 'boolean') {
            if (!openPanel) {
                return
            }
            dispatch(setIsLoading(true)) // Enable loading spinner
            updateData(selectedSiteId)
        } else if (typeof triggerDataRefresh === 'string' && triggerDataRefresh === 'open') {
            setOpenPanel(true)
            dispatch(setIsLoading(true)) // Enable loading spinner
            updateData(selectedSiteId)
        } else if (typeof triggerDataRefresh === 'string' && triggerDataRefresh === 'close') {
            setOpenPanel(false)
            dispatch(setIsLoading(true)) // Enable loading spinner
            updateData(selectedSiteId)
        }
    }, [triggerDataRefresh, selectedSiteId]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleClickOpen = async () => {
        setOpenPanel(true)
    };

    const handleClose = () => {
        setOpenPanel(false)
    };

    const handleSelectDevice = (devices) => {
        if (devices.rows.length === 0 && triggerDataRefresh) {
            updateData(selectedSiteId)
        }
        let deviceId = undefined
        if (devices.rows.length > 0) {
            deviceId = devices.rows[0].serialNumber
            setInternalSelectedDevice(deviceId)
        }
    };

    const handleOpenDetails = () => {
        dispatch(setSelectedDevice(internalSelectedDevice))
        dispatch(setDeviceDetailsPanelOpen(true))
        setInternalSelectedDevice(true)
    };

    return (
        <>
            <Tooltip title="Show Device Table" placement="left" aria-label="add">
                <Fab color="primary" aria-label="add" className={"btnOpenDeviceView"} onClick={handleClickOpen}>
                    <Typography variant="h6" className={classes.alertCounterText}>{rows.length}</Typography>
                </Fab>
            </Tooltip>
            <Dialog fullScreen open={openPanel} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} color="default">
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>Devices for {selectedSiteName}</Typography>
                        {internalSelectedDevice === true ? <></> : <Button variant="contained" color="secondary" onClick={handleOpenDetails}>Details</Button> }
                        <IconButton color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            sortModel={sortModel}
                            className={classes.root}
                            onSelectionChange={handleSelectDevice}
                            disableMultipleSelection={true}
                            pageSize={15}
                            components={{
                                noRowsOverlay: CustomNoRowsOverlay,
                                pagination: CustomPagination,
                            }}
                            columnBuffer={2}
                            {...useData(rows)}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    );
}