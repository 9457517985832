import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  setIsLoading,
  setSelectedSite,
  setBase64Planimetry,
  setSelectedDevice,
  setDeviceDetailsPanelOpen,
  selectSelectedSite,
  selectPlanimetry,
  selectBase64Planimetry,
  selectMaxZoom,
  selectPoiCollection,
  selectIsLeaf,
  selectPermissions,
  selectPlanimetryEditMode,
  selectAddSiteOnTheMap,
  selectNewSiteName,
  setAddSiteOnTeMap,
  setNewSiteName,
  setNewSitePlanimetryFile,
  selectNewSitePlanimetryFile,
  setTriggerDataRefresh,
  selectAddEditSiteAction,
  selectSelectedSiteName,
  selectSelectedSiteCoordinates,
  selectAddDeviceOnTheMap,
  setAddDeviceOnTheMap,
  setTriggerDeviceDataRefresh,
  setPlanimetry,
  selectAddUserOnSite,
  setAddUserOnSite,
  selectUserSite,
  selectUsername,
  selectSiteType,
  selectNavbarSelectionPath,
  setSiteToRemove,
  setForceParentChangeType,
  setUserSiteType,
  setUserSiteIsLeaf,
  setSiteType,
  setIsLeaf,
  selectLocationFinderResult,
  setPoiCollectionAsync,
} from '../redux/reducers/dashboardSlice';
import {
  GetSitePlanimetry,
  UpdateGeoPosition,
  InsertNewSite,
  UpdateSite,
  InsertNewDevice,
  UpdatePlanimetryCorners,
  CreateUserProfile,
  DeleteSite,
  GetUsersList,
  UpdateSelectedUser
} from "../BkConnect";
import PropTypes from 'prop-types';
import { Map, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import Control from 'react-leaflet-control';
import ReactDistortableImageOverlay from 'react-leaflet-distortable-imageoverlay'
import { DivIcon, Point, LatLng } from "leaflet";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField'
import Badge from '@material-ui/core/Badge';
import EditMapControl from './EditMapControl';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import {
  Typography, Button, FormControl, InputLabel, Select, MenuItem, makeStyles,
  Paper, useMediaQuery, Avatar, Box, AppBar, Tabs, Tab, List, ListItem,
  ListItemAvatar, ListItemText, ListItemSecondaryAction, Checkbox, FormControlLabel, Switch, Grid, Fab, Tooltip, IconButton
} from "@material-ui/core";
import TouchAppIcon from '@material-ui/icons/TouchApp';
import EditIcon from '@material-ui/icons/Edit';
import getRenderPropsFromDeviceType, { getDeviceList } from './renderer/deviceTypeRenderUtility'
import { useSnackbar } from "notistack";
import { DropzoneDialog } from 'material-ui-dropzone'
import ImgView from 'material-ui-image'
import { useTheme } from '@material-ui/core/styles';
import "../Components/MyNavBar.css"
import Draggable from 'react-draggable';
import { clone, cloneDeep, find, findIndex } from "lodash";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import SwipeableViews from 'react-swipeable-views';
import "../Components/Map.css";
import { RenderStandaloneAvatar, FillProfilePicCollection } from "./renderer";
import CloseIcon from '@material-ui/icons/Close';
import LocationFinder from "./LocationFinder";
import { Label, NfcOutlined } from "@material-ui/icons";
import { getHeartbeatPeriodList, getSamplePeriodList } from "../utils/measures_converter";
import NFC from "../utils/NFC";

function getSeverityIcon(max_severity, label, isLeaf, deviceTypeName = undefined, labelComponent = "p", zoom = undefined) {
  let className = "mapPointer"
  let background = ""
  let displayedLabel = label
  if (isLeaf) {
    let renderProps = getRenderPropsFromDeviceType(deviceTypeName, max_severity)
    background = `url(${renderProps.svg})`
    displayedLabel = renderProps.label
  } else {
    switch (max_severity) {
      case "critical":
        background = "url(images/icons/map-marker-red.svg)"
        break
      case "alarm":
        background = "url(images/icons/map-marker-orange.svg)"
        break
      case "warning":
        background = "url(images/icons/map-marker-yellow.svg)"
        break
      case "none":
        background = "url(images/icons/map-marker-green.svg)"
        break
      default:
        background = "url(images/icons/map-marker-green.svg)"
        break
    }
  }
  //console.log(background)
  return new DivIcon({
    iconSize: new Point(200, 70),
    className: "marker",
    iconAnchor: [100, 100],
    popupAnchor: [0, -100],
    html: `<div class='icondiv-container'><div style='background-image: ${background}' class='${className}'></div><div class='pointerShadow'></div><${labelComponent}>${displayedLabel}</${labelComponent}></div>`,
  })
}

const useStyles = makeStyles((theme) => ({
  white: {
    '& label.Mui-focused': {
      color: 'white',
    },
  },
  red: {
    '& label.Mui-focused': {
      color: 'red',
    },
  },
  formControl: {
    marginBottom: theme.spacing(1),
  },
  userForm: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(2),
    },
    buttons: {
      marginTop: theme.spacing(2),
    },
    checkbox: {
      margin: theme.spacing(2)
    }
  }
}));

/*
* POI COLLECTION STRUCTURE:
* markers: [
  *    { key: 'marker1', position: [51.5, -0.1], content: 'My first popup' },
  *    { key: 'marker2', position: [51.51, -0.1], content: 'My second popup' },
  *    { key: 'marker3', position: [51.49, -0.05], content: 'My third popup' },
  * ]
  * 
  */
let defaultCorners = [
  new LatLng(0.001, 0),
  new LatLng(0.001, 0.001),
  new LatLng(0, 0),
  new LatLng(0, 0.001),
]

const r_earth = 6371000 // Raggio terra
const scale_factor = 25 // Px/M 

function getNewLatLonFromOffset(center, x, y) {
  let new_latitude = center.lat + (y / r_earth) * (180 / Math.PI);
  let new_longitude = center.lon + (x / r_earth) * (180 / Math.PI) / Math.cos(center.lat * Math.PI / 180);
  return {
    lat: new_latitude,
    lon: new_longitude
  }
}

function getAutoPlanimetryCornersFromSiteLocation(center, width, height) {
  const scaledWidth = width / scale_factor
  const scaledHeight = height / scale_factor
  let corners = {
    tr: getNewLatLonFromOffset(center, Math.abs(scaledWidth / 2), Math.abs(scaledHeight / 2)),
    tl: getNewLatLonFromOffset(center, -(scaledWidth / 2), Math.abs(scaledHeight / 2)),
    br: getNewLatLonFromOffset(center, Math.abs(scaledWidth / 2), -(scaledHeight / 2)),
    bl: getNewLatLonFromOffset(center, -(scaledWidth / 2), -(scaledHeight / 2)),
  }
  return corners
}

const ConfirmDeleteSiteDialog = () => {
  const [openConfirmDialog, setOpenConfigDialog] = useState(false)
  const parentSiteId = useSelector(selectSelectedSite)
  const parentSiteName = useSelector(selectSelectedSiteName)
  const navbarSelection = useSelector(selectNavbarSelectionPath)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenConfirmDialog = () => {
    setOpenConfigDialog(true)
  };

  const handleCloseDialog = async (_confirm) => {
    if (_confirm) {
      const res = await DeleteSite(parentSiteId)
      if (res) {
        enqueueSnackbar(`Succesful deleted ${parentSiteName}! reloading...`, { variant: 'success' })
        dispatch(setIsLoading(true))
        dispatch(setSiteToRemove(parentSiteId))
        // go to parent site
        setTimeout(() => dispatch(setSelectedSite(navbarSelection[findIndex(navbarSelection, ['siteId', parentSiteId]) - 1].siteId)), 500)
        //setTimeout(() => window.location.reload(), 1000)
        dispatch(setAddSiteOnTeMap(false))
      } else {
        enqueueSnackbar("Server rejected the delete action...", { variant: 'error' })
      }
    }
    setOpenConfigDialog(false)
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={handleOpenConfirmDialog}>Delete Site</Button>
      <Dialog
        open={openConfirmDialog}
        onClose={() => handleCloseDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Really want to delete ${parentSiteName}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By clicking on "CONFIRM" button you allow to delete this site permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog(false)} color="secondary">CANCEL</Button>
          <Button onClick={() => handleCloseDialog(true)} color="secondary" autoFocus>CONFIRM</Button>
        </DialogActions>
      </Dialog>
    </>
  )
};

const MyAddEditSiteDialog = () => {
  const addEditSiteOnTeMap = useSelector(selectAddSiteOnTheMap)
  const popupAction = useSelector(selectAddEditSiteAction)
  const siteName = useSelector(selectNewSiteName)
  const siteIsLeaf = useSelector(selectIsLeaf)
  const siteType = useSelector(selectSiteType)
  const parentSiteId = useSelector(selectSelectedSite)
  const userSiteId = useSelector(selectUserSite)
  const userSite = useSelector(selectUserSite)
  const parentSiteName = useSelector(selectSelectedSiteName)
  const parentSitePlanimetry = useSelector(selectBase64Planimetry)
  const parentSiteCoordinates = useSelector(selectSelectedSiteCoordinates)
  const planFile = useSelector(selectNewSitePlanimetryFile)
  const navbarSelection = useSelector(selectNavbarSelectionPath)
  const [openUploader, setOpenUploader] = React.useState(false)
  const locationFinderResult = useSelector(selectLocationFinderResult)
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const classes = useStyles()

  const updateForm = (e) => {
    switch (e.target.id) {
      case 'name':
        dispatch(setNewSiteName(e.target.value))
        break
      default:
        break
    }
  }

  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    })
  }

  const addPlanimetryFile = async (files) => {
    if (files.length > 0) {
      try {
        let file = files[0];
        const _base64Planimetry = await readFileAsync(file);
        dispatch(setNewSitePlanimetryFile(_base64Planimetry))
        setOpenUploader(false)
      } catch (err) {
        console.log(err);
      }
    }
  }

  const handleClose = async (action) => {
    switch (action) {
      case 'cancel':
        dispatch(setNewSitePlanimetryFile(""))
        dispatch(setNewSiteName(""))
        break
      case 'apply':
        if (popupAction) { // se è una insert
          let siteConfig = {
            name: siteName,
            lat: parentSiteCoordinates.lat,
            lon: parentSiteCoordinates.lon,
            planimetry: undefined
          }
          if (locationFinderResult) {
            siteConfig.lat = locationFinderResult.lat
            siteConfig.lon = locationFinderResult.lon
          }
          if (planFile !== "") {
            let i = new Image()
            i.onload = async () => {
              const newPlanCorners = getAutoPlanimetryCornersFromSiteLocation(parentSiteCoordinates, i.width, i.height)
              let planimetryConfig = {
                file_name: `${siteName.replace(/ /g, '_')}_${parentSiteId}.jpg`,
                lat_br: newPlanCorners.br.lat,
                lon_br: newPlanCorners.br.lon,
                lat_bl: newPlanCorners.bl.lat,
                lon_bl: newPlanCorners.bl.lon,
                lat_tl: newPlanCorners.tl.lat,
                lon_tl: newPlanCorners.tl.lon,
                lat_tr: newPlanCorners.tr.lat,
                lon_tr: newPlanCorners.tr.lon,
              }
              siteConfig.planimetry = planimetryConfig
              let res = await InsertNewSite(parentSiteId, siteConfig, planFile)
              if (res) {
                enqueueSnackbar("Succesful updated new site with planimetry on the server!", { variant: 'success' })
                dispatch(setIsLoading(true))
                // Controllo se il sito padre è il sito dell'utente e controllo che il sitetype sia not leaf altrimenti aggiorno i cookies
                if (parentSiteId === userSiteId && siteType !== 'not_leaf') {
                  localStorage.setItem("siteType", 'not_leaf')
                  localStorage.setItem("siteIsLeaf", 'false')
                  dispatch(setUserSiteType('not_leaf'))
                  dispatch(setUserSiteIsLeaf(false))
                  dispatch(setSiteType('not_leaf'))
                  dispatch(setIsLeaf(false))
                  setTimeout(() => { dispatch(setTriggerDataRefresh(parentSiteId)) }, 100)
                } else {
                  dispatch(setTriggerDataRefresh(true))
                }
                // go to parent site
                setTimeout(() => {
                  dispatch(setSelectedSite(parentSiteId));
                  //dispatch(setSelectedSite(navbarSelection[findIndex(navbarSelection, ['siteId', parentSiteId]) - 1].siteId));
                  dispatch(setIsLoading(false))
                }, 500)

                //setTimeout(() => { dispatch(setSelectedSite(parentSiteId)); dispatch(setIsLoading(false)) }, 500)
                //setTimeout(() => window.location.reload(), 1000)
              } else {
                enqueueSnackbar("Server rejected the update...", { variant: 'error' })
              }
            }
            i.src = planFile
          } else {
            let res = await InsertNewSite(parentSiteId, siteConfig)
            if (res) {
              enqueueSnackbar("Succesful updated new site on the server!", { variant: 'success' })
              dispatch(setIsLoading(true))
              // Controllo se il sito padre è il sito dell'utente e controllo che il sitetype sia not leaf altrimenti aggiorno i cookies
              if (parentSiteId === userSiteId && siteType !== 'not_leaf') {
                localStorage.setItem("siteType", 'not_leaf')
                localStorage.setItem("siteIsLeaf", 'false')
                dispatch(setUserSiteType('not_leaf'))
                dispatch(setUserSiteIsLeaf(false))
                dispatch(setSiteType('not_leaf'))
                dispatch(setIsLeaf(false))
                setTimeout(() => { dispatch(setTriggerDataRefresh(parentSiteId)) }, 100)
              } else {
                dispatch(setTriggerDataRefresh(true))
              }
              // go to parent site
              setTimeout(() => {
                //dispatch(setSelectedSite(navbarSelection[findIndex(navbarSelection, ['siteId', parentSiteId]) - 1].siteId));
                dispatch(setSelectedSite(parentSiteId));
                dispatch(setIsLoading(false))
              }, 500)

              //setTimeout(() => { dispatch(setSelectedSite(parentSiteId)); dispatch(setIsLoading(false)) }, 500)
              //setTimeout(() => window.location.reload(), 1000)
            } else {
              enqueueSnackbar("Server rejected the update...", { variant: 'error' })
            }
          }
        } else { // se è un update
          let siteConfig = {
            name: siteName,
          }
          if (parentSitePlanimetry !== planFile && siteIsLeaf) {
            if (planFile !== "") {
              let i = new Image()
              i.onload = async () => {
                const newPlanCorners = getAutoPlanimetryCornersFromSiteLocation(parentSiteCoordinates, i.width, i.height)
                siteConfig.planimetry_file_name = `${siteName.replace(/ /g, '_')}_${parentSiteId}.jpg`
                siteConfig.lat_br_planimetry = newPlanCorners.br.lat
                siteConfig.lon_br_planimetry = newPlanCorners.br.lon
                siteConfig.lat_bl_planimetry = newPlanCorners.bl.lat
                siteConfig.lon_bl_planimetry = newPlanCorners.bl.lon
                siteConfig.lat_tl_planimetry = newPlanCorners.tl.lat
                siteConfig.lon_tl_planimetry = newPlanCorners.tl.lon
                siteConfig.lat_tr_planimetry = newPlanCorners.tr.lat
                siteConfig.lon_tr_planimetry = newPlanCorners.tr.lon
                let res = await UpdateSite(parentSiteId, siteConfig, planFile)
                if (res) {
                  enqueueSnackbar("Succesful added planimetry file!", { variant: 'success' })
                  dispatch(setPlanimetry({
                    file_name: siteConfig.planimetry_file_name,
                    lat_br: siteConfig.lat_br_planimetry,
                    lat_bl: siteConfig.lat_bl_planimetry,
                    lat_tl: siteConfig.lat_tl_planimetry,
                    lat_tr: siteConfig.lat_tr_planimetry,
                    lon_bl: siteConfig.lon_bl_planimetry,
                    lon_br: siteConfig.lon_br_planimetry,
                    lon_tl: siteConfig.lon_tl_planimetry,
                    lon_tr: siteConfig.lon_tr_planimetry,
                  }))
                  dispatch(setBase64Planimetry(planFile))
                } else {
                  enqueueSnackbar("Server rejected the update...", { variant: 'error' })
                }
              }
              i.src = planFile
            } else {
              siteConfig.planimetry_file_name = null
              siteConfig.lat_br_planimetry = null
              siteConfig.lon_br_planimetry = null
              siteConfig.lat_bl_planimetry = null
              siteConfig.lon_bl_planimetry = null
              siteConfig.lat_tl_planimetry = null
              siteConfig.lon_tl_planimetry = null
              siteConfig.lat_tr_planimetry = null
              siteConfig.lon_tr_planimetry = null
              let res = await UpdateSite(parentSiteId, siteConfig, undefined)
              if (res) {
                enqueueSnackbar("Succesful removed planimetry on the server!", { variant: 'success' })
                dispatch(setIsLoading(true))
                dispatch(setSelectedSite(userSite))
                dispatch(setSelectedSite(parentSiteId))
                //setTimeout(() => window.location.reload(), 1000)
              } else {
                enqueueSnackbar("Server rejected the update...", { variant: 'error' })
              }
            }
          } else {
            let res = await UpdateSite(parentSiteId, siteConfig, undefined)
            if (res) {
              enqueueSnackbar("Succesful changed name on the server!", { variant: 'success' })
              dispatch(setIsLoading(true))
              dispatch(setTriggerDataRefresh(true))
              // go to parent site
              setTimeout(() => dispatch(setSelectedSite(navbarSelection[findIndex(navbarSelection, ['siteId', parentSiteId]) - 1].siteId)), 500)
              //setTimeout(() => window.location.reload(), 1000)
            } else {
              enqueueSnackbar("Server rejected the update...", { variant: 'error' })
            }
          }
        }
        break
      default:
        break
    }
    dispatch(setAddSiteOnTeMap(false))
  }

  useEffect(() => {
    if (!popupAction) {
      dispatch(setNewSitePlanimetryFile(parentSitePlanimetry ? `data:image/jpeg;base64,${parentSitePlanimetry}` : ""))
      dispatch(setNewSiteName(parentSiteName))
    } else {
      dispatch(setNewSitePlanimetryFile(""))
      dispatch(setNewSiteName(""))
    }
  }, [addEditSiteOnTeMap, popupAction, parentSiteName, parentSitePlanimetry]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={addEditSiteOnTeMap} onClose={handleClose} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{popupAction ? "Insert New" : "Edit Selected"} Site</DialogTitle>
      <DialogContent>
        <DialogContentText>{popupAction ? "New" : "Selected"} site details:</DialogContentText>
        {popupAction ? (
          <LocationFinder />
        ) : undefined}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Site Name"
          type="text"
          value={siteName}
          onChange={updateForm}
          fullWidth
          tabIndex={2}
          InputProps={{
            readOnly: !popupAction && userSiteId === parentSiteId ? true : false,
          }}
          className={classes.white}
        />
        {popupAction || siteType !== 'not_leaf' ? (
          <>
            <Button variant="outlined" onClick={() => setOpenUploader(true)}>Upload New Planimetry</Button>
            <DropzoneDialog
              open={openUploader}
              onClose={() => setOpenUploader(false)}
              filesLimit={1}
              dropzoneText={"Drop here Planimetry [.JPG] if you want"}
              onSave={addPlanimetryFile}
              acceptedFiles={['image/jpeg']}
              showPreviewsInDropzone={true}
              showPreviews={false}
              maxFileSize={5000000}
            />
            {planFile === '' ? (
              undefined
            ) : (
              <ImgView
                src={planFile === '' ? `` : `${planFile}`}
                disableSpinner
                cover
                aspectRatio={(16 / 9)}
              />
            )}
          </>
        ) : (undefined)}
        {!popupAction && parentSiteId !== userSiteId && siteType !== 'not_leaf' ? <ConfirmDeleteSiteDialog></ConfirmDeleteSiteDialog> : undefined}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose("cancel")} color="primary" variant="contained">
          Cancel
        </Button>
        <Button onClick={() => handleClose("apply")} color="primary" variant="contained">
          {popupAction ? "Create" : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const error = {
  txtSerialNumber: false,
  txtIpAddress: false,
  txtServerPort: false,
  txtDescription: false,
  txtPositioning: false,
}

const MyAddDeviceDialog = () => {
  const addDeviceOnTheMap = useSelector(selectAddDeviceOnTheMap)

  const [newDeviceSerialNumber, setNewDeviceSerialNumber] = React.useState("")
  const [newDeviceServerIpAddr, setNewDeviceServerIpAddr] = React.useState("")
  const [newDeviceServerPort, setNewDeviceServerPort] = React.useState("")
  const [newDeviceDeviceType, setNewDeviceDeviceType] = React.useState("")
  const [newDeviceOperativeStatus, setNewDeviceOperativeStatus] = React.useState("")
  const [newDeviceCoordinates, setNewDeviceCoordinates] = React.useState("")
  const [newDeviceDescription, setNewDeviceDescription] = React.useState("")
  const [newDeviceSiteId, setNewDeviceSiteId] = React.useState("")
  const [newDevicePositioning, setNewDevicePositioning] = React.useState("")
  const [newDeviceText1, setNewDeviceText1] = React.useState("")
  const [newDeviceText2, setNewDeviceText2] = React.useState("")
  const [newDeviceHeartBeatPeriod, setNewDeviceHeartBeatPeriod] = React.useState("")
  const [newDeviceSamplePeriod, setNewDeviceSamplePeriod] = React.useState("")

  const siteType = useSelector(selectSiteType)
  const parentSiteId = useSelector(selectSelectedSite)
  const parentSiteName = useSelector(selectSelectedSiteName)
  const parentSiteCoordinates = useSelector(selectSelectedSiteCoordinates)
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles()
  const dispatch = useDispatch()
  const Writer = new NFC();

  function validateIPaddress(ipaddress) {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
      return (true)
    }
    return (false)
  }

  const updateForm = (e) => {
    switch (e.target.name) {
      case 'txtSerialNumber':
        if (e.target.value.match(/^([0-9A-Fa-f]{0,2}[:-]?){0,7}([0-9A-Fa-f]{0,2})$/)) {
          setNewDeviceSerialNumber(e.target.value)
          error[e.target.id] = false
        } else {
          error[e.target.id] = true
        }
        break
      case 'txtIpAddress':
        setNewDeviceServerIpAddr(e.target.value)
        if (validateIPaddress(e.target.value)) {
          error[e.target.id] = false
        }
        else {
          error[e.target.id] = true
        }
        break
      case 'txtServerPort':
        setNewDeviceServerPort(e.target.value)
        if (!Number.isNaN(Number(e.target.value))) {
          error[e.target.id] = false
        }
        else {
          error[e.target.id] = true
        }
        break
      case 'txtDescription':
        setNewDeviceDescription(e.target.value)
        break
      case 'selectOperativeStatus':
        setNewDeviceOperativeStatus(e.target.value)
        break
      case 'selectDeviceType':
        setNewDeviceDeviceType(e.target.value)
        const { defaultValues } = getRenderPropsFromDeviceType(e.target.value)
        setNewDeviceServerIpAddr(defaultValues.server_ip_address)
        setNewDeviceServerPort(defaultValues.server_port)
        setNewDeviceHeartBeatPeriod(defaultValues.heartbeat_period)
        setNewDeviceSamplePeriod(defaultValues.sample_period)
        break
      case 'txtPositioning':
        setNewDevicePositioning(e.target.value)
        break
      case 'txtText1':
        setNewDeviceText1(e.target.value)
        break
      case 'txtText2':
        setNewDeviceText2(e.target.value)
        break
      case 'selectHeartBeatPeriod':
        if (e.target.value >= 5 && e.target.value <= 65535) {
          setNewDeviceHeartBeatPeriod(e.target.value)
        }
        break
      case 'selectSamplePeriod':
        if (e.target.value >= 1 && e.target.value <= 250) {
          setNewDeviceSamplePeriod(e.target.value)
        }
        break
      default:
        break
    }
  }

  const handleClose = async (action) => {
    switch (action) {
      case 'cancel':
        dispatch(setAddDeviceOnTheMap(false))
        break
      case 'apply':
        if (newDeviceServerIpAddr.length === 0 || newDeviceServerPort.length === 0 || newDeviceSerialNumber.length === 0) {
          enqueueSnackbar('You have to fill the form as well!', { variant: 'error' });
          return
        }
        for (let er in error) {
          if (error[er]) {
            enqueueSnackbar('You have to fill the form as well!', { variant: 'error' });
            return
          }
        }
        dispatch(setIsLoading(true))
        let deviceInfo = {
          server_ip_addr: newDeviceServerIpAddr,
          server_port: newDeviceServerPort,
          description: newDeviceDescription,
          serial_number: newDeviceSerialNumber,
          operative_status: newDeviceOperativeStatus,
          device_type: newDeviceDeviceType,
          lat: newDeviceCoordinates.lat,
          lon: newDeviceCoordinates.lon,
          site_id: Number(newDeviceSiteId),
          positioning: newDevicePositioning,
          text1: newDeviceText1,
          text2: newDeviceText2,
          heartbeat_period: newDeviceHeartBeatPeriod,
          sample_period: newDeviceSamplePeriod,
        }
        let res = await InsertNewDevice(deviceInfo)
        if (res && res.accepted) {
          if (siteType === 'leaf_without_devices') {
            dispatch(setForceParentChangeType(parentSiteId))
          }
          dispatch(setAddDeviceOnTheMap(false))
          dispatch(setTriggerDeviceDataRefresh('open'))
          enqueueSnackbar('Configuration Updated!', { variant: 'success' });
        } else if (res && res.message === 'Existing Device!') {
          enqueueSnackbar('Error! Existing serial number!', { variant: 'error' });
        } else {
          enqueueSnackbar('Server Rejected New Configuration!', { variant: 'warning' });
        }
        dispatch(setIsLoading(false))
        break
      default:
        break
    }
    setNewDeviceSerialNumber("")
    setNewDeviceDescription("")
    setNewDevicePositioning("")
    setNewDeviceText1("")
    setNewDeviceText2("")
    setNewDeviceSiteId(parentSiteId)
    setNewDeviceCoordinates({ lat: parentSiteCoordinates.lat, lon: parentSiteCoordinates.lon })
    setNewDeviceOperativeStatus("NonConfigurato")
    setNewDeviceDeviceType("not_defined")
    const { defaultValues } = getRenderPropsFromDeviceType('not_defined')
    setNewDeviceServerIpAddr(defaultValues.server_ip_address)
    setNewDeviceServerPort(defaultValues.server_port)
    setNewDeviceHeartBeatPeriod(defaultValues.heartbeat_period)
    setNewDeviceSamplePeriod(defaultValues.sample_period)
  }

  useEffect(() => {
    setNewDeviceSiteId(parentSiteId)
    setNewDeviceCoordinates({ lat: parentSiteCoordinates.lat, lon: parentSiteCoordinates.lon })
    setNewDeviceOperativeStatus("NonConfigurato")
    setNewDeviceDeviceType("not_defined")
    const { defaultValues } = getRenderPropsFromDeviceType('not_defined')
    setNewDeviceServerIpAddr(defaultValues.server_ip_address)
    setNewDeviceServerPort(defaultValues.server_port)
    setNewDeviceHeartBeatPeriod(defaultValues.heartbeat_period)
    setNewDeviceSamplePeriod(defaultValues.sample_period)
  }, [parentSiteId, parentSiteCoordinates])

  const getNfcTagUId = async () => {
    enqueueSnackbar('Please, put the NFC Tag on the reader...', { variant: 'info' });
    const nfcTag = await Writer.scanTags()
    if (nfcTag && nfcTag.serialNumber) {
      setNewDeviceSerialNumber(nfcTag.serialNumber)
      enqueueSnackbar(`NFC Tag found! Serial Number: ${nfcTag.serialNumber}`, { variant: 'success' });
    }
  }

  return (
    <Dialog open={addDeviceOnTheMap} onClose={handleClose} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Insert New Device In {parentSiteName} {Writer.nfcAvailable && <IconButton onClick={() => getNfcTagUId()} color="secondary" aria-label="Use NFC"><NfcOutlined /></IconButton>}</DialogTitle>
      <DialogContent>
        <DialogContentText>New device details:</DialogContentText>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel id="lblDeviceType" className={classes.white}>Device Type</InputLabel>
          <Select
            labelId="lblDeviceType"
            id="selectDeviceType"
            name="selectDeviceType"
            value={newDeviceDeviceType.toLowerCase()}
            onChange={updateForm}
            variant="standard"
            fullWidth
          >
            {getDeviceList().map(device => {
              const { label, icon } = getRenderPropsFromDeviceType(device)
              return <MenuItem key={device} value={device}>{icon} {label}</MenuItem>
            })}
          </Select>
        </FormControl>
        {newDeviceDeviceType === 'not_defined' ? <Typography id="lblDeviceTypeAlert" variant="body2" color="secondary" component="p">With this setting the device will be activated with a minimal default configuration that is not specific to any particular type of use.</Typography> : undefined}
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel id="lblOperativeStatus" className={classes.white}>Operative Status</InputLabel>
          <Select
            labelId="lblOperativeStatus"
            id="selectOperativeStatus"
            name="selectOperativeStatus"
            value={newDeviceOperativeStatus}
            readOnly={true}
            disabled={true}
            onChange={updateForm}
            variant="standard"
            fullWidth
          >
            <MenuItem value={'Operational'}>Operational</MenuItem>
            <MenuItem value={'Maintenance'}>Maintenance</MenuItem>
            <MenuItem value={'Off'}>Off</MenuItem>
            <MenuItem value={'NonConfigurato'}>Non Configurato</MenuItem>
          </Select>
        </FormControl>
        <TextField
          autoFocus
          tabIndex={1}
          margin="dense"
          id="txtSerialNumber"
          name="txtSerialNumber"
          label="Device Serial Number"
          type="text"
          error={error["txtSerialNumber"]}
          value={newDeviceSerialNumber}
          onChange={updateForm}
          fullWidth
          className={classes.white}
        />
        <TextField
          tabIndex={2}
          margin="dense"
          id="txtIpAddress"
          name="txtIpAddress"
          label="Server IP Address"
          type="text"
          error={error["txtIpAddress"]}
          value={newDeviceServerIpAddr}
          onChange={updateForm}
          fullWidth
          className={classes.white}
        />
        <TextField
          tabIndex={3}
          margin="dense"
          id="txtServerPort"
          name="txtServerPort"
          label="Server Port"
          type="text"
          error={error["txtServerPort"]}
          value={newDeviceServerPort}
          onChange={updateForm}
          fullWidth
          className={classes.white}
        />
        <TextField
          tabIndex={4}
          margin="dense"
          id="txtDescription"
          name="txtDescription"
          label="Description"
          type="text"
          value={newDeviceDescription}
          onChange={updateForm}
          fullWidth
          className={classes.white}
        />
        <TextField
          tabIndex={5}
          margin="dense"
          id="txtPositioning"
          name="txtPositioning"
          label="Positioning"
          type="text"
          value={newDevicePositioning}
          onChange={updateForm}
          fullWidth
          className={classes.white}
        />
        <TextField
          tabIndex={7}
          margin="dense"
          id="txtText1"
          name="txtText1"
          label="Text 1"
          type="text"
          value={newDeviceText1}
          onChange={updateForm}
          fullWidth
          className={classes.white}
        />
        <TextField
          tabIndex={8}
          margin="dense"
          id="txtText2"
          name="txtText2"
          label="Text 2"
          type="text"
          value={newDeviceText2}
          onChange={updateForm}
          fullWidth
          className={classes.white}
        />
        <TextField
          select
          margin="dense"
          label="Heartbeat Period"
          id="selectHeartBeatPeriod"
          name="selectHeartBeatPeriod"
          className={classes.white}
          value={newDeviceHeartBeatPeriod}
          onChange={updateForm}
          tabIndex={9}
          fullWidth
        >
          {getHeartbeatPeriodList(newDeviceSamplePeriod).map((sp, i) => {
            return <MenuItem key={i} value={sp}>{sp >= 60 ? `${sp / 60} h` : `${sp} min`}</MenuItem>
          })}
        </TextField>
        <TextField
          select
          margin="dense"
          label="Sample Period"
          id="selectSamplePeriod"
          name="selectSamplePeriod"
          className={classes.white}
          value={newDeviceSamplePeriod}
          onChange={updateForm}
          tabIndex={10}
          fullWidth
        >
          {getSamplePeriodList().map((sp, i) => {
            return <MenuItem key={i} value={sp}>{sp >= 60 ? `${sp / 60} h` : `${sp} min`}</MenuItem>
          })}
        </TextField>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose("cancel")} color="primary" variant="contained">
          Cancel
        </Button>
        <Button onClick={() => handleClose("apply")} color="primary" variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
};

function PaperComponent(props) {
  return (
    <Draggable handle="#adduser-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const error_user = {
  txtUsername: false,
  txtEmail: false,
  txtOldPassword: false,
  txtNewPassword1: false,
  txtNewPassword2: false,
}

const error_edit_users = {
  txtYourPassword: false,
  txtNewPassword1_edit: false,
  txtNewPassword2_edit: false,
}

const MyAddUserDialog = () => {
  const addUserOnSite = useSelector(selectAddUserOnSite)
  const selectedSite = useSelector(selectSelectedSite)
  const [openUploader, setOpenUploader] = React.useState(false)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch()
  const [newUserProfilePic, setNewUserProfilePic] = React.useState("")
  const [userProfilePicChanged, setUserProfilePicChanged] = React.useState("")
  const username = useSelector(selectUsername)
  const [localUsername, setLocalUsername] = React.useState("")
  const [localEmail, setLocalEmail] = React.useState("")
  const [newPassword1, setNewPassword1] = React.useState("")
  const [newPassword2, setNewPassword2] = React.useState("")
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [tabVal, setTabVal] = React.useState(0);
  const [usersList, setUsersList] = React.useState([])
  const [checked, setChecked] = React.useState([]);
  const [yourPassword, setYourPassword] = React.useState("")
  const [newPassword1_edit, setNewPassword1_edit] = React.useState("")
  const [newPassword2_edit, setNewPassword2_edit] = React.useState("")
  const [changePassword, setChangePassword] = React.useState(false)
  const [userEnabled, setUserEnabled] = React.useState(false)
  const [editedUserPermissions, setEditedUserPermissions] = React.useState({
    users_management: false, sites_management: false, devices_management: false, notifications_management: false
  })
  const [userPermissions, setUserPermissions] = React.useState({
    users_management: false, sites_management: false, devices_management: false, notifications_management: false
  })

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return re.test(String(email).toLowerCase());
  }

  function validateUsername(username) {
    const re = /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
    return re.test(String(username).toLowerCase());
  }

  const updateUsersList = async () => {
    const res = await GetUsersList(selectedSite)
    if (res) {
      let usrList = []
      for (let row in res) {
        await FillProfilePicCollection(res[row].username)
        usrList[row] = res[row]
      }
      setUsersList(usrList)
    }
  }

  const UserList = () => {
    return (
      usersList.map(usr => {
        const labelId = `checkbox-list-secondary-label-${usr.username}`
        return (
          <ListItem key={`${usr.username}_avatar`} button onClick={handleToggle(usr.username)}>
            <ListItemAvatar>
              <RenderStandaloneAvatar name={usr.username}></RenderStandaloneAvatar>
            </ListItemAvatar>
            <ListItemText id={labelId} primary={usr.username} />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={handleToggle(usr.username)}
                checked={checked.indexOf(usr.username) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )
      }
      )
    )
  }

  const handleToggle = (value) => () => {
    setChecked([value]);
    for (let el in usersList) {
      if (usersList[el].username === value) {
        setUserEnabled(!usersList[el].user_disabled)
        setEditedUserPermissions({
          users_management: usersList[el].users_management,
          sites_management: usersList[el].sites_management,
          devices_management: usersList[el].devices_management,
          notifications_management: usersList[el].notifications_management
        })
      }
    }
  };

  useEffect(() => {
    if (addUserOnSite) {
      updateUsersList()
    }
  }, [addUserOnSite]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateForm = (e) => {
    switch (e.target.id) {
      case 'txtUsername':
        setLocalUsername(e.target.value)
        if (validateUsername(e.target.value)) {
          error_user[e.target.id] = false
        }
        else {
          error_user[e.target.id] = true
        }
        break
      case 'txtEmail':
        setLocalEmail(e.target.value)
        if (validateEmail(e.target.value)) {
          error_user[e.target.id] = false
        }
        else {
          error_user[e.target.id] = true
        }
        break
      case 'txtNewPassword1':
        setNewPassword1(e.target.value)
        if (e.target.value.length > 3 && e.target.value === newPassword2) {
          error_user[e.target.id] = false
        }
        else {
          error_user[e.target.id] = true
        }
        break
      case 'txtNewPassword2':
        setNewPassword2(e.target.value)
        if (e.target.value.length > 3 && e.target.value === newPassword1) {
          error_user[e.target.id] = false
          error_user['txtNewPassword1'] = false
        }
        else {
          error_user[e.target.id] = true
        }
        break
      case 'swUserEnabled':
        setUserEnabled(e.target.checked)
        break
      case 'txtYourPassword':
        setYourPassword(e.target.value)
        if (e.target.value.length > 3) {
          error_edit_users[e.target.id] = false
        }
        else {
          error_edit_users[e.target.id] = true
        }
        break
      case 'txtNewPassword1_edit':
        setNewPassword1_edit(e.target.value)
        if (e.target.value.length > 3) {
          error_edit_users[e.target.id] = false
        }
        else {
          error_edit_users[e.target.id] = true
        }
        break
      case 'txtNewPassword2_edit':
        setNewPassword2_edit(e.target.value)
        if (e.target.value.length > 3 && e.target.value === newPassword1_edit) {
          error_edit_users[e.target.id] = false
          error_edit_users['txtNewPassword1'] = false
        }
        else {
          error_edit_users[e.target.id] = true
        }
        break
      case 'swRoleEdit_users_management':
      case 'swRoleEdit_sites_management':
      case 'swRoleEdit_devices_management':
      case 'swRoleEdit_notifications_management':
        let perm = clone(editedUserPermissions)
        perm[e.target.id.replace('swRoleEdit_', '')] = e.target.checked
        setEditedUserPermissions(perm)
        break
      case 'swRole_users_management':
      case 'swRole_sites_management':
      case 'swRole_devices_management':
      case 'swRole_notifications_management':
        let _perm = clone(userPermissions)
        _perm[e.target.id.replace('swRole_', '')] = e.target.checked
        setUserPermissions(_perm)
        break
      default:
        break
    }
  }

  const handleClose = async (action) => {
    switch (action) {
      case 'cancel':
        break
      case 'submit':
        if (localUsername.length === 0 || localEmail.length === 0 || newPassword2.length === 0) {
          enqueueSnackbar('You have to fill the form as well!', { variant: 'error' });
          return
        }
        for (let er in error_user) {
          if (error_user[er]) {
            enqueueSnackbar('You have to fill the form as well!', { variant: 'error' });
            return
          }
        }
        dispatch(setIsLoading(true))
        const res = await CreateUserProfile(selectedSite, userPermissions, localUsername.toLocaleLowerCase(), localEmail, newPassword2, userProfilePicChanged ? newUserProfilePic : undefined)
        if (res.accepted) {
          enqueueSnackbar('User created!', { variant: 'success' });
        } else {
          enqueueSnackbar('Error! User Exists...', { variant: 'error' });
          error_user['txtUsername'] = true
          dispatch(setIsLoading(false))
          return
        }
        dispatch(setIsLoading(false))
        break
      case 'submit_edit':
        if (yourPassword.length === 0 || (changePassword === true && newPassword2_edit.length === 0)) {
          enqueueSnackbar('You have to fill the form as well!', { variant: 'error' });
          return
        }
        for (let er in error_edit_users) {
          if (error_edit_users[er]) {
            enqueueSnackbar('You have to fill the form as well!', { variant: 'error' });
            return
          }
        }
        dispatch(setIsLoading(true))
        // Aggiungere i campi
        const result = await UpdateSelectedUser(username, yourPassword, checked[0], editedUserPermissions, !userEnabled, (changePassword ? newPassword2_edit : undefined))
        if (result) {
          enqueueSnackbar('User created!', { variant: 'success' });
        } else {
          enqueueSnackbar('Error! Password is wrong...', { variant: 'error' });
          error_edit_users['txtYourPassword'] = true
          dispatch(setIsLoading(false))
          return
        }
        dispatch(setIsLoading(false))
        break
      default:
        break
    }
    setUserProfilePicChanged(false)
    setNewUserProfilePic("")
    setLocalUsername("")
    setLocalEmail("")
    setNewPassword1("")
    setNewPassword2("")
    dispatch(setAddUserOnSite(false))
  }

  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    })
  }

  const addProfilePictureFile = async (files) => {
    if (files.length > 0) {
      try {
        let file = files[0];
        const _base64Planimetry = await readFileAsync(file)
        setNewUserProfilePic(_base64Planimetry)
        setUserProfilePicChanged(true)
        setOpenUploader(false)
      } catch (err) {
        console.log(err);
      }
    }
  }

  const handleChangeTabs = (event, newValue) => {
    setTabVal(newValue);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        fullScreen={fullScreen}
        PaperComponent={!fullScreen ? PaperComponent : undefined}
        open={addUserOnSite}
        onClose={handleClose}
        aria-labelledby="adduser-dialog-title"
      >
        <AppBar position="static" color="default">
          <Tabs
            id="adduser-dialog-title"
            value={tabVal}
            onChange={handleChangeTabs}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="User Manager"
          >
            <Tab label="Create New" {...a11yProps(0)} />
            <Tab label="Edit Existing" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabVal}
        >
          <TabPanel value={tabVal} index={0} dir={theme.direction}>
            <DialogContent className={classes.userForm}>
              <Avatar className="largeAvatar" alt={localUsername} src={newUserProfilePic.indexOf('base64,') > -1 ? newUserProfilePic : `data:image/jpeg;base64,${newUserProfilePic}`} />
              <Button tabIndex={0} fullWidth className={classes.userForm.buttons} variant="outlined" onClick={() => setOpenUploader(true)}>Change Profile Picture</Button>
              <DropzoneDialog
                open={openUploader}
                onClose={() => setOpenUploader(false)}
                filesLimit={1}
                dropzoneText={"Drop here the profile picture [.JPG] if you want"}
                onSave={addProfilePictureFile}
                acceptedFiles={['image/jpeg']}
                showPreviewsInDropzone={true}
                showPreviews={false}
                maxFileSize={2000000}
              />
              <TextField
                tabIndex={1}
                autoFocus
                margin="dense"
                id="txtUsername"
                label="Username"
                type="text"
                fullWidth
                variant="outlined"
                value={localUsername}
                onChange={updateForm}
                aria-errormessage="Incorrect Username"
                error={error_user["txtUsername"]}
                className={classes.white}
              />
              <TextField
                tabIndex={1}
                autoFocus
                margin="dense"
                id="txtEmail"
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                value={localEmail}
                onChange={updateForm}
                aria-errormessage="Incorrect Email Address"
                error={error_user["txtEmail"]}
                className={classes.white}
              />

              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <FormControlLabel
                    key="sw1_new"
                    control={<Switch checked={userPermissions.users_management} onChange={updateForm} id="swRole_users_management" />}
                    label="Edit Users"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    key="sw2_new"
                    control={<Switch checked={userPermissions.sites_management} onChange={updateForm} id="swRole_sites_management" />}
                    label="Edit Sites"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    key="sw3_new"
                    control={<Switch checked={userPermissions.devices_management} onChange={updateForm} id="swRole_devices_management" />}
                    label="Edit Devices"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    key="sw4_new"
                    control={<Switch checked={userPermissions.notifications_management} onChange={updateForm} id="swRole_notifications_management" />}
                    label="Receive Emails"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    key="sw5"
                    control={<Switch checked={userEnabled} onChange={updateForm} id="swUserEnabled" />}
                    label="User Enabled"
                  />
                </Grid>
              </Grid>
              <TextField
                tabIndex={3}
                autoFocus
                margin="dense"
                id="txtNewPassword1"
                label="Type New Password"
                type="password"
                fullWidth
                variant="outlined"
                value={newPassword1}
                onChange={updateForm}
                aria-errormessage="Insert the User Password"
                error={error_user["txtNewPassword1"]}
                className={classes.white}
              />
              <TextField
                tabIndex={4}
                autoFocus
                margin="dense"
                id="txtNewPassword2"
                label="Type New Password Again"
                type="password"
                fullWidth
                variant="outlined"
                value={newPassword2}
                onChange={updateForm}
                aria-errormessage="Password should be the same"
                error={error_user["txtNewPassword2"]}
                className={classes.white}
              />
            </DialogContent>
            <DialogActions>
              <Button tabIndex={4} className={classes.userForm.buttons} color="secondary" variant="contained" onClick={() => handleClose('submit')}>Save User Profile</Button>
              <Button tabIndex={5} autoFocus onClick={() => handleClose('cancel')} variant="contained" color="primary">Close</Button>
            </DialogActions>
          </TabPanel>
          <TabPanel value={tabVal} index={1} dir={theme.direction}>
            <DialogContent className={classes.userForm}>
              <List dense className={classes.root}>
                <UserList></UserList>
                <TextField
                  tabIndex={2}
                  autoFocus
                  margin="dense"
                  id="txtYourPassword"
                  label="Your Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  value={yourPassword}
                  onChange={updateForm}
                  aria-errormessage="Insert Your Password"
                  error={error_edit_users["txtYourPassword"]}
                  className={classes.white}
                />

                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      key="sw1"
                      control={<Switch checked={editedUserPermissions.users_management} onChange={updateForm} id="swRoleEdit_users_management" />}
                      label="Edit Users"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      key="sw2"
                      control={<Switch checked={editedUserPermissions.sites_management} onChange={updateForm} id="swRoleEdit_sites_management" />}
                      label="Edit Sites"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      key="sw3"
                      control={<Switch checked={editedUserPermissions.devices_management} onChange={updateForm} id="swRoleEdit_devices_management" />}
                      label="Edit Devices"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      key="sw4"
                      control={<Switch checked={editedUserPermissions.notifications_management} onChange={updateForm} id="swRoleEdit_notifications_management" />}
                      label="Receive Emails"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      key="sw5"
                      control={<Switch checked={userEnabled} onChange={updateForm} id="swUserEnabled" />}
                      label="User Enabled"
                    />
                  </Grid>
                </Grid>
                <FormControlLabel
                  className={classes.userForm.checkbox}
                  control={<Checkbox icon={<VpnKeyOutlinedIcon />} checkedIcon={<VpnKeyIcon />} name="checked" onChange={(e) => setChangePassword(e.target.checked)} />}
                  label="Change Password"
                />
                <TextField
                  tabIndex={3}
                  autoFocus
                  margin="dense"
                  id="txtNewPassword1_edit"
                  label="Type New Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  value={newPassword1_edit}
                  onChange={updateForm}
                  aria-errormessage="Insert the new Password"
                  error={error_edit_users["txtNewPassword1_edit"]}
                  disabled={!changePassword}
                  className={classes.white}
                />
                <TextField
                  tabIndex={4}
                  autoFocus
                  margin="dense"
                  id="txtNewPassword2_edit"
                  label="Type New Password Again"
                  type="password"
                  fullWidth
                  variant="outlined"
                  value={newPassword2_edit}
                  onChange={updateForm}
                  aria-errormessage="New password should be the same"
                  error={error_edit_users["txtNewPassword2_edit"]}
                  disabled={!changePassword}
                  className={classes.white}
                />
              </List>
            </DialogContent>
            <DialogActions>
              <Button tabIndex={4} className={classes.userForm.buttons} variant="contained" color="secondary" onClick={() => handleClose('submit_edit')}>Update Selected User</Button>
              <Button tabIndex={5} autoFocus onClick={() => handleClose('cancel')} variant="contained" color="primary">Close</Button>
            </DialogActions>
          </TabPanel>
        </SwipeableViews>
      </Dialog>
    </div>
  );
};

export function MyMap(props) {
  const dispatch = useDispatch()
  const [zoom, setZoom] = React.useState(18)
  const editMode = useSelector(selectPlanimetryEditMode)
  const planimetry = useSelector(selectPlanimetry)
  const base64Planimetry = useSelector(selectBase64Planimetry)
  const selectedSite = useSelector(selectSelectedSite)
  const userSite = useSelector(selectUserSite)
  const siteType = useSelector(selectSiteType)
  const isLeaf = useSelector(selectIsLeaf)
  const poiCollection = useSelector(selectPoiCollection)
  const maxZoom = useSelector(selectMaxZoom)
  const siteCoordinates = useState(props.siteCoordinates)
  const permissions = useSelector(selectPermissions)
  const navbarSelection = useSelector(selectNavbarSelectionPath)
  const { enqueueSnackbar } = useSnackbar();

  async function getPlanimetryPic() {
    dispatch(setIsLoading(true)) // Enable loading spinner
    dispatch(setBase64Planimetry(await GetSitePlanimetry(selectedSite)))
    dispatch(setIsLoading(false)) // Disable loading spinner
  }

  function getBounds(coords) {
    if (coords.length > 0) {
      let bounds = []
      for (let coord of coords) {
        bounds.push(coord.position)
      }
      return bounds
    } else {
      return [[siteCoordinates[0].lat, siteCoordinates[0].lon]]
    }
  }

  function getBoundsFromPlanimetry(planimetry) {
    return [
      [planimetry.lat_tl, planimetry.lon_tl],
      [planimetry.lat_bl, planimetry.lon_bl],
      [planimetry.lat_tr, planimetry.lon_tr],
      [planimetry.lat_bl, planimetry.lon_bl]
    ]
  }

  function selectOnTheMap(content) {
    if (isLeaf) {
      dispatch(setSelectedDevice(content.serialNumber))
      dispatch(setDeviceDetailsPanelOpen(true))
    }
    else
      dispatch(setSelectedSite(Number(content.siteId)))
  }

  const handleZoomChange = (e) => {
    setZoom(e.target._zoom)
  }

  function updateGeoPositionGraphics(idElement, type, newCoords) {
    let newPoiCollection = cloneDeep(poiCollection)
    let ref = find(newPoiCollection, (e) => { return type === 'device' ? e.content.serialNumber === idElement : e.content.siteId === idElement })
    ref.position[0] = newCoords.lat
    ref.position[1] = newCoords.lng
    dispatch(setPoiCollectionAsync(newPoiCollection))
  }

  const MyPopupMarker = ({ content, position, z }) => {
    const [draggable, setDraggable] = useState(false)
    const [openModal, setOpenModal] = React.useState(false);
    const [newCoords, setNewCoords] = React.useState(false);

    const handleClose = async (action) => {
      switch (action) {
        case "save":
          let type = (isLeaf ? "device" : "site")
          console.log(`Updating ${type}${isLeaf ? ` SN: ${content.serialNumber}` : ` ID: ${content.siteId}`} position: ${newCoords}`)
          // BkConnect Here
          let res
          if (isLeaf) {
            res = await UpdateGeoPosition(isLeaf ? content.serialNumber : content.siteId, type, newCoords)
            updateGeoPositionGraphics(isLeaf ? content.serialNumber : content.siteId, type, newCoords)
          } else {
            let plan = await GetSitePlanimetry(content.siteId)
            if (plan && plan !== '') {
              if (plan.indexOf('base64') === -1) plan = `data:image/jpeg;base64,${plan}`
              let siteConfig = {}
              let i = new Image()
              i.onload = async () => {
                const center = { lat: newCoords.lat, lon: newCoords.lng }
                const newPlanCorners = getAutoPlanimetryCornersFromSiteLocation(center, i.width, i.height)
                siteConfig.lat = newCoords.lat
                siteConfig.lon = newCoords.lng
                siteConfig.lat_br_planimetry = newPlanCorners.br.lat
                siteConfig.lon_br_planimetry = newPlanCorners.br.lon
                siteConfig.lat_bl_planimetry = newPlanCorners.bl.lat
                siteConfig.lon_bl_planimetry = newPlanCorners.bl.lon
                siteConfig.lat_tl_planimetry = newPlanCorners.tl.lat
                siteConfig.lon_tl_planimetry = newPlanCorners.tl.lon
                siteConfig.lat_tr_planimetry = newPlanCorners.tr.lat
                siteConfig.lon_tr_planimetry = newPlanCorners.tr.lon
                let _res = await UpdateSite(content.siteId, siteConfig)
                if (_res) {
                  setDraggable(false);
                  setTimeout(() => dispatch(setTriggerDataRefresh(true)), 200)
                  enqueueSnackbar("Succesful updated location and translated planimetry on the server!", { variant: 'success' })
                } else {
                  enqueueSnackbar("Server rejected the update...", { variant: 'error' })
                }
              }
              i.src = plan
              break
            } else {
              res = await UpdateGeoPosition(isLeaf ? content.serialNumber : content.siteId, type, newCoords)
              updateGeoPositionGraphics(isLeaf ? content.serialNumber : content.siteId, type, newCoords)
            }
          }
          if (res) {
            if (isLeaf) {
              //setTimeout(() => dispatch(setTriggerDataRefresh(true)), 1200)
              setTimeout(() => dispatch(setTriggerDeviceDataRefresh(true)), 200)
            } else {
              setTimeout(() => dispatch(setTriggerDataRefresh(true)), 200)
            }
            enqueueSnackbar("Succesful updated location on the server!", { variant: 'success' })
          } else {
            enqueueSnackbar("Server rejected the update...", { variant: 'error' })
          }
          setDraggable(false);
          break
        case "cancel":
          setDraggable(false);
          break
        default:
          break
      }
      setOpenModal(false);
    };

    const updatePoiCoordinates = (e) => {
      setNewCoords(e.target.getLatLng())
      setOpenModal(true)
    };

    const _renderProps = getRenderPropsFromDeviceType(content.deviceTypeName, content.maxSeverity)

    return (
      <Marker
        position={position}
        draggable={draggable}
        onDragend={updatePoiCoordinates}
        icon={getSeverityIcon(content.maxSeverity, content.name, isLeaf, content.deviceTypeName, 'p', z)}
      >
        <Popup className="locationPopup">
          <Card className="root">
            {/*<CardMedia
                component="img"
                alt={isLeaf ? (_renderProps.label) : (content.name)}
                height="140"
                className="media"
                image={isLeaf ? (_renderProps.svg) : ("../images/EL_03043_WM_WM.jpg")}
                title={isLeaf ? (_renderProps.label) : (content.name)}
              />*/}
            <CardContent >
              <Typography gutterBottom variant="h5" component="h2">
                {isLeaf ? (
                  _renderProps.label
                ) : (content.name)}
                {content.alerts > 0 ? (
                  <Badge className="alertCounter" badgeContent={content.alerts} color="error">
                    <WarningRoundedIcon />
                  </Badge>
                ) : (
                  <></>
                )}
              </Typography>
              {content.alerts > 0 ? (
                <>
                  <Typography variant="body2" color="textSecondary" component="p">
                    This {isLeaf ? ("device") : ("site")} has {content.alerts} {isLeaf ? ("sensors") : ("devices")} in trouble!
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Maximum severity: {content.maxSeverity.toUpperCase()}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="body2" color="textSecondary" component="p">
                    This {isLeaf ? ("device") : ("site")} has no {isLeaf ? ("sensors") : ("devices")} in trouble!
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    All is working well!
                  </Typography>
                </>
              )}
              {content.description && content.description !== '' ?
                <Typography variant="body2" color="textSecondary" component="p">
                  Description: {content.description}
                </Typography> : undefined}
              {content.positioning && content.positioning !== '' ?
                <Typography variant="body2" color="textSecondary" component="p">
                  Positioning: {content.positioning}
                </Typography> : undefined}
            </CardContent>
            <CardActions>
              <Button color="default" size="medium" onClick={() => selectOnTheMap(content)} startIcon={<TouchAppIcon />}>
                Select {isLeaf ? ("device") : ("site")}
              </Button>
              {isLeaf ? (
                permissions.devices_management ? (
                  <Button color="default" size="medium" onClick={() => setDraggable(!draggable)} startIcon={<EditIcon />}>
                    {draggable ? ("Save") : ("Move")}
                  </Button>
                ) : undefined
              ) : (
                permissions.sites_management && !content.isLeaf ? (
                  <Button color="default" size="medium" hidden={permissions.sites_management ? false : true} onClick={() => setDraggable(!draggable)} startIcon={<EditIcon />}>
                    {draggable ? ("Save") : ("Move")}
                  </Button>
                ) : undefined
              )}
            </CardActions>
          </Card>
        </Popup>
        <Dialog
          open={openModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Update location change on the server?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              By clicking on "SAVE NEW POSITION" button you confirm to save the new geographic information to the server.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose("cancel")} color="secondary">
              CANCEL
            </Button>
            <Button onClick={() => handleClose("save")} color="secondary" autoFocus>
              SAVE NEW POSITION
            </Button>
          </DialogActions>
        </Dialog>
      </Marker>
    )
  }

  const MyMarkersList = ({ markers, z }) => {
    const items = markers.map(({ key, ...props }) => (
      <MyPopupMarker key={key} {...props} /*z={z}*/ />
    ))
    return <MarkerClusterGroup>{items}</MarkerClusterGroup>
  }

  useEffect(() => {
    if (siteType !== 'not_leaf')
      getPlanimetryPic()
  }, [siteType, selectedSite]) // eslint-disable-line react-hooks/exhaustive-deps

  function onCornersUpdated(corners) {
    UpdatePlanimetryCorners(selectedSite, corners)
    dispatch(setPlanimetry({
      file_name: planimetry.file_name,
      lat_br: corners[3].lat,
      lat_bl: corners[2].lat,
      lat_tl: corners[0].lat,
      lat_tr: corners[1].lat,
      lon_bl: corners[2].lng,
      lon_br: corners[3].lng,
      lon_tl: corners[0].lng,
      lon_tr: corners[1].lng,
    }))
    //dispatch(setTriggerDeviceDataRefresh(true))
  }

  const handleClickCloseLevel = () => {
    // go to parent site
    setTimeout(() => dispatch(setSelectedSite(navbarSelection[findIndex(navbarSelection, ['siteId', selectedSite]) - 1].siteId)), 200)
  }

  return (
    <>
      <Map
        className="map"
        onZoomStart={handleZoomChange}
        maxZoom={maxZoom} zoomControl={false}
        useFlyTo={props.useFlyTo}
        bounds={(planimetry === undefined || planimetry.file_name === undefined || base64Planimetry === "" ? getBounds(poiCollection) : getBoundsFromPlanimetry(planimetry))}
      >
        <TileLayer
          url="https://tile.thunderforest.com/neighbourhood/{z}/{x}/{y}.png?apikey=43d136d38a064b11925be75e32fc86fa"
          maxZoom={25}
          attribution='Maps &copy; <a href="https://www.thunderforest.com">Thunderforest</a>, Data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>'
          id='mapbox.streets'
        />

        <ZoomControl position="topright" zoomInText="+" zoomOutText="-" />

        <Control position="topleft">
          <EditMapControl />
          {siteType !== 'not_leaf' && selectedSite !== userSite ? <Tooltip title="Go to previous level" placement="left" aria-label="add">
            <Fab color="inherit" aria-label="add" className={"btnOpenCloseLevel redBtn"} onClick={handleClickCloseLevel}>
              <CloseIcon></CloseIcon>
            </Fab>
          </Tooltip> : <></>}
        </Control>

        <ReactDistortableImageOverlay
          url={planimetry === undefined || planimetry.file_name === undefined || base64Planimetry === "" ? "../images/fake.jpg" : (base64Planimetry.indexOf('base64,') > -1 ? base64Planimetry : `data:image/jpeg;base64,${base64Planimetry}`)}
          editMode={editMode}
          onCornersUpdated={onCornersUpdated.bind(this)}
          corners={planimetry === undefined || planimetry.file_name === undefined || base64Planimetry === "" ? defaultCorners : [
            new LatLng(planimetry.lat_tl, planimetry.lon_tl),
            new LatLng(planimetry.lat_tr, planimetry.lon_tr),
            new LatLng(planimetry.lat_bl, planimetry.lon_bl),
            new LatLng(planimetry.lat_br, planimetry.lon_br)
          ]}
        />

        <MyMarkersList markers={poiCollection} z={zoom} />
      </Map>
      {permissions.sites_management ? <MyAddEditSiteDialog></MyAddEditSiteDialog> : undefined}
      {permissions.users_management ? <MyAddUserDialog></MyAddUserDialog> : undefined}
      {siteType !== 'not_leaf' ? <MyAddDeviceDialog></MyAddDeviceDialog> : undefined}
    </>
  );
}