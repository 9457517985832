import * as React from 'react';
import clsx from 'clsx';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import SignalCellularConnectedNoInternet1BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet1Bar';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import AtmIcon from '@material-ui/icons/Atm';
import OpacityIcon from '@material-ui/icons/Opacity';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import InputIcon from '@material-ui/icons/Input';
import Chip from '@material-ui/core/Chip';
import { CellParams } from '@material-ui/x-grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { AllOut, Favorite, SignalCellular0Bar } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'left',
      '& .icon': {
        color: 'inherit',
      },
    },
    blank: {
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.primary}`,
    },
    info: {
      color: theme.palette.info.dark,
      border: `1px solid ${theme.palette.info.main}`,
    },
    warning: {
      color: theme.palette.warning.dark,
      border: `1px solid ${theme.palette.warning.main}`,
    },
    error: {
      color: theme.palette.error.dark,
      border: `1px solid ${theme.palette.error.main}`,
    },
    success: {
      color: theme.palette.success.dark,
      border: `1px solid ${theme.palette.success.main}`,
    }
  }),
);

interface SensorProps {
  sensor: string;
}

export const getSensorPropsFromName = (name: string) => {
  let icon: any = null;
  let label = name;
  let colorClass = "blank"
  switch (name) {
    case 'battery_level':
      icon = <BatteryAlertIcon className="icon" />;
      label = "Battery Level"
      break;
    case 'antenna_signal':
      icon = <SignalCellularConnectedNoInternet1BarIcon className="icon" />;
      label = "Antenna Signal"
      break;
    case 'residual_battery':
      icon = <BatteryUnknownIcon className="icon" />;
      label = "Residual Battery"
      break;
    case 'temperature':
      icon = <WhatshotIcon className="icon" />;
      label = "Temperature"
      break;
    case 'pres_atm':
      icon = <AtmIcon className="icon" />;
      label = "Atmospheric Pressure"
      break;
    case 'humidity':
      icon = <OpacityIcon className="icon" />;
      label = "Humidity"
      break;
    case 'moved':
      icon = <OpenWithIcon className="icon" />;
      label = "Moved"
      break;
    case 'verticality':
      icon = <SwapVertIcon className="icon" />;
      label = "Verticality"
      break;
    case 'closed_door':
      icon = <MeetingRoomIcon className="icon" />;
      label = "Closed Door"
      break;
    case 'comb_door':
      icon = <MeetingRoomIcon className="icon" />;
      label = "Comb Door"
      break;
    case 'distance':
      icon = <AllOut className="icon" />;
      label = "Distance"
      break
    case 'open_close_cycles':
      icon = <MeetingRoomIcon className="icon" />;
      label = "Open/close Cycles"
      break
    case 'analogic_sensor_1':
      icon = <InputIcon className="icon" />;
      label = "Analogic Sensor 1"
      break;
    case 'analogic_sensor_2':
      icon = <InputIcon className="icon" />;
      label = "Analogic Sensor 2"
      break;
    case 'analogic_sensor_3':
      icon = <InputIcon className="icon" />;
      label = "Analogic Sensor 3"
      break;
    case 'analogic_sensor_4':
      icon = <InputIcon className="icon" />;
      label = "Analogic Sensor 4"
      break;
    case 'digital_sensor_1':
      icon = <InputIcon className="icon" />;
      label = "Digital Sensor 1"
      break;
    case 'digital_sensor_2':
      icon = <InputIcon className="icon" />;
      label = "Digital Sensor 2"
      break;
    case 'digital_sensor_3':
      icon = <InputIcon className="icon" />;
      label = "Digital Sensor 3"
      break;
    case 'digital_sensor_4':
      icon = <InputIcon className="icon" />;
      label = "Digital Sensor 4"
      break;
    case 'digital_sensor_5':
      icon = <InputIcon className="icon" />;
      label = "Digital Sensor 5"
      break;
    case 'digital_sensor_6':
      icon = <InputIcon className="icon" />;
      label = "Digital Sensor 6"
      break;
    case 'digital_sensor_7':
      icon = <InputIcon className="icon" />;
      label = "Digital Sensor 7"
      break;
    case 'digital_sensor_8':
      icon = <InputIcon className="icon" />;
      label = "Digital Sensor 8"
      break;
    case 'network_no_heartbeat':
      icon = <Favorite className="icon" />;
      label = "No heartbeat received"
      break;
    case 'network_malformed':
      icon = <SignalCellular0Bar className="icon" />;
      label = "Network malformed"
      break;
    default:
      label = "No sensor"
      break;
  }
  return {
    label,
    icon,
    colorClass
  }
};

const Sensor = React.memo((props: SensorProps) => {
  const { sensor } = props;
  const classes: any = useStyles();
  const { label, icon, colorClass } = getSensorPropsFromName(sensor)
  return (
    <Chip
      className={clsx(classes.root, classes[colorClass])}
      icon={icon}
      size="small"
      label={label}
      variant="outlined"
    />
  );
});

export function renderSensor(params: CellParams) {
  return <Sensor sensor={params.value!.toString()} />;
}
