import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from './routes/Login.jsx';
import Dashboard from './routes/Dashboard.jsx';
import Device from './routes/Device.jsx';
import { StartSocketConnection } from "./BkConnect"
import './App.css';
import { Fade } from '@material-ui/core';

//console.log("Backend IPv4 Addr:", process.env.REACT_APP_BK_IPV4_ADDRESS);


function App() {
  // Ottengo i dati registrati sui cookies
  const [session_key] = useState(
    localStorage.getItem('session_key') || ''
  );
  const [isUserAutenticated] = useState(
    localStorage.getItem('isUserAutenticated') || ''
  );
  const [username] = useState(
    localStorage.getItem('username') || ''
  );
  const [email] = useState(
    localStorage.getItem('email') || ''
  );
  const [permissions] = useState(
    localStorage.getItem('permissions') || ''
  );
  const [site_id] = useState(
    localStorage.getItem('sitesCollection') || ''
  );
  const [site_name] = useState(
    localStorage.getItem('siteName') || ''
  );
  const [site_path] = useState(
    localStorage.getItem('sitePath') || ''
  );
  const [site_lev] = useState(
    localStorage.getItem('siteLevel') || ''
  );
  const [childSite_lev] = useState(
    localStorage.getItem('childSiteLevel') || ''
  );
  const [site_isLeaf] = useState(
    localStorage.getItem('siteIsLeaf') || ''
  );
  const [site_type] = useState(
    localStorage.getItem('siteType') || ''
  );
  const [site_coordinates] = useState(
    localStorage.getItem('siteCoordinates') || ''
  );
  const [sitePlanimetry] = useState(
    localStorage.getItem('sitePlanimetry') || ''
  );

  const loc = window.location.pathname

  if (username !== '' && site_id !== '')
    StartSocketConnection(username, site_id, session_key)

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let welcome_msg = '%c\n\n' +
      '  ██╗  ██╗██╗    ███████╗███████╗██╗     ██╗      █████╗ ███████╗██╗\n' +
      '  ██║  ██║██║    ██╔════╝██╔════╝██║     ██║     ██╔══██╗██╔════╝██║\n' +
      '  ███████║██║    █████╗  █████╗  ██║     ██║     ███████║███████╗██║\n' +
      '  ██╔══██║██║    ██╔══╝  ██╔══╝  ██║     ██║     ██╔══██║╚════██║╚═╝\n' +
      '  ██║  ██║██║    ██║     ███████╗███████╗███████╗██║  ██║███████║██╗\n' +
      '  ╚═╝  ╚═╝╚═╝    ╚═╝     ╚══════╝╚══════╝╚══════╝╚═╝  ╚═╝╚══════╝╚═╝\n\n' +
      (isUserAutenticated === '' ? 'User have to log in!' : 'Logged in as ' + username + '\n')

    console.log(welcome_msg, 'font-family:monospace; color: #1976d2; font-size:12px;')
  });

  return (
    <Router>
      {isUserAutenticated === '' ? <Login page={loc} /> :
        <Switch>
          <Route exact path="/device/:serialNumber">
              {({ match }) => (
                <Device
                  username={username}
                  siteId={Number(site_id)}
                  sitePath={site_path}
                  email={email}
                  permissions={JSON.parse(permissions)}
                  serialNumber={match.params.serialNumber}
                />
              )}
            </Route>
          <Route exact path="/">
            <Fade>
              <Dashboard
                username={username}
                email={email}
                permissions={JSON.parse(permissions)}
                siteId={Number(site_id)}
                siteName={site_name}
                sitePath={site_path}
                siteLevel={site_lev}
                childSiteLevel={childSite_lev}
                siteType={site_type}
                siteIsLeaf={JSON.parse(site_isLeaf)}
                siteCoordinates={JSON.parse(site_coordinates)}
                sitePlanimetry={JSON.parse(sitePlanimetry)}
              />
            </Fade>
          </Route>
        </Switch>
      }
    </Router>
  );
}

export default App;
