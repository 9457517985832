// SMA Epoch is in UTC format, milliseconds
const SmaEpoch = new Date(Date.UTC(2020, 0, 1, 0, 0, 0, 0));

// input "date" must be in UTC format
// Return in UTC format, milliseconds
function GetRealTimeStamp(date) {
    // Date(number: value) always uses UTC for time representation
    return new Date(
        // getTime() always uses UTC for time representation
        SmaEpoch.getTime() +
        date * 1000)
}

export {
    SmaEpoch,
    GetRealTimeStamp
}
