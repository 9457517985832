import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { CellParams } from '@material-ui/x-grid';
import { GetUserProfilePic } from '../../BkConnect';
import { Typography } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';

interface profilePic {
  fetched: boolean,
  img: string
}

const profilePicsCollection: Array<profilePic> = []

export async function FillProfilePicCollection(_name: any) {
  if (_name) {
    if (!profilePicsCollection[_name]) {
      profilePicsCollection[_name] = {
        fetched: false,
        img: ""
      }
      GetUserProfilePic(_name)
        .then((_profilePic: string) => {
          profilePicsCollection[_name].fetched = true
          profilePicsCollection[_name].img = _profilePic
        })
    }
  }
}

// component react non vuole promise ma jsx
export function RenderStandaloneAvatar(props: any) {
  const { name } = props
  return (
    <Avatar
      style={{ backgroundColor: orange[400] }}
      src={profilePicsCollection[name] && profilePicsCollection[name].fetched ? `data:image/png;base64,${profilePicsCollection[name].img}` : undefined}
    >{profilePicsCollection[name] && profilePicsCollection[name].fetched ? "" : name.toString().substring(0, 1)}</Avatar>
  )
}

// component react non vuole promise ma jsx
export function RenderAvatar(params: CellParams) {
  const name = (params.value! as any).name

  return (name ? (
    <>
      <Avatar
        style={{ backgroundColor: (params.value! as any).color }}
        src={profilePicsCollection[name] && profilePicsCollection[name].fetched ? `data:image/png;base64,${profilePicsCollection[name].img}` : undefined}
      >{profilePicsCollection[name] && profilePicsCollection[name].fetched ? "" : name.toString().substring(0, 1)}</Avatar>
      <Typography variant="subtitle2" style={{ marginLeft: '6px' }}>{name}</Typography>
    </>) : ("Not yet")
  )
}