import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    selectSelectedSite,
    selectSelectedSiteName,
    selectMaxSeverity,
    selectAlertCount,
    selectAlertPanelRows,
    selectAlertPanelOpen,
    setAlertPanelOpen,
    setAlertPanelRows,
    selectUsername,
    selectTriggerAlertsDataRefresh,
    setTriggerAlertsDataRefresh,
    setIsLoading,
    selectSiteType,
    setSelectedDevice,
    setDeviceDetailsPanelOpen,
} from '../redux/reducers/dashboardSlice';
import { GetAlertTable, UserTakesAlerts } from "../BkConnect"
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Button, Tooltip } from "@material-ui/core/";
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import PropTypes from 'prop-types';
import { orange } from '@material-ui/core/colors';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import {
    RenderAvatar,
    renderSeverity,
    renderSensor,
    renderStatus,
    FillProfilePicCollection,
    renderDateTime,
    renderMultipleSensor,
    renderDeviceTypeById,
} from './renderer';
import { useSnackbar } from "notistack";
import "../Components/MyAlertsPanel.css"
import RenderPath from "./renderer/renderPath";
import { MyDeviceDetailPanel } from "./MyDeviceDetailPanel";

function customCheckbox(theme) {
    return {
        '& .MuiCheckbox-root svg': {
            width: 16,
            height: 16,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.type === 'light' ? '#d9d9d9' : 'rgb(150, 150, 150)'
                }`,
            borderRadius: 2,
        },
        '& .MuiCheckbox-root svg path': {
            display: 'none',
        },
        '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
            backgroundColor: '#1890ff',
            borderColor: '#1890ff',
        },
        '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
            position: 'absolute',
            display: 'table',
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
            transform: 'rotate(45deg) translate(-50%,-50%)',
            opacity: 1,
            transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
            content: '""',
            top: '50%',
            left: '39%',
            width: 5.71428571,
            height: 9.14285714,
        },
        '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
            width: 8,
            height: 8,
            backgroundColor: '#1890ff',
            transform: 'none',
            top: '39%',
            border: 0,
        },
    };
}

function CustomPagination(props) {
    const { paginationProps } = props;

    return (
        <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={paginationProps.page}
            count={paginationProps.pageCount}
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event, value) => paginationProps.setPage(value)}
        />
    );
}

CustomPagination.propTypes = {
    /**
     * The object containing all pagination details in [[PaginationProps]].
     */
    paginationProps: PropTypes.shape({
        page: PropTypes.number.isRequired,
        pageCount: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        rowCount: PropTypes.number.isRequired,
        setPage: PropTypes.func.isRequired,
        setPageSize: PropTypes.func.isRequired,
    }).isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        border: 0,
        color:
            theme.palette.type === 'light'
                ? 'rgba(0,0,0,.85)'
                : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
            borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-cell': {
            color:
                theme.palette.type === 'light'
                    ? 'rgba(0,0,0,.85)'
                    : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        ...customCheckbox(theme),
        '& .MuiDataGrid-overlayContent': {
            flexDirection: 'column',
            alignItems: 'center',
        },
        '& .ant-empty-img-1': {
            fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
            fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
        },
    },
    label: {
        marginTop: theme.spacing(1),
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

function CustomNoRowsOverlay() {
    const classes = useStyles();

    return (
        <GridOverlay className={classes.root}>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <div className={classes.label}>No Alerts <EmojiEmotionsIcon /></div>
        </GridOverlay>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function getColorClassName(_maxSeverity) {
    let className = ""
    switch (_maxSeverity) {
        case "critical":
            className = "redBtn"
            break
        case "alarm":
            className = "orangeBtn"
            break
        case "warning":
            className = "yellowBtn"
            break
        case "none":
            className = "greenBtn"
            break
        default:
            className = "greenBtn"
            break
    }
    return className
}

function getStatus(params) {
    if (params.getValue('is_taken') && !params.getValue('is_cleared')) return "AlertTaken"
    else if (!params.getValue('is_taken') && !params.getValue('is_cleared')) return "NewAlert"
    else if (params.getValue('is_cleared')) return "AlertCleared"
}

function getMultipleValueFormatted(params) {
    let value = 'N/a'
    if (params.getValue('value_at_raise') !== null) {
        value = params.getValue('value_at_raise').value
    }
    return JSON.stringify({
        sensor: params.getValue('sensor'),
        value
    })
}

function getMultipleValueForSort(params) {
    let value = -9999
    if (params.getValue('value_at_raise') !== null) {
        value = params.getValue('value_at_raise').value
    }
    return value
}

const _columns = [
    {
        field: 'alert_id',
        headerName: 'Alarm ID',
        type: 'number',
        width: 5,
        hide: true
    },
    {
        field: 'path',
        headerName: 'Site Name',
        type: 'string',
        width: 250,
        renderCell: RenderPath,
    },
    {
        field: 'device_serial',
        headerName: 'Device ID',
        type: 'string',
        width: 165,
        hide: true
    },
    {
        field: 'devices_type_name',
        headerName: 'Device Type',
        type: 'string',
        width: 250,
        renderCell: renderDeviceTypeById
    },
    {
        field: 'alert_positioning',
        headerName: 'Positioning',
        type: 'string',
        width: 160,
    },
    {
        field: 'ts_raise',
        headerName: 'Raise Time',
        type: 'dateTime',
        width: 160,
        renderCell: renderDateTime,
    },
    {
        field: 'sensor',
        headerName: 'Sensor',
        type: 'string',
        width: 250,
        renderCell: renderSensor,
    },
    {
        field: 'severity',
        headerName: 'Severity',
        type: 'string',
        width: 110,
        renderCell: renderSeverity
    },
    {
        field: 'value_at_raise',
        headerName: 'Value',
        type: 'number',
        width: 100,
        valueGetter: getMultipleValueFormatted,
        renderCell: renderMultipleSensor,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            getMultipleValueForSort(cellParams1) - getMultipleValueForSort(cellParams2)
    },
    {
        field: 'status',
        headerName: "Status",
        width: 110,
        renderCell: renderStatus,
        valueGetter: getStatus,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            getStatus(cellParams1).localeCompare(getStatus(cellParams2))
    },
    {
        field: 'is_taken',
        type: 'string',
        headerName: 'Taken',
        width: 70,
        //renderCell: renderDone,
        hide: true
    },
    {
        field: 'taken_by',
        headerName: 'Taken by',
        type: 'string',
        width: 200,
        renderCell: RenderAvatar,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            v1.name.localeCompare(v2.name)
    },
    {
        field: 'ts_taken',
        headerName: 'Taken Time',
        type: 'dateTime',
        width: 160,
        renderCell: renderDateTime,
    },
    {
        field: 'is_cleared',
        type: 'string',
        headerName: 'Cleared',
        width: 85,
        //renderCell: renderDone,
        hide: true
    },
    {
        field: 'ts_cleared',
        headerName: 'Cleared Time',
        type: 'dateTime',
        width: 160,
        renderCell: renderDateTime,
    },
]

const sortModel = [
    {
        field: 'ts_raise',
        sort: 'desc',
    },
];

function useData(rowsCollection, leaf = undefined) {
    const [data, setData] = useState({ columns: [], rows: [] });

    useEffect(() => {
        const rows = [];
        let i = 0
        for (let _row in rowsCollection) {
            const row = {
                id: i,
            }
            i++
            for (let _col in rowsCollection[_row]) {
                row[_col] = rowsCollection[_row][_col];
            }
            rows.push(row);
        }

        const columns = [{ field: 'id', hide: true }];

        for (let _col in _columns) {
            if (leaf && _columns[_col].field === 'path') {
                _columns[_col].hide = true
            } else if (!leaf && _columns[_col].field === 'path') {
                _columns[_col].hide = undefined
            }
            if (typeof _columns[_col].hide !== 'undefined')
                columns.push({
                    field: _columns[_col].field,
                    headerName: _columns[_col].headerName,
                    type: _columns[_col].type,
                    width: _columns[_col].width,
                    hide: _columns[_col].hide,
                });
            else if (typeof _columns[_col].renderCell !== 'undefined' && typeof _columns[_col].valueGetter !== 'undefined')
                columns.push({
                    field: _columns[_col].field,
                    headerName: _columns[_col].headerName,
                    type: _columns[_col].type,
                    width: _columns[_col].width,
                    renderCell: _columns[_col].renderCell,
                    valueGetter: _columns[_col].valueGetter,
                    sortComparator: _columns[_col].sortComparator
                });
            else if (typeof _columns[_col].renderCell !== 'undefined' && typeof _columns[_col].valueGetter == 'undefined')
                columns.push({
                    field: _columns[_col].field,
                    headerName: _columns[_col].headerName,
                    type: _columns[_col].type,
                    width: _columns[_col].width,
                    renderCell: _columns[_col].renderCell
                });
            else
                columns.push({
                    field: _columns[_col].field,
                    headerName: _columns[_col].headerName,
                    width: _columns[_col].width,
                    type: _columns[_col].type
                });
        }

        for (let _col in columns) {
            if (columns[_col].field === 'value_at_raise') {
                columns[_col].headerAlign = 'center'
                columns[_col].align = 'center'
            }
        }

        setData({
            rows,
            columns,
        });
    }, [rowsCollection, leaf]);

    //console.log("DATI REALI:",data)
    return data;
}

export function MyAlertsPanel(props) {
    const dispatch = useDispatch()
    const [selectedAlertsToTake, setSelectedAlertsToTake] = React.useState([])
    const [selectedAlertsToClose, setSelectedAlertsToClose] = React.useState([])
    const [selectedDevices, setSelectedDevices] = React.useState([])
    const username = useSelector(selectUsername);
    const openPanel = useSelector(selectAlertPanelOpen);
    const triggerDataRefresh = useSelector(selectTriggerAlertsDataRefresh)
    const rows = useSelector(selectAlertPanelRows)
    const selectedSiteId = useSelector(selectSelectedSite)
    const selectedSiteName = useSelector(selectSelectedSiteName)
    const selectedSiteType = useSelector(selectSiteType)
    const selectedSiteMaxSeverity = useSelector(selectMaxSeverity)
    const selectedSiteAlertCount = useSelector(selectAlertCount)
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [action, setAction] = React.useState('disabled');
    const [showDetails, setShowDetails] = React.useState(false);

    async function updateData(siteId) {
        let _tab = await GetAlertTable(siteId)
        //console.log(_tab)
        let _rows = []
        _tab.alert_table.forEach((e) => {
            let thisRow = {
                alert_id: Number(e.id),
                device_serial: e.device_serial,
                sensor: e.sensor,
                severity: e.severity,
                value_at_raise: e.value_at_raise,
                alert_positioning: e.alert_positioning,
                ts_raise: e.ts_raise,
                is_taken: e.is_taken,
                taken_by: { name: e.taken_by, color: orange[500] },
                ts_taken: e.ts_taken,
                is_cleared: e.is_cleared,
                ts_cleared: e.ts_cleared,
                devices_type_name: e.devices_type_name,
                site_id: e.site_id,
                path: e.path,
            }
            _rows.push(thisRow)
        })
        for (let _al of _rows) {
            if (_al.taken_by) await FillProfilePicCollection(_al.taken_by.name)
        }
        //console.log(_rows)
        dispatch(setAlertPanelRows(_rows))
        dispatch(setTriggerAlertsDataRefresh(false)) // Enable loading spinner
        dispatch(setIsLoading(false)) // Disable loading spinner
    }

    useEffect(() => {
        if (triggerDataRefresh && selectedSiteId > 0) {
            if (openPanel && selectedAlertsToTake.concat(selectedAlertsToClose).length > 0) {
                return
            }
            dispatch(setIsLoading(true)) // Enable loading spinner
            updateData(selectedSiteId)
        }
        //console.log(data.rows)
    }, [selectedSiteId, triggerDataRefresh]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedSiteId > 0)
            updateData(selectedSiteId)
    }, [selectedSiteId]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleClickOpen = async () => {
        dispatch(setAlertPanelOpen(true))
    };

    const handleClose = () => {
        dispatch(setAlertPanelOpen(false))
    };

    const showDeviceDetails = () => {
        //dispatch(setIsLoading(true))
        enqueueSnackbar(`Found a device! Fetching data...`, { variant: 'success' })
        dispatch(setSelectedDevice(selectedDevices[0]))
        dispatch(setDeviceDetailsPanelOpen(true))
    };

    const handleSelectAlerts = (alerts) => {
        if (alerts.rows.length === 0 && triggerDataRefresh) {
            updateData(selectedSiteId)
        }
        let alertsToTake = []
        let alertsToClose = []
        let _selectedDevices = []
        for (let alert in alerts.rows) {
            _selectedDevices.push(alerts.rows[alert].device_serial)
            if (alerts.rows[alert].is_taken !== true && alerts.rows[alert].is_cleared !== true) {
                alertsToTake.push(alerts.rows[alert].alert_id)
            } else if (alerts.rows[alert].is_cleared === true) {
                alertsToClose.push(alerts.rows[alert].alert_id)
            }
        }
        if (alertsToTake.length > 0 && alertsToClose.length === 0) {
            setAction('take')
            setSelectedAlertsToTake(alertsToTake)
            setSelectedAlertsToClose([])
        } else if (alertsToTake.length === 0 && alertsToClose.length > 0) {
            setAction('close')
            setSelectedAlertsToClose(alertsToClose)
            setSelectedAlertsToTake([])
        } else if (alertsToTake.length > 0 && alertsToClose.length > 0) {
            setAction('take & close')
            setSelectedAlertsToTake(alertsToTake)
            setSelectedAlertsToClose(alertsToClose)
        } else if (alertsToTake.length === 0 && alertsToClose.length === 0) {
            setAction('disabled')
            setSelectedAlertsToTake([])
            setSelectedAlertsToClose([])
        }
        setSelectedDevices(_selectedDevices)
        if (_selectedDevices.length === 1) {
            setShowDetails(true)
        } else {
            setShowDetails(false)
        }
    };

    const handleCloseDialog = async (_action) => {
        switch (_action) {
            case "confirm":
                switch (action) {
                    case 'take':
                        await updateAlertsOnTheServer(username, selectedAlertsToTake, 'take')
                        break
                    case 'close':
                        await updateAlertsOnTheServer(username, selectedAlertsToClose, 'close')
                        break
                    case 'take & close':
                        await updateAlertsOnTheServer(username, selectedAlertsToTake, 'take')
                        await updateAlertsOnTheServer(username, selectedAlertsToClose, 'close')
                        break
                    default:
                        break
                }
                break
            default:
                break
        }
        setOpenConfirmDialog(false);
    };

    async function updateAlertsOnTheServer(_username, _selectedAlerts, _action) {
        let res = await UserTakesAlerts(selectedSiteId, _username, _selectedAlerts, _action)
        if (res) {
            enqueueSnackbar("Updated selected alerts! ", { variant: 'success' })
            updateData(selectedSiteId)
        } else {
            enqueueSnackbar("Server rejected this action!", { variant: 'error' })
        }
    }

    const ConfirmDialog = () => {
        return (
            <Dialog
                open={openConfirmDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Really want to ${action} this alerts?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        By clicking on "CONFIRM" button you allow to {action} this alerts.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDialog("cancel")} color="secondary" variant="contained">CANCEL</Button>
                    <Button onClick={() => handleCloseDialog("confirm")} color="secondary" autoFocus variant="contained">CONFIRM</Button>
                </DialogActions>
            </Dialog>
        )
    };

    return (
        <>
            <Tooltip title="Show Alert Table" placement="left" aria-label="add">
                <Fab color="inherit" aria-label="add" className={"btnOpenAlertView " + getColorClassName(selectedSiteMaxSeverity)} onClick={handleClickOpen}>
                    <Typography variant="h6" className={classes.alertCounterText}>{selectedSiteAlertCount}</Typography>
                </Fab>
            </Tooltip>
            <Dialog fullScreen open={openPanel} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} color="default">
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>Alerts for {selectedSiteName}</Typography>
                        {showDetails ? <Button variant="contained" color="primary" onClick={() => showDeviceDetails()}>Details</Button> : undefined}
                        {action === 'disabled' ? undefined : <Button variant="contained" color="secondary" onClick={() => setOpenConfirmDialog(true)}>{action.toUpperCase()}</Button>}
                        <IconButton color="inherit" variant="contained" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 2 }}>
                        <DataGrid
                            sortModel={sortModel}
                            className={classes.root}
                            checkboxSelection
                            onSelectionChange={handleSelectAlerts}
                            pageSize={15}
                            components={{
                                noRowsOverlay: CustomNoRowsOverlay,
                                pagination: CustomPagination,
                            }}
                            columnBuffer={4}
                            {...useData(rows, selectedSiteType !== 'not_leaf' ? true : false)}
                        />
                    </div>
                </div>
                <ConfirmDialog></ConfirmDialog>
            </Dialog>
            {selectedSiteType !== 'leaf_with_devices' ? <MyDeviceDetailPanel></MyDeviceDetailPanel> : undefined}
        </>
    );
}