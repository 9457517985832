import * as React from 'react';
import clsx from 'clsx';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ExploreIcon from '@material-ui/icons/Explore';
import { CellParams } from '@material-ui/x-grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'left',
      '& .icon': {
        color: 'inherit',
      },
    },
    info: {
      color: theme.palette.info.dark,
      border: `1px solid ${theme.palette.info.main}`,
    },
    warning: {
      color: theme.palette.warning.dark,
      border: `1px solid ${theme.palette.warning.main}`,
    },
    error: {
      color: theme.palette.error.dark,
      border: `1px solid ${theme.palette.error.main}`,
    },
    success: {
      color: theme.palette.success.dark,
      border: `1px solid ${theme.palette.success.main}`,
    }
  }),
);

interface CoordinatesProps {
  coordinates: string;
}

const Coordinates = React.memo((props: CoordinatesProps) => {
  const { coordinates } = props;
  const classes: any = useStyles();
  let coords = coordinates.split(";")
  let icon: any = null;
  let label = "";
  let _class = classes.info;
  if (coordinates === 'Unknown') {
    icon = <ReportProblemIcon className="icon" />;
    label = "N/a"
  } else {
    icon =  <ExploreIcon className="icon" />;
    label = "Lat: " + coords[0] + ", Lon: " + coords[1]
  }

  return (
    <Chip
      className={clsx(classes.root, _class)}
      icon={icon}
      size="small"
      label={label}
      variant="outlined"
    />
  );
});

export function renderCoordinates(params: CellParams) {
  return <Coordinates coordinates={params.value!.toString()} />;
}
