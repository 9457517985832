import { createSlice } from '@reduxjs/toolkit';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    isLeaf: false,
    siteType: 'not_leaf',
    triggerDataRefresh: 0,
    triggerAlertsDataRefresh: 0,
    triggerDeviceDataRefresh: 0,
    triggerDeviceDetailsDataRefresh: 0,
    selectedSite: 0,
    selectedSiteName: "",
    selectedSiteCoordinates: {},
    alertCount: 0,
    maxSeverity: "none",
    base64Planimetry: "",
    planimetry: {
      file_name: null,
      lat_br: null,
      lat_bl: null,
      lat_tl: null,
      lat_tr: null,
      lon_bl: null,
      lon_br: null,
      lon_tl: null,
      lon_tr: null,
    },
    maxZoom: 22,
    mapCenter: undefined,
    navbarSelectionPath: [],
    poiCollection: [],
    planimetryEditMode: "lock",
    addSiteOnTeMap: false,
    addDeviceOnTheMap: false,
    addEditSiteAction: false,
    newSiteName: "",
    newSiteCoords: {},
    newSitePlanimetryFile: "",
    newSitePlanimetryCorners: {
      lat_br: null,
      lat_bl: null,
      lat_tl: null,
      lat_tr: null,
      lon_bl: null,
      lon_br: null,
      lon_tl: null,
      lon_tr: null,
    },
    // Device and Alert Panel
    alertPanelOpen: false,
    devicePanelRows: [],
    alertPanelRows: [],
    // User Global Params
    username: undefined,
    email: undefined,
    permissions: 0,
    userProfilePic: "",
    userSite: undefined,
    userSiteName: undefined,
    userSitePath: undefined,
    userCoordinates: undefined,
    userLevel: undefined,
    childSiteLevel: undefined,
    userSiteIsLeaf: undefined,
    userSiteType: undefined,
    userAlerts: 0,
    userMaxSeverity: "none",
    // Device View Params
    selectedDevice: 0,
    deviceDetailsPanelOpen: false,
    deviceMeasureHistoryRows: [],
    deviceAlertsHistoryRows: [],
    deviceCurrentAlertsRows: [],
    deviceCurrentMeasures: {
      battery_level: 0,
      residual_battery: 0,
      antenna_signal: 0,
      temperature: 0,
      pres_atm: 0,
      humidity: 0,
      verticality: 0,
    },
    deviceConfig: {},
    deviceEditedConfig: {},
    // Utilities
    isLoading: false,
    userSettingsDialogOpen: false,
    addUserOnSite: false,
    addUserAction: false,
    siteToRemove: false,
    parentWasEmpty: false,
    forceParentChangeType: false,
    locationFinderResult: null
  },
  reducers: {
    setIsLeaf: (state, action) => {
      state.isLeaf = action.payload;
    },
    setSiteType: (state, action) => {
      state.siteType = action.payload;
    },
    setTriggerDataRefresh: (state, action) => {
      state.triggerDataRefresh = action.payload;
    },
    setTriggerDeviceDataRefresh: (state, action) => {
      state.triggerDeviceDataRefresh = action.payload;
    },
    setTriggerDeviceDetailsDataRefresh: (state, action) => {
      state.triggerDeviceDetailsDataRefresh = action.payload;
    },
    setTriggerAlertsDataRefresh: (state, action) => {
      state.triggerAlertsDataRefresh = action.payload;
    },
    setSelectedSite: (state, action) => {
      state.selectedSite = action.payload;
    },
    setSelectedSiteName: (state, action) => {
      state.selectedSiteName = action.payload;
    },
    setSelectedSiteCoordinates: (state, action) => {
      state.selectedSiteCoordinates = action.payload;
    },
    setAlertCount: (state, action) => {
      state.alertCount = action.payload;
    },
    setMaxSeverity: (state, action) => {
      state.maxSeverity = action.payload;
    },
    setPlanimetry: (state, action) => {
      state.planimetry = action.payload;
    },
    setBase64Planimetry: (state, action) => {
      state.base64Planimetry = action.payload;
    },
    setMaxZoom: (state, action) => {
      state.maxZoom = action.payload;
    },
    setMapCenter: (state, action) => {
      state.mapCenter = action.payload;
    },
    setNavbarSelectionPath: (state, action) => {
      state.navbarSelectionPath = action.payload;
    },
    setPoiCollection: (state, action) => {
      state.poiCollection = action.payload;
    },
    setPlanimetryEditMode: (state, action) => {
      state.planimetryEditMode = action.payload;
    },
    setAddDeviceOnTheMap: (state, action) => {
      state.addDeviceOnTheMap = action.payload;
    },
    setAddSiteOnTeMap: (state, action) => {
      state.addSiteOnTeMap = action.payload;
    },
    setAddEditSiteAction: (state, action) => {
      state.addEditSiteAction = action.payload;
    },
    setNewSiteName: (state, action) => {
      state.newSiteName = action.payload;
    },
    setNewSiteCoords: (state, action) => {
      state.newSiteCoords = action.payload;
    },
    setNewSitePlanimetryFile: (state, action) => {
      state.newSitePlanimetryFile = action.payload;
    },
    setNewSitePlanimetryCorners: (state, action) => {
      state.newSitePlanimetryCorners = action.payload;
    },
    setAlertPanelOpen: (state, action) => {
      state.alertPanelOpen = action.payload;
    },
    setDevicePanelRows: (state, action) => {
      state.devicePanelRows = action.payload;
    },
    setAlertPanelRows: (state, action) => {
      state.alertPanelRows = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setUserProfilePic: (state, action) => {
      state.userProfilePic = action.payload;
    },
    setUserSite: (state, action) => {
      state.userSite = action.payload;
    },
    setUserSiteName: (state, action) => {
      state.userSiteName = action.payload;
    },
    setUserSitePath: (state, action) => {
      state.userSitePath = action.payload;
    },
    setUserCoordinates: (state, action) => {
      state.userCoordinates = action.payload;
    },
    setUserLevel: (state, action) => {
      state.userLevel = action.payload;
    },
    setChildSiteLevel: (state, action) => {
      state.childSiteLevel = action.payload;
    },
    setUserSiteIsLeaf: (state, action) => {
      state.userSiteIsLeaf = action.payload;
    },
    setUserSiteType: (state, action) => {
      state.userSiteType = action.payload;
    },
    setUserAlerts: (state, action) => {
      state.userAlerts = action.payload;
    },
    setUserMaxSeverity: (state, action) => {
      state.userMaxSeverity = action.payload;
    },
    setSelectedDevice: (state, action) => {
      state.selectedDevice = action.payload;
    },
    setDeviceDetailsPanelOpen: (state, action) => {
      state.deviceDetailsPanelOpen = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDeviceMeasureHistoryRows: (state, action) => {
      state.deviceMeasureHistoryRows = action.payload;
    },
    setDeviceCurrentMeasures: (state, action) => {
      state.deviceCurrentMeasures = action.payload;
    },
    setDeviceAlertsHistoryRows: (state, action) => {
      state.deviceAlertsHistoryRows = action.payload;
    },
    setDeviceCurrentAlertsRows: (state, action) => {
      state.deviceCurrentAlertsRows = action.payload;
    },
    setDeviceConfig: (state, action) => {
      state.deviceConfig = action.payload;
    },
    setDeviceEditedConfig: (state, action) => {
      state.deviceEditedConfig = action.payload;
    },
    setUserSettingsDialogOpen: (state, action) => {
      state.userSettingsDialogOpen = action.payload;
    },
    setAddUserOnSite: (state, action) => {
      state.addUserOnSite = action.payload;
    },
    setAddUserAction: (state, action) => {
      state.addUserAction = action.payload;
    },
    setSiteToRemove: (state, action) => {
      state.siteToRemove = action.payload;
    },
    setParentWasEmpty: (state, action) => {
      state.parentWasEmpty = action.payload;
    },
    setForceParentChangeType: (state, action) => {
      state.forceParentChangeType = action.payload;
    },
    setLocationFinderResult: (state, action) => {
      state.locationFinderResult = action.payload;
    },
  },
});

export const {
  setIsLeaf,
  setSiteType,
  setTriggerDataRefresh,
  setTriggerDeviceDataRefresh,
  setTriggerDeviceDetailsDataRefresh,
  setTriggerAlertsDataRefresh,
  setSelectedSite,
  setSelectedSiteName,
  setSelectedSiteCoordinates,
  setAlertCount,
  setMaxSeverity,
  setPlanimetry,
  setBase64Planimetry,
  setMaxZoom,
  setMapCenter,
  setNavbarSelectionPath,
  setPoiCollection,
  setPlanimetryEditMode,

  setAddDeviceOnTheMap,
  setAddSiteOnTeMap,
  setAddEditSiteAction,
  setNewSiteName,
  setNewSiteCoords,
  setNewSitePlanimetryFile,
  setNewSitePlanimetryCorners,
  setAlertPanelOpen,
  setDevicePanelRows,
  setAlertPanelRows,
  setUsername,
  setEmail,
  setPermissions,
  setUserProfilePic,
  setUserSite,
  setUserSiteName,
  setUserSitePath,
  setUserCoordinates,
  setUserLevel,
  setChildSiteLevel,
  setUserSiteIsLeaf,
  setUserSiteType,
  setUserAlerts,
  setUserMaxSeverity,
  setSelectedDevice,
  setDeviceDetailsPanelOpen,
  setIsLoading,
  setDeviceMeasureHistoryRows,
  setDeviceCurrentMeasures,
  setDeviceAlertsHistoryRows,
  setDeviceCurrentAlertsRows,
  setDeviceConfig,
  setDeviceEditedConfig,
  setUserSettingsDialogOpen,
  setAddUserOnSite,
  setAddUserAction,
  setSiteToRemove,
  setParentWasEmpty,
  setForceParentChangeType,
  setLocationFinderResult
} = dashboardSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const setPoiCollectionAsync = poiCollection => dispatch => {
  setTimeout(() => {
    dispatch(setPoiCollection(poiCollection));
  }, 1000);
};

export const setDevicePanelRowsAsync = poiCollection => dispatch => {
  setTimeout(() => {
    dispatch(setDevicePanelRows(poiCollection));
  }, 10);
};

export const setAlertPanelRowsAsync = poiCollection => dispatch => {
  setTimeout(() => {
    dispatch(setAlertPanelRows(poiCollection));
  }, 10);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectIsLeaf = state => state.dashboard.isLeaf;
export const selectSiteType = state => state.dashboard.siteType;
export const selectTriggerDataRefresh = state => state.dashboard.triggerDataRefresh;
export const selectTriggerDeviceDataRefresh = state => state.dashboard.triggerDeviceDataRefresh;
export const selectTriggerDeviceDetailsDataRefresh = state => state.dashboard.triggerDeviceDetailsDataRefresh;
export const selectTriggerAlertsDataRefresh = state => state.dashboard.triggerAlertsDataRefresh;
export const selectSelectedSite = state => state.dashboard.selectedSite;
export const selectSelectedSiteName = state => state.dashboard.selectedSiteName;
export const selectSelectedSiteCoordinates = state => state.dashboard.selectedSiteCoordinates;
export const selectAlertCount = state => state.dashboard.alertCount;
export const selectMaxSeverity = state => state.dashboard.maxSeverity;
export const selectPlanimetry = state => state.dashboard.planimetry;
export const selectBase64Planimetry = state => state.dashboard.base64Planimetry;
export const selectMaxZoom = state => state.dashboard.maxZoom;
export const selectMapCenter = state => state.dashboard.mapCenter;
export const selectNavbarSelectionPath = state => state.dashboard.navbarSelectionPath;
export const selectPoiCollection = state => state.dashboard.poiCollection;
export const selectPlanimetryEditMode = state => state.dashboard.planimetryEditMode;

export const selectAddDeviceOnTheMap = state => state.dashboard.addDeviceOnTheMap;

export const selectAddSiteOnTheMap = state => state.dashboard.addSiteOnTeMap;
export const selectAddEditSiteAction = state => state.dashboard.addEditSiteAction;
export const selectNewSiteName = state => state.dashboard.newSiteName;
export const selectNewSiteCoords = state => state.dashboard.newSiteCoords;
export const selectNewSitePlanimetryFile = state => state.dashboard.newSitePlanimetryFile;
export const selectNewSitePlanimetryCorners = state => state.dashboard.newSitePlanimetryCorners;

export const selectAlertPanelOpen = state => state.dashboard.alertPanelOpen;
export const selectDevicePanelRows = state => state.dashboard.devicePanelRows;
export const selectAlertPanelRows = state => state.dashboard.alertPanelRows;
export const selectUsername = state => state.dashboard.username;
export const selectEmail = state => state.dashboard.email;
export const selectPermissions = state => state.dashboard.permissions;
export const selectUserProfilePic = state => state.dashboard.userProfilePic;
export const selectUserSite = state => state.dashboard.userSite;
export const selectUserSiteName = state => state.dashboard.userSiteName;
export const selectUserSitePath = state => state.dashboard.userSitePath;
export const selectUserCoordinates = state => state.dashboard.userCoordinates;
export const selectUserLevel = state => state.dashboard.userLevel;
export const selectChildSiteLevel = state => state.dashboard.childSiteLevel;
export const selectUserSiteIsLeaf = state => state.dashboard.userSiteIsLeaf;
export const selectUserSiteType = state => state.dashboard.userSiteType;
export const selectUserAlerts = state => state.dashboard.userAlerts;
export const selectUserMaxSeverity = state => state.dashboard.userMaxSeverity;
export const selectSelectedDevice = state => state.dashboard.selectedDevice;
export const selectDeviceDetailsPanelOpen = state => state.dashboard.deviceDetailsPanelOpen;
export const selectIsLoading = state => state.dashboard.isLoading;
export const selectDeviceMeasureHistoryRows = state => state.dashboard.deviceMeasureHistoryRows;
export const selectDeviceCurrentMeasures = state => state.dashboard.deviceCurrentMeasures;
export const selectDeviceAlertsHistoryRows = state => state.dashboard.deviceAlertsHistoryRows;
export const selectDeviceCurrentAlertsRows = state => state.dashboard.deviceCurrentAlertsRows;
export const selectDeviceConfig = state => state.dashboard.deviceConfig;
export const selectDeviceEditedConfig = state => state.dashboard.deviceEditedConfig;
export const selectUserSettingsDialogOpen = state => state.dashboard.userSettingsDialogOpen;
export const selectAddUserOnSite = state => state.dashboard.addUserOnSite;
export const selectAddUserAction = state => state.dashboard.addUserAction;
export const selectSiteToRemove = state => state.dashboard.siteToRemove;
export const selectParentWasEmpty = state => state.dashboard.parentWasEmpty;
export const selectForceParentChangeType = state => state.dashboard.forceParentChangeType;
export const selectLocationFinderResult = state => state.dashboard.locationFinderResult;

export default dashboardSlice.reducer;
