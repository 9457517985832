import * as React from 'react';
import { CellParams } from '@material-ui/x-grid';
import { last, startCase } from 'lodash';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux'
import { selectNavbarSelectionPath } from '../../redux/reducers/dashboardSlice';

export default function RenderPath(params: CellParams) {
    const navPath: any = useSelector(selectNavbarSelectionPath)
    let path = params.value!.toString()
    let arrPath = path.split('.')
    
    let out = arrPath.map((e: string) => {
        if (e !== last<any>(navPath).name)
            return <Typography key={e} variant="subtitle2" color="textSecondary">{startCase(e)}</Typography>
        return <></>
    })

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} className="selectionPath" maxItems={2} aria-label="breadcrumb">
            {out.slice(-2)}
        </Breadcrumbs>
    )
}
