const geocoder = require('node-open-geocoder');

async function geocode(address) {
    return await geocoder()
        .geocode(address, {})
}

function geocodeAsync(address, callback) {
    geocoder()
        .geocode(address)
        .end((err, res) => callback(res))
}

export {
    geocode,
    geocodeAsync
}