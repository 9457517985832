import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { 
  setUserSite, 
  selectIsLeaf, 
  selectIsLoading, 
  setEmail, 
  setUsername, 
  setUserLevel, 
  setUserSiteIsLeaf,
  setUserCoordinates,
  setUserSiteName,
  setUserSitePath,
  selectUsername,
  setPlanimetry,
  setPermissions,
  setChildSiteLevel,
  setUserSiteType
} from '../redux/reducers/dashboardSlice'
import { MyAlertsPanel, MyDevicePanel, MyDeviceDetailPanel, MyTreeViewPanel, MyMap, MyNavBar } from "../Components"
import { createMuiTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'fontsource-roboto';
import "leaflet/dist/leaflet.css";
import "./Dashboard.css";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
    color: '#fff',
  },
}));

/**
 * The Dashboard component
 *
 * @export
 * @return {*} 
 */
export default function Dashboard(props) {
  const [alertPanelShow, setAlertPanelShow] = useState(false)
  const [devicePanelShow, setDevicePanelShow] = useState(false)
  const [treeViewPanelShow, setTreeViewPanelShow] = useState(false)
  const dispatch = useDispatch()
  const isLeaf = useSelector(selectIsLeaf)
  const isLoading = useSelector(selectIsLoading)
  const username = useSelector(selectUsername)
  const classes = useStyles();
  const palletType = "dark"
  const darkTheme = createMuiTheme({
    palette: {
      type: palletType,
    }
  });
  
  useEffect(() => {
    //console.log(props.sitePlanimetry)
    dispatch(setUserSite(props.siteId)) // Salva su redux il siteId
    dispatch(setUserSiteName(props.siteName)) // Salva su redux il siteName
    dispatch(setUserSitePath(props.sitePath)) // Salva su redux il sitePath
    dispatch(setPlanimetry(props.sitePlanimetry)) // Salva su redux l'oggetto della planimetria (se il sito main è foglia)
    dispatch(setUserLevel(props.siteLevel)) // Salva su redux il siteLevel
    dispatch(setChildSiteLevel(props.childSiteLevel)) // Salva su redux il siteLevel
    dispatch(setUserSiteType(props.siteType)) // Salva su redux la variabile isLeaf del suo sito
    dispatch(setUserSiteIsLeaf(props.siteIsLeaf)) // Salva su redux la variabile isLeaf del suo sito
    dispatch(setUserCoordinates(props.siteCoordinates)) // Salva su redux le coordinate del sito
    dispatch(setPermissions(props.permissions))// Salva su redux l'username
    dispatch(setEmail(props.email))// Salva su redux l'username
    dispatch(setUsername(props.username))// Salva su redux l'username
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (username !== undefined) {
    return (
      <ThemeProvider theme={darkTheme}>
        <MyNavBar id="navBar" active="Dashboard"></MyNavBar>
        <MyMap useFlyTo={false} siteCoordinates={props.siteCoordinates}></MyMap>
        <MyTreeViewPanel
          show={treeViewPanelShow}
          onHide={() => setTreeViewPanelShow(false)}
        ></MyTreeViewPanel>
        <MyAlertsPanel
          history={false}
          show={alertPanelShow}
          onHide={() => setAlertPanelShow(false)}
        ></MyAlertsPanel>
        {isLeaf? (
          <>
            <MyDevicePanel
              show={devicePanelShow}
              onHide={() => setDevicePanelShow(false)}
            ></MyDevicePanel>
            <MyDeviceDetailPanel></MyDeviceDetailPanel>
          </>
        ) : (
          undefined
        )}
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    );
  }
  else {
    return (<></>)
  }
}