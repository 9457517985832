import * as React from 'react';
import clsx from 'clsx';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import InfoIcon from '@material-ui/icons/Info';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DoneIcon from '@material-ui/icons/Done';
import Chip from '@material-ui/core/Chip';
import { CellParams } from '@material-ui/x-grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'left',
      '& .icon': {
        color: 'inherit',
      },
    },
    Unknown: {
      color: theme.palette.info.dark,
      border: `1px solid ${theme.palette.info.main}`,
    },
    none: {
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.primary}`,
    },
    warning: {
      color: theme.palette.warning.dark,
      border: `1px solid ${theme.palette.warning.main}`,
    },
    alarm: {
      color: theme.palette.error.dark,
      border: `1px solid ${theme.palette.error.main}`,
    },
    critical: {
      color: theme.palette.error.dark,
      border: `1px solid ${theme.palette.error.main}`,
    },
  }),
);

interface SeverityProps {
  severity: string;
}

const Severity = React.memo((props: SeverityProps) => {
  const { severity } = props;
  let icon: any = null;
  const classes: any = useStyles();
  if (severity === 'Unknown') {
    icon = <ReportProblemIcon className="icon" />;
  } else if (severity === 'none') {
    icon = <InfoIcon className="icon" />;
  } else if (severity === 'warning') {
    icon = <InfoIcon className="icon" />;
  } else if (severity === 'alarm') {
    icon = <AutorenewIcon className="icon" />;
  } else if (severity === 'critical') {
    icon = <DoneIcon className="icon" />;
  }
  let label = severity;
  switch (severity) {
    case 'none':
      label = "None"
      break;
    case 'warning':
      label = "Warning"
      break;
    case 'alarm':
      label = "Alarm"
      break;
    case 'critical':
      label = "Critical"
      break;
    default:
      label = "No Severity"
      break;
  }
  return (
    <Chip
      className={clsx(classes.root, classes[severity])}
      icon={icon}
      size="small"
      label={label}
      variant="outlined"
    />
  );
});

export function renderSeverity(params: CellParams) {
  return <Severity severity={params.value!.toString()} />;
}
