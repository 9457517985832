import * as React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import SpeedIcon from '@material-ui/icons/Speed';
import SyncIcon from '@material-ui/icons/Sync';
import { CellParams } from '@material-ui/x-grid';

interface MsgTypeProps {
  value: number;
}

/**
 * Types of update a client can expect about a board.
 * The string representation is the only one that matters
 */
const MessageTypes: any = Object.freeze({
  "1": {name: "heartbeat", icon: <SpeedIcon fontSize="small" />},
  "2": {name: "alert", icon: <WarningIcon fontSize="small" />},
  "3": {name: "queryStateConfig", icon: <SyncIcon fontSize="small" />}
})

const MsgType = React.memo(function MsgType(props: MsgTypeProps) {
  return MessageTypes[props.value].icon;
});

export function renderType(params: CellParams) {
  if (params.value === null || !params.value || params.value > Object.keys(MessageTypes).length) {
    return <MsgType value={1} />;
  }
  return <MsgType value={Number(params.value)!} />;
}
