import { pullAll, union } from "lodash"

const configSchema = {
    type: "object",
    properties: {
        battery_level: {
            type: "object",
            properties: {
                thresholds: {
                    type: "object"
                }
            }
        },
        antenna_signal: {
            type: "object",
            properties: {
                thresholds: {
                    type: "object"
                }
            }
        },
        residual_battery: {
            type: "object",
            properties: {
                thresholds: {
                    type: "array",
                    maxItems: 3,
                    items: {
                        type: "object",
                        properties: {
                            value: {
                                type: "number",
                                minimum: 0
                            },
                            severity: {
                                type: "string",
                                enum: ["warning", "alarm", "critical"]
                            }
                        }
                    }
                }
            }
        },
        temperature: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["yes", "no"]
                },
                thresholds: {
                    type: "object",
                    properties: {
                        min: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number",
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        },
                        max: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number",
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        pres_atm: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["yes", "no"]
                },
                thresholds: {
                    type: "object",
                    properties: {
                        min: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number",
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        },
                        max: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number",
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        humidity: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    example: "ciao",
                    enum: ["yes", "no"]
                },
                thresholds: {
                    type: "object",
                    properties: {
                        min: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number",
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        },
                        max: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number",
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        moved: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["yes", "no"]
                },
                // thresholds: {
                //     type: "object",
                //     readOnly: true,
                //     properties: {
                //         max: {
                //             type: "array",
                //             minItems: 0,
                //             maxItems: 3,
                //             items: {
                //                 type: "object",
                //                 properties: {
                //                     value: {
                //                         type: "number",
                //                     },
                //                     severity: {
                //                         type: "string",
                //                         enum: ["warning", "alarm", "critical"]
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // }
            }
        },
        verticality: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["yes", "no"]
                },
                thresholds: {
                    type: "object",
                    properties: {
                        min: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number",
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        },
                        max: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number",
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        closed_door: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["no", "yesPolling", "yesInterrupt"],
                },
                // activeHigh: {
                //     type: "string",
                //     readOnly: true,
                //     enum: ["activeHigh", "activeLow"]
                // },
                alertOn: {
                    type: "string",
                    enum: ["alertOnFalse", "alertOnTrue", "noAssociatedAlert"]
                },
                timeFilter: {
                    readOnly: false,
                    type: "number"
                },
                severity: {
                    type: "string",
                    enum: ["warning", "alarm", "critical"]
                }
            }
        },
        comb_door: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["yes", "no"]
                },
                // sxDx: {
                //     type: "string",
                //     readOnly: true,
                //     enum: ["sx", "dx"]
                // },
                thresholds: {
                    type: "array",
                    maxItems: 2,
                    items: {
                        type: "object",
                        properties: {
                            value: {
                                type: "string",
                                enum: ["closed", "partial", "open"]
                            },
                            severity: {
                                type: "string",
                                enum: ["warning", "alarm", "critical"]
                            }
                        }
                    }
                }
            }
        },
        distance: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["yes", "no"]
                },
                // baseInterval: {
                //     type: "number",
                //     readOnly: true
                // },
                thresholds: {
                    type: "object",
                    properties: {
                        max: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        analogic_sensor_1: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["yes", "no"]
                },
                description: {
                    type: "string"
                },
                connectedExtSensor: {
                    type: "string",
                    enum: ["Tensione", "Pressione0_16Bar"]
                },
                ref_min: {
                    type: "number",
                    minimum: 0
                },
                ref_max: {
                    type: "number",
                    minimum: 0
                },
                ref_uom: {
                    type: "string",
                },
                thresholds: {
                    type: "object",
                    properties: {
                        min: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number"
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        },
                        max: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number"
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        analogic_sensor_2: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["yes", "no"]
                },
                description: {
                    type: "string"
                },
                connectedExtSensor: {
                    type: "string",
                    enum: ["Tensione", "Pressione0_16Bar"]
                },
                ref_min: {
                    type: "number",
                    minimum: 0
                },
                ref_max: {
                    type: "number",
                    minimum: 0
                },
                ref_uom: {
                    type: "string",
                },
                thresholds: {
                    type: "object",
                    properties: {
                        min: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number"
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        },
                        max: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number"
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        analogic_sensor_3: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["yes", "no"]
                },
                description: {
                    type: "string"
                },
                connectedExtSensor: {
                    type: "string",
                    enum: ["Tensione", "Pressione0_16Bar"]
                },
                ref_min: {
                    type: "number",
                    minimum: 0
                },
                ref_max: {
                    type: "number",
                    minimum: 0
                },
                ref_uom: {
                    type: "string",
                },
                thresholds: {
                    type: "object",
                    properties: {
                        min: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number"
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        },
                        max: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number"
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        analogic_sensor_4: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["yes", "no"]
                },
                description: {
                    type: "string"
                },
                connectedExtSensor: {
                    type: "string",
                    enum: ["Tensione", "Pressione0_16Bar"]
                },
                ref_min: {
                    type: "number",
                    minimum: 0
                },
                ref_max: {
                    type: "number",
                    minimum: 0
                },
                ref_uom: {
                    type: "string",
                },
                thresholds: {
                    type: "object",
                    properties: {
                        min: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number"
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        },
                        max: {
                            type: "array",
                            minItems: 0,
                            maxItems: 3,
                            items: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "number"
                                    },
                                    timeFilter: {
                                        readOnly: false,
                                        type: "number"
                                    },
                                    severity: {
                                        type: "string",
                                        enum: ["warning", "alarm", "critical"]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        digital_sensor_1: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["no", "yesPolling", "yesInterrupt"]
                },
                description: {
                    type: "string"
                },
                activeHigh: {
                    type: "string",
                    enum: ["activeHigh", "activeLow"]
                },
                alertOn: {
                    type: "string",
                    enum: ["alertOnFalse", "alertOnTrue", "noAssociatedAlert"]
                },
                timeFilter: {
                    readOnly: false,
                    type: "number"
                },
                severity: {
                    type: "string",
                    enum: ["warning", "alarm", "critical"]
                }
            }
        },
        digital_sensor_2: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["no", "yesPolling", "yesInterrupt"]
                },
                description: {
                    type: "string"
                },
                activeHigh: {
                    type: "string",
                    enum: ["activeHigh", "activeLow"]
                },
                alertOn: {
                    type: "string",
                    enum: ["alertOnFalse", "alertOnTrue", "noAssociatedAlert"]
                },
                timeFilter: {
                    readOnly: false,
                    type: "number"
                },
                severity: {
                    type: "string",
                    enum: ["warning", "alarm", "critical"]
                }
            }
        },
        digital_sensor_3: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["no", "yesPolling", "yesInterrupt"]
                },
                description: {
                    type: "string"
                },
                activeHigh: {
                    type: "string",
                    enum: ["activeHigh", "activeLow"]
                },
                alertOn: {
                    type: "string",
                    enum: ["alertOnFalse", "alertOnTrue", "noAssociatedAlert"]
                },
                timeFilter: {
                    readOnly: false,
                    type: "number"
                },
                severity: {
                    type: "string",
                    enum: ["warning", "alarm", "critical"]
                }
            }
        },
        digital_sensor_4: {
            type: "object",
            properties: {
                active: {
                    type: "string",
                    enum: ["no", "yesPolling", "yesInterrupt"]
                },
                description: {
                    type: "string"
                },
                activeHigh: {
                    type: "string",
                    enum: ["activeHigh", "activeLow"]
                },
                alertOn: {
                    type: "string",
                    enum: ["alertOnFalse", "alertOnTrue", "noAssociatedAlert"]
                },
                timeFilter: {
                    readOnly: false,
                    type: "number"
                },
                severity: {
                    type: "string",
                    enum: ["warning", "alarm", "critical"]
                }
            }
        },
        // digital_sensor_5: {
        //     type: "object",
        //     properties: {
        //         active: {
        //             type: "string",
        //             enum: ["no", "yesPolling", "yesInterrupt"]
        //         },
        //         description: {
        //             type: "string"
        //         },
        //         activeHigh: {
        //             type: "string",
        //             enum: ["activeHigh", "activeLow"]
        //         },
        //         alertOn: {
        //             type: "string",
        //             enum: ["alertOnFalse", "alertOnTrue", "noAssociatedAlert"]
        //         },
        //         timeFilter: {
        //             readOnly: false,
        //             type: "number"
        //         },
        //         severity: {
        //             type: "string",
        //             enum: ["warning", "alarm", "critical"]
        //         }
        //     }
        // },
        // digital_sensor_6: {
        //     type: "object",
        //     properties: {
        //         active: {
        //             type: "string",
        //             enum: ["no", "yesPolling", "yesInterrupt"]
        //         },
        //         description: {
        //             type: "string"
        //         },
        //         activeHigh: {
        //             type: "string",
        //             enum: ["activeHigh", "activeLow"]
        //         },
        //         alertOn: {
        //             type: "string",
        //             enum: ["alertOnFalse", "alertOnTrue", "noAssociatedAlert"]
        //         },
        //         timeFilter: {
        //             readOnly: false,
        //             type: "number"
        //         },
        //         severity: {
        //             type: "string",
        //             enum: ["warning", "alarm", "critical"]
        //         }
        //     }
        // },
        // digital_sensor_7: {
        //     type: "object",
        //     properties: {
        //         active: {
        //             type: "string",
        //             enum: ["no", "yesPolling", "yesInterrupt"]
        //         },
        //         description: {
        //             type: "string"
        //         },
        //         activeHigh: {
        //             type: "string",
        //             enum: ["activeHigh", "activeLow"]
        //         },
        //         alertOn: {
        //             type: "string",
        //             enum: ["alertOnFalse", "alertOnTrue", "noAssociatedAlert"]
        //         },
        //         timeFilter: {
        //             readOnly: false,
        //             type: "number"
        //         },
        //         severity: {
        //             type: "string",
        //             enum: ["warning", "alarm", "critical"]
        //         }
        //     }
        // },
        // digital_sensor_8: {
        //     type: "object",
        //     properties: {
        //         active: {
        //             type: "string",
        //             enum: ["no", "yesPolling", "yesInterrupt"]
        //         },
        //         description: {
        //             type: "string"
        //         },
        //         activeHigh: {
        //             type: "string",
        //             enum: ["activeHigh", "activeLow"]
        //         },
        //         alertOn: {
        //             type: "string",
        //             enum: ["alertOnFalse", "alertOnTrue", "noAssociatedAlert"]
        //         },
        //         timeFilter: {
        //             readOnly: false,
        //             type: "number"
        //         },
        //         severity: {
        //             type: "string",
        //             enum: ["warning", "alarm", "critical"]
        //         }
        //     }
        // },
    }
}

const defaultSensorsUnit = {
    battery_level: "volt",
    antenna_signal: "num 0/31",
    residual_battery: "days",
    temperature: "°C",
    pres_atm: "mbar",
    humidity: "%",
    moved: "count",
    verticality: "°",
    closed_door: "",
    comb_door: "",
    distance: "",
}

function getSensorsProps() {
    return Object.keys(configSchema.properties)
}

function getFirstLevelProps() {
    let props = []
    for (let sensor of getSensorsProps()) {
        props = union(props, Object.keys(configSchema.properties[sensor].properties))
    }
    return pullAll(props, ["severity", "timeFilter", "thresholds"])
}

const sensors = getSensorsProps()
const firstLevelProps = getFirstLevelProps()
const inputsFields = ["value", "timeFilter", "severity"]

function checkIfPropIsASensor(prop) {
    return sensors.includes(prop.toString())
}

function getDefaultSensorUnit(prop) {
    if (checkIfPropIsASensor(prop)) {
        if (Object.keys(defaultSensorsUnit).includes(prop)) {
            return defaultSensorsUnit[prop]
        }
    }
    return false
}

function checkIfPropIsAFirstLevel(prop) {
    return firstLevelProps.includes(prop.toString())
}

function checkIfPropIsAInputField(prop) {
    return inputsFields.includes(prop.toString())
}

export {
    configSchema,
    sensors,
    firstLevelProps,
    inputsFields,
    checkIfPropIsASensor,
    checkIfPropIsAFirstLevel,
    checkIfPropIsAInputField,
    getDefaultSensorUnit
}