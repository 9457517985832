import { CellParams } from '@material-ui/x-grid';
import { renderTemperature } from './renderTemperature';
import { renderPercentage } from './renderPercentage';
import { renderVBatt } from './renderVBatt';
import { renderUMTS } from './renderUMTS';
import { renderPressure } from './renderPressure';
import { renderAnalogs, renderAnalogsWithValue } from './renderAnalogs';
import { renderNormalNumber } from './renderNormalNumber';
import { renderDone } from './renderDone';
import { renderCombDoor } from './renderCombDoor';
import { renderVerticality } from './renderVerticality';
import { renderHumidity } from './renderHumidity';
import { renderMove } from './renderMove';

export function renderMultipleSensor(params: CellParams) {
    let sensor = JSON.parse(params.value!.toString()).sensor
    let val = JSON.parse(params.value!.toString()).value
    let _params = params
    _params.value = val
    if (val !== 'N/a') {
        switch (sensor) {
            case 'temperature':
                return renderTemperature(_params)
            case 'battery_level':
                return renderVBatt(_params)
            case 'antenna_signal':
                return renderUMTS(_params)
            case 'residual_battery':
                return renderPercentage(_params)
            case 'pres_atm':
                return renderPressure(_params)
            case 'humidity':
                return renderHumidity(_params)
            case 'moved':
                return renderMove(_params)
            case 'verticality':
                return renderVerticality(_params)
            case 'closed_door':
                return renderDone(_params)
            case 'comb_door':
                return renderCombDoor(_params)
            case 'distance':
                return renderNormalNumber(_params)
            case 'open_close_cycles':
                return renderNormalNumber(_params)
            case 'digital_sensor_1':
            case 'digital_sensor_2':
            case 'digital_sensor_3':
            case 'digital_sensor_4':
            case 'digital_sensor_5':
            case 'digital_sensor_6':
            case 'digital_sensor_7':
            case 'digital_sensor_8':
                return renderDone(_params)
            case 'analogic_sensor_1':
            case 'analogic_sensor_2':
            case 'analogic_sensor_3':
            case 'analogic_sensor_4':
                return renderAnalogs(_params)
            case 'analogic_sensor_value':
                return renderAnalogsWithValue(_params)
            case 'network_no_heartbeat':
                return renderNormalNumber(_params)
            case 'network_malformed':
                return renderNormalNumber(_params)
            default:
                return renderNormalNumber(_params)
        }
    } else {
        return renderNormalNumber(_params)
    }
}
