import * as React from 'react';
import clsx from 'clsx';
import BuildIcon from '@material-ui/icons/Build';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Chip from '@material-ui/core/Chip';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { CellParams } from '@material-ui/x-grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'left',
      '& .icon': {
        color: 'inherit',
      },
    },
    Unknown: {
      color: theme.palette.info.dark,
      border: `1px solid ${theme.palette.info.main}`,
    },
    operational: {
      color: theme.palette.success.dark,
      border: `1px solid ${theme.palette.success.main}`,
    },
    maintenance: {
      color: theme.palette.info.dark,
      border: `1px solid ${theme.palette.info.main}`,
    },
  }),
);

interface OperativeStatusProps {
  operativeStatus: string;
}

const OperativeStatus = React.memo((props: OperativeStatusProps) => {
  const { operativeStatus } = props;
  let icon: any = null;
  let label = operativeStatus;
  const classes: any = useStyles();
  
  switch (operativeStatus) {
    case 'Operational':
      label = "Operational"
      icon = <DoneIcon className="icon" />
      break;
    case 'Maintenance':
      label = "In maintenance"
      icon = <BuildIcon className="icon" />
      break;
    case 'Off':
      label = "Off"
      icon = <PowerSettingsNewIcon className="icon" />
      break;
    default:
      label = "No operativeStatus"
      icon = <ErrorOutlineIcon className="icon" />
      break;
  }
  return (
    <Chip
      className={clsx(classes.root, classes[operativeStatus])}
      icon={icon}
      size="small"
      label={label}
      variant="outlined"
    />
  );
});

export function renderOperativeStatus(params: CellParams) {
  return <OperativeStatus operativeStatus={params.value!.toString()} />;
}
