import * as React from 'react';
import { CellParams } from '@material-ui/x-grid';
import ClearIcon from '@material-ui/icons/Clear';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

interface MoveProps {
    value: number;
}

const Move = React.memo(function Pnl(props: MoveProps) {
    const { value } = props;

    return (
        <>
            {value === 0 ? <ClearIcon fontSize="small" /> : <SwapHorizIcon fontSize="small" />}
        </>
    );
});

export function renderMove(params: CellParams) {
    return <Move value={params.value as any} />;
}
