import React from 'react';

import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

import { ObjectFieldTemplateProps } from '@rjsf/core';
import { utils } from '@rjsf/core';

import AddButton from './AddButton';
import { checkIfPropIsAFirstLevel, checkIfPropIsAInputField, checkIfPropIsASensor, getDefaultSensorUnit } from './configSchema';
import { startCase } from 'lodash';
import { Box, Tab, Tabs, Theme } from '@material-ui/core';
import { getSensorPropsFromName } from './renderSensor';

const { canExpand } = utils;

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className={classes.tabPan}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '70vh',
        width: '92vw',
        marginTop: theme.spacing(1)
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '15%',
        minWidth: '20ch'
    },
    root_2: {
        marginTop: 10,
    },
    tabPan: {
        width: '85%',
        overflowY: 'scroll'
    }
}));

const hidden_elements = ["battery_level", "antenna_signal"]

const ObjectFieldTemplate = ({
    DescriptionField,
    description,
    TitleField,
    title,
    properties,
    required,
    disabled,
    readonly,
    uiSchema,
    idSchema,
    schema,
    formData,
    onAddClick,
}: ObjectFieldTemplateProps) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    let check = false
    if (properties) {
        properties.forEach((el) => {
            if (checkIfPropIsASensor(el.name)) {
                check = true
            } else {
                check = false
            }
        })
    }

    if (check) {
        return (
            <>
                <div className={classes.root}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Sensors"
                        className={classes.tabs}
                    >
                        {properties.map((element: any, index: number) => {
                            let disabled = false
                            if (hidden_elements.includes(element.name)) {
                                disabled = true
                                if (value === index) {
                                    setValue(value + 1)
                                }
                            }
                            const { label, icon } = getSensorPropsFromName(element.name)
                            return (
                            <Tab disabled={disabled} className={disabled ? 'hideBtn' : ''} icon={icon} key={index} label={startCase(label)} {...a11yProps(index)} />
                        )})}
                    </Tabs>
                    {properties.map((element: any, index: number) => (
                        <TabPanel key={index} value={value} index={index}>{element.content}</TabPanel>
                    ))}
                </div>
            </>
        );
    } else {
        return (
            <>
                {(uiSchema['ui:title'] || title) && (
                    <TitleField
                        id={`${idSchema.$id}-title`}
                        title={`${startCase(title)}${getDefaultSensorUnit(title) ? ` (${getDefaultSensorUnit(title)})` : ""}`}
                        required={required}
                    />
                )}
                {description && (
                    <DescriptionField
                        id={`${idSchema.$id}-description`}
                        description={description}
                    />
                )}
                <Grid container={true} spacing={2} className={classes.root_2}>
                    {properties.map((element: any, index: number) => (
                        <Grid
                            item={true}
                            xs={12}
                            sm={checkIfPropIsAFirstLevel(element.name) ? 6 : checkIfPropIsAInputField(element.name) ? 4 : 12}
                            md={checkIfPropIsAFirstLevel(element.name) ? 4 : checkIfPropIsAInputField(element.name) ? 4 : 12}
                            lg={checkIfPropIsAFirstLevel(element.name) ? 3 : checkIfPropIsAInputField(element.name) ? 4 : 12}
                            key={index}
                            style={{ marginBottom: '10px' }}
                        >
                            {element.content}
                        </Grid>
                    ))}
                    {canExpand(schema, uiSchema, formData) && (
                        <Grid container justify='flex-end'>
                            <Grid item={true}>
                                <AddButton
                                    className='object-property-expand'
                                    onClick={onAddClick(schema)}
                                    disabled={disabled || readonly}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    }
};

export default ObjectFieldTemplate;