import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CropRotateRoundedIcon from '@material-ui/icons/CropRotateRounded';
import OpenWithRoundedIcon from '@material-ui/icons/OpenWithRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import AspectRatioRoundedIcon from '@material-ui/icons/AspectRatioRounded';
import EditIcon from '@material-ui/icons/Edit';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import MapIcon from '@material-ui/icons/Map';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectPlanimetry,
    selectSelectedSite,
    selectBase64Planimetry,
    selectPermissions,
    setPlanimetryEditMode,
    setAddSiteOnTeMap,
    setAddEditSiteAction,
    setAddDeviceOnTheMap,
    setAddUserOnSite,
    setAddUserAction,
    selectSiteType,
} from '../redux/reducers/dashboardSlice';


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: 80,
        transform: 'translateZ(0px)',
        flexGrow: 1,
    },
    exampleWrapper: {
        position: 'relative',
        marginTop: theme.spacing(1),
        height: 380,
    },
    radioGroup: {
        margin: theme.spacing(1, 0),
    },
    speedDial: {
        zIndex: 20000,
        position: 'absolute',
        '& .MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
            bottom: theme.spacing(1),
            right: theme.spacing(1),
        },
        '& .MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
            top: theme.spacing(1),
            left: theme.spacing(1),
        },
        '& .MuiSpeedDialAction-staticTooltipLabel': {
            width: '180px'
        }
    },
}));

const planimetry_actions = [
    { icon: <MapIcon />, name: 'Edit Site', id: 'edit_site' },
    { icon: <OpenWithRoundedIcon />, name: 'Translate Planimetry', id: 'translate' },
    { icon: <CropRotateRoundedIcon />, name: 'Rotate Planimetry', id: 'rotate' },
    { icon: <AspectRatioRoundedIcon />, name: 'Scale Planimetry', id: 'scale' },
    { icon: <LockRoundedIcon />, name: 'Lock Planimetry', id: 'lock' },
];

const new_planimetry_actions = [
    { icon: <AddLocationIcon />, name: 'Add Site', id: 'add_site' },
    { icon: <EditLocationIcon />, name: 'Edit Site', id: 'edit_site' },
];

const site_actions = [
    { icon: <AddLocationIcon />, name: 'Add Site', id: 'add_site' },
    { icon: <EditLocationIcon />, name: 'Edit Site', id: 'edit_site' },
    { icon: <PersonAddIcon />, name: 'Manage Users', id: 'add_user' },
];

const leaf_actions = [
    { icon: <PersonAddIcon />, name: 'Manage Users', id: 'add_user' },
    { icon: <AddLocationIcon />, name: 'Add Device', id: 'add_device' },
];

export default function EditMapControl(props) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const base64Planimetry = useSelector(selectBase64Planimetry)
    const planimetry = useSelector(selectPlanimetry)
    const selectedSiteId = useSelector(selectSelectedSite)
    const siteType = useSelector(selectSiteType)
    const [open, setOpen] = React.useState(false);
    const [actions, setActions] = React.useState([])
    const permissions = useSelector(selectPermissions)

    const handleOpen = () => {
        setOpen(true);
    };

    const manageAction = (command) => {
        switch (command) {
            case "close":
                setOpen(false)
                break
            case "add_site":
                dispatch(setAddEditSiteAction(true))
                dispatch(setAddSiteOnTeMap(true))
                break
            case "edit_site":
                dispatch(setAddEditSiteAction(false))
                dispatch(setAddSiteOnTeMap(true))
                break
            case "add_device":
                dispatch(setAddDeviceOnTheMap(true))
                break
            case "add_user":
                dispatch(setAddUserOnSite(true))
                dispatch(setAddUserAction(true))
                break
            case "translate":
                dispatch(setPlanimetryEditMode("translate"))
                break
            case "rotate":
                dispatch(setPlanimetryEditMode("rotate"))
                break
            case "scale":
                dispatch(setPlanimetryEditMode("scale"))
                break
            case "lock":
                dispatch(setPlanimetryEditMode("lock"))
                break
            default:
                break
        }
    };

    useEffect(() => {
        let _actions = []
        switch (siteType) {
            case 'leaf_with_devices':
            case 'leaf_without_devices':
                leaf_actions.forEach(e => {
                    _actions.push(e)
                })
                if (planimetry === undefined || planimetry.file_name === undefined || base64Planimetry === "") {
                    new_planimetry_actions.forEach(e => {
                        _actions.push(e)
                    })
                } else {
                    planimetry_actions.forEach(e => {
                        _actions.push(e)
                    })
                }
                break
            case 'not_leaf':
                site_actions.forEach(e => {
                    _actions.push(e)
                })
                break
            default:
                break
        }

        // Check permissions and remove not available options
        for (let i in _actions) {
            if (_actions[i].id === 'add_user') {
                if (!permissions.users_management) {
                    delete _actions[i]
                    continue
                }
            }
            if (_actions[i].id === 'edit_site' || _actions[i].id === 'add_site' || _actions[i].id === 'translate' ||
                _actions[i].id === 'rotate' || _actions[i].id === 'scale' || _actions[i].id === 'lock') {
                if (!permissions.sites_management) {
                    delete _actions[i]
                    continue
                }
            }
            if (_actions[i].id === 'add_device') {
                if (!permissions.devices_management)
                    delete _actions[i]
            }
        }
        setActions(_actions)
    }, [selectedSiteId, siteType, planimetry, base64Planimetry]) // eslint-disable-line react-hooks/exhaustive-deps

    for (let i in actions) {
        if (actions[i])
            return (
                <div className={classes.exampleWrapper}>
                    <SpeedDial
                        ariaLabel=""
                        className={classes.speedDial}
                        icon={<EditIcon />}
                        open={open}
                        onClose={(e, r) => {
                            if (r !=='mouseLeave' && r !== 'focus')
                                manageAction("close")
                        }}
                        onOpen={(e, r) => {
                            if (r !== 'mouseEnter' && r !== 'focus')
                                handleOpen()
                        }}
                        FabProps={{
                            onClick: handleOpen
                        }}
                        direction='down'
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                tooltipOpen
                                tooltipPlacement="right"
                                onClick={() => manageAction(action.id)}
                            />
                        ))}
                    </SpeedDial>
                </div>
            )
    }
    return <></>
}