import * as React from 'react';
import { CellParams } from '@material-ui/x-grid';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';

interface NormalNumberProps {
    value: number;
}

const NormalNumber = React.memo(function Pnl(props: NormalNumberProps) {
    const { value } = props;

    return (
        <>
            {value === 0 ? <DoneIcon fontSize="small" /> : <CloseIcon fontSize="small" />} <Typography variant="h5">  |  </Typography> {value === 1 ? <DoneIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
        </>
    );
});

export function renderCombDoor(params: CellParams) {
    return <NormalNumber value={params.value as any} />;
}
