import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    selectUserCoordinates,
    selectUserLevel,
    selectUserSite,
    selectUserSiteIsLeaf,
    setIsLeaf,
    setIsLoading,
    setSelectedSite,
    setSelectedSiteName,
    setAlertCount,
    setMaxSeverity,
    setPlanimetry,
    setMaxZoom,
    setNavbarSelectionPath,
    setPoiCollection,
    setUserAlerts,
    setUserMaxSeverity,
    selectSelectedSite,
    selectUserSiteName,
    selectUserSitePath,
    selectPlanimetry,
    selectTriggerDataRefresh,
    setTriggerDataRefresh,
    selectChildSiteLevel,
    setSelectedSiteCoordinates,
    setSiteType,
    selectUserSiteType,
    setSiteToRemove,
    selectSiteToRemove,
    setParentWasEmpty,
    selectParentWasEmpty,
    selectForceParentChangeType,
    setForceParentChangeType,
} from '../redux/reducers/dashboardSlice';
import { GetTreeView, GetSiteAnalytics } from "../BkConnect"
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import "../Components/MyTreeViewPanel.css"
import { startCase } from "lodash";
import { grey } from "@material-ui/core/colors";

const defaultTree = {
    children: []
}
const cachedTree = defaultTree; // The tree

/** @type {*} Collection of the CSS for the treeview */
const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color)`,
            color: 'var(--tree-view-color)',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            borderTopRightRadius: theme.spacing(2),
            borderBottomRightRadius: theme.spacing(2),
        },
        '&$selected > $content $label': {
            backgroundColor: 'unset'
        }
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(0),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelIconInvisible: {
        marginRight: theme.spacing(1),
        color: "rgba(0, 0, 0, 0)"
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
    labelInfo: {
        marginRight: theme.spacing(2),
    },
}));

/**
 * Use this to dynamically change the styles of the TreeView Items.
 *
 * @param {*} props All the variable should be here
 * @return {*} 
 */
function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, selectFunc, expandFunc, isLeaf, ...other } = props;

    return (
        <TreeItem
            collapseIcon={<ArrowDropDownIcon onClick={expandFunc} />}
            expandIcon={<ArrowRightIcon onClick={expandFunc} />}
            label={
                <div className={classes.labelRoot}>
                    {Number(labelInfo.substring(labelInfo.indexOf(": ") + 2)) === 0 ?
                        <LabelIcon color="inherit" className={classes.labelIconInvisible} onClick={expandFunc} /> :
                        <LabelIcon color="inherit" className={classes.labelIcon} onClick={expandFunc} />
                    }
                    <Typography variant="body2" className={classes.labelText} onClick={selectFunc}>
                        {startCase(labelText)}
                    </Typography>
                    <Typography variant="caption" className={classes.labelInfo} color="inherit">
                        {labelInfo}
                    </Typography>
                    {/*<Button variant="link" color="primary" onClick={selectFunc}>SELECT</Button>*/}
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    selectFunc: PropTypes.func.isRequired,
    expandFunc: PropTypes.func.isRequired,
    isLeaf: PropTypes.bool.isRequired,

};

const useStyles = makeStyles((theme) => ({
    h5: {
        margin: theme.spacing(2)
    }
}));

export function MyTreeViewPanel(props) {
    const componentIsMounted = useRef(true)
    const dispatch = useDispatch();
    const selectedSite = useSelector(selectSelectedSite)
    //const cachedTreeView = useSelector(selectCachedTreeView)
    const [expanded, setExpanded] = useState([]);
    const [openPanel, setOpenPanel] = useState(false);
    const [dataReady, setDataReady] = useState(false);
    const userCoordinates = useSelector(selectUserCoordinates)
    const userSiteLev = useSelector(selectUserLevel)
    const childSiteLevel = useSelector(selectChildSiteLevel)
    const userSite = useSelector(selectUserSite)
    const userSiteName = useSelector(selectUserSiteName)
    const userSitePath = useSelector(selectUserSitePath)
    const userSitePlanimetry = useSelector(selectPlanimetry)
    const userSiteType = useSelector(selectUserSiteType)
    const userSiteIsLeaf = useSelector(selectUserSiteIsLeaf)
    const triggerDataRefresh = useSelector(selectTriggerDataRefresh)
    const classes = useStyles();
    const site_label = userSitePath.split(".")[userSiteLev - 1]
    const siteToRemove = useSelector(selectSiteToRemove)
    const parentWasEmpty = useSelector(selectParentWasEmpty)
    const forceParentChangeType = useSelector(selectForceParentChangeType)

    useEffect(() => {
        dispatch(setIsLoading(true)) // Enable loading spinner
        async function stopper() {
            await initTree()
        }
        stopper()
        return () => {
            componentIsMounted.current = false
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(setIsLoading(true)) // Enable loading spinner
        updateStates(selectedSite)
    }, [selectedSite]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (forceParentChangeType && typeof forceParentChangeType === 'number') {
            updateStates(forceParentChangeType, 'change_type')
        }
    }, [forceParentChangeType]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function refresh() {
            if (typeof triggerDataRefresh === 'boolean' && triggerDataRefresh === true) {
                dispatch(setIsLoading(true)) // Enable loading spinner
                updateStates(selectedSite)
            }
            if (typeof triggerDataRefresh === 'number' && triggerDataRefresh > 0) { // Aggiornamento dalla websocket
                updateStates(triggerDataRefresh, true)
            }
        }
        refresh()
    }, [triggerDataRefresh]) // eslint-disable-line react-hooks/exhaustive-deps

    //create your forceUpdate hook
    function useForceUpdate() {
        const [_value, setValue] = useState(0); // eslint-disable-line no-unused-vars
        return () => setValue(_value => ++_value); // update the state to force render
    }

    async function getSelectedSiteObjectFromNodeId(_data, _selectedId) {
        for (let el in _data) {
            if (_data[el].nodeId === _selectedId) return _data[el]
            if (countProperties(_data[el].children) > 0) {
                let tmp = await getSelectedSiteObjectFromNodeId(_data[el].children, _selectedId)
                if (tmp !== undefined) return tmp
            }
        }
        return undefined
    }

    async function getNavbarArrayFromPath(_path) {
        let dt = cachedTree
        let out = []
        const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g
        const tempSitePath = userSiteName
            .trim()
            .replace(regex, ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join('')

        for (let el of _path.slice(_path.indexOf(tempSitePath))) {
            out.push({ name: dt.children[el].labelText, siteId: dt.children[el].nodeId })
            if (countProperties(dt.children[el]) > 0) {
                dt = dt.children[el]
            }
        }
        return out
    }

    /**
     * This function returns the collection of the next level childrens of the selected site
     *
     * @param {*} _selectedSite the Selected Site link object got from "getSelectedSiteObjectFromNodeId()"
     * @return {*} the POI collection to pass to the MyMap Component
     * 
     * POI COLLECTION STRUCTURE:
     * markers: [
     *    { key: 'marker1', position: [51.5, -0.1], content: 'My first popup' },
     *    { key: 'marker2', position: [51.51, -0.1], content: 'My second popup' },
     *    { key: 'marker3', position: [51.49, -0.05], content: 'My third popup' },
     * ]
     * @type {*}
     */
    async function getPoiCollectionFromSelectedSite(_selectedSite) {
        let _poiCollection = []
        for (let site in _selectedSite.children) {
            _poiCollection.push({
                key: _selectedSite.children[site].labelText,
                position: [_selectedSite.children[site].lat, _selectedSite.children[site].lon],
                content: {
                    name: _selectedSite.children[site].name,
                    alerts: _selectedSite.children[site].alerts,
                    maxSeverity: _selectedSite.children[site].maxSeverity,
                    isLeaf: _selectedSite.children[site].isLeaf,
                    siteId: _selectedSite.children[site].nodeId
                }
            })
        }
        return _poiCollection
    }

    async function getPlanimetryDataFromSelectedSite(_selectedSite) {
        let check = true
        for (let el in _selectedSite) {
            if (_selectedSite[el] === null) {
                check = false
            }
        }
        if (check) {
            let planimetryData = {}
            planimetryData.file_name = _selectedSite.planimetry_file_name
            planimetryData.lat_tl = _selectedSite.lat_tl_planimetry
            planimetryData.lon_tl = _selectedSite.lon_tl_planimetry
            planimetryData.lat_tr = _selectedSite.lat_tr_planimetry
            planimetryData.lon_tr = _selectedSite.lon_tr_planimetry
            planimetryData.lat_bl = _selectedSite.lat_bl_planimetry
            planimetryData.lon_bl = _selectedSite.lon_bl_planimetry
            planimetryData.lat_br = _selectedSite.lat_br_planimetry
            planimetryData.lon_br = _selectedSite.lon_br_planimetry
            return planimetryData
        } else {
            return undefined
        }
    }

    async function updateStates(_selected, _refresh = false) {
        if (countProperties(cachedTree.children) > 0) {
            //console.log(selectedObject)
            if (typeof _refresh === 'string' && _refresh === 'change_type') {
                const selectedObject = await getSelectedSiteObjectFromNodeId(cachedTree.children, _selected)
                selectedObject.siteType = 'leaf_with_device'
                dispatch(setForceParentChangeType(false))
            } else if (_refresh) {
                const selectedObject = await getSelectedSiteObjectFromNodeId(cachedTree.children, _selected)

                // Aggiorno le analtycs del padre
                let dataFromDb = await GetSiteAnalytics(_selected)
                dispatch(setUserAlerts(dataFromDb.analytics.numberCurrentAlerts))
                dispatch(setUserMaxSeverity(dataFromDb.analytics.maxSeverity))
                selectedObject.alerts = dataFromDb.analytics.numberCurrentAlerts
                selectedObject.bgColor = severity_colors[dataFromDb.analytics.maxSeverity].bgColor
                selectedObject.color = severity_colors[dataFromDb.analytics.maxSeverity].color
                selectedObject.alerts = dataFromDb.analytics.numberCurrentAlerts
                selectedObject.labelIcon = severity_colors[dataFromDb.analytics.maxSeverity].icon
                selectedObject.labelInfo = "Alerts: " + dataFromDb.analytics.numberCurrentAlerts
                selectedObject.maxSeverity = dataFromDb.analytics.maxSeverity
                if (_selected === userSite) {
                    selectedObject.siteType = userSiteType
                    selectedObject.isLeaf = userSiteIsLeaf
                }

                // Aggiorno i figli
                await getData(Number(selectedSite), Number(selectedObject.level), selectedObject)
                if (!selectedObject.isLeaf && Number(selectedSite) === Number(_selected)) {
                    const _poiCollection = await getPoiCollectionFromSelectedSite(selectedObject)
                    dispatch(setPoiCollection(_poiCollection))
                    let _maxSeverity = selectedObject.maxSeverity
                    let _alertCount = selectedObject.alerts
                    dispatch(setMaxSeverity(_maxSeverity))
                    dispatch(setAlertCount(_alertCount))
                } else if (selectedObject.isLeaf && Number(selectedSite) === Number(_selected)) {
                    let _maxSeverity = selectedObject.maxSeverity
                    let _alertCount = selectedObject.alerts
                    dispatch(setMaxSeverity(_maxSeverity))
                    dispatch(setAlertCount(_alertCount))
                }
            } else {
                const selectedObject = await getSelectedSiteObjectFromNodeId(cachedTree.children, _selected)
                await getData(Number(_selected), Number(selectedObject.level), selectedObject)
                if ((!parentWasEmpty && selectedObject.siteType !== 'not_leaf') || (selectedObject.planimetry_file_name && selectedObject.siteType !== 'not_leaf')) {
                    dispatch(setIsLeaf(true))
                    dispatch(setSiteType(selectedObject.siteType))
                    //show planimetry
                    const _planimetryData = await getPlanimetryDataFromSelectedSite(selectedObject)
                    //dispatch(setPoiCollection([]))
                    //dispatch(setMapCenter([selectedObject.lat, selectedObject.lon]))
                    dispatch(setPlanimetry(_planimetryData))
                } else {
                    dispatch(setIsLeaf(false))
                    dispatch(setSiteType(selectedObject.siteType))
                    let exp = expanded
                    if (exp.indexOf(_selected.toString()) === -1) {
                        exp.push(_selected.toString());
                    } else {
                        exp.pop(_selected.toString());
                    }
                    const _poiCollection = await getPoiCollectionFromSelectedSite(selectedObject)
                    dispatch(setPlanimetry(undefined))
                    setTimeout(() => dispatch(setPoiCollection(_poiCollection)), 250)
                }
                dispatch(setMaxZoom(22))
                let _maxSeverity = selectedObject.maxSeverity
                let _alertCount = selectedObject.alerts
                dispatch(setMaxSeverity(_maxSeverity))
                dispatch(setAlertCount(_alertCount))
                dispatch(setSelectedSiteName(selectedObject.name))
                dispatch(setSelectedSiteCoordinates({ lat: selectedObject.lat, lon: selectedObject.lon }))
                let navArr = selectedObject.sitePath.split('.')
                let navbarWithFunctions = await getNavbarArrayFromPath(navArr)
                if (selectedObject.sitePath) dispatch(setNavbarSelectionPath(navbarWithFunctions))
                if (countProperties(selectedObject.children) === 0) dispatch(setParentWasEmpty(true))
                else dispatch(setParentWasEmpty(false))
            }
            dispatch(setTriggerDataRefresh(false))
            dispatch(setIsLoading(false)) // Disable loading spinner
        }
    }

    const forceUpdate = useForceUpdate();
    //let cachedTree = []

    /** @type {*} The collection of the tree item colors and icons related to the alarm severity got from DB */
    const severity_colors = {
        "none": {
            color: "#3c8039",
            bgColor: "#e6f4ea",
            icon: ThumbUpIcon
        },
        "warning": {
            color: grey[800],
            bgColor: "#fcffcc",
            icon: NotificationImportantIcon
        },
        "alarm": {
            color: grey[800],
            bgColor: "#ffa756",
            icon: WarningIcon
        },
        "critical": {
            color: grey[800],
            bgColor: "#fce3e3",
            icon: ErrorIcon
        }
    }

    /**
     * Function to easily get the length of an object
     *
     * @param {*} obj
     * @return {*} 
     */
    function countProperties(obj) {
        var count = 0;

        for (var prop in obj) {
            if (obj.hasOwnProperty(prop))
                ++count;
        }

        return count;
    }

    /**
     * This is the most important funtion of the treeView: use it to add data dynamically from the DB api
     * It works like Lazy Loading of contents.
     *
     * @param {*} _siteId The id of the Site that you want to expand
     * @param {*} _siteLev the level of the site that you want to expand
     * @param {*} [_self=cachedTree] the dataset to update. by default it's the original cachedTree data 
     * but in case of recursive function call it points to the children object of the cachedTree.
     */
    async function getData(_siteId, _siteLev, _self = cachedTree.children[site_label]) {
        //console.log(Number(_siteId), Number(_siteLev), _self)
        if (componentIsMounted.current === true) {
            let treeViewData = await GetTreeView(Number(_siteId), Number(_siteLev))
            if (siteToRemove) { // Se c'è un sito da rimuovere lo rimuovo
                for (let ch in _self.children) {
                    if (_self.children[ch].nodeId === Number(siteToRemove)) {
                        delete _self.children[ch]
                        dispatch(setSiteToRemove(false))
                    }
                }
            }
            treeViewData.tree_view.forEach(async function (e) {
                let arrSitePath = e.sitePath.split('.')
                for (let ch in _self.children) {
                    if (_self.children[ch].nodeId === Number(e.siteId)) // se il sito è stato modificato elimino il vecchio nome
                        delete _self.children[ch]
                }
                if (!(arrSitePath[_siteLev] in _self.children)) {
                    _self.children[arrSitePath[_siteLev]] = {
                        nodeId: Number(e.siteId),
                        level: e.level,
                        name: e.name,
                        children: [],
                        childSiteLevel: e.childSiteLevel,
                        sitePath: e.sitePath,
                        labelText: arrSitePath[arrSitePath.length - 1],
                        labelInfo: "Alerts: " + e.numberCurrentAlerts,
                        labelIcon: severity_colors[e.maxSeverity].icon,
                        color: severity_colors[e.maxSeverity].color,
                        bgColor: severity_colors[e.maxSeverity].bgColor,
                        isLeaf: e.isLeaf,
                        siteType: e.siteType,
                        lat: e.lat,
                        lon: e.lon,
                        alerts: e.numberCurrentAlerts,
                        maxSeverity: e.maxSeverity,
                        planimetry_file_name: e.planimetry_file_name,
                        lat_tl_planimetry: e.lat_tl_planimetry,
                        lon_tl_planimetry: e.lon_tl_planimetry,
                        lat_tr_planimetry: e.lat_tr_planimetry,
                        lon_tr_planimetry: e.lon_tr_planimetry,
                        lat_bl_planimetry: e.lat_bl_planimetry,
                        lon_bl_planimetry: e.lon_bl_planimetry,
                        lat_br_planimetry: e.lat_br_planimetry,
                        lon_br_planimetry: e.lon_br_planimetry,
                    }
                    // The callback executed when user expands or collapse a part of the tree
                    _self.children[arrSitePath[_siteLev]].expandFunc = async function () {
                        if (componentIsMounted.current === true) {
                            //console.log("EXPAND FUNC")
                            if (!e.isLeaf) {
                                let nested = _self.children[arrSitePath[_siteLev]]
                                await getData(Number(e.siteId), Number(_siteLev) + 1, nested)

                                let exp = expanded
                                if (exp.indexOf(e.siteId.toString()) === -1) {
                                    exp.push(e.siteId.toString());
                                } else {
                                    exp.pop(e.siteId.toString());
                                }
                                //dispatch(setExpanded(exp))
                                forceUpdate()
                            }
                        } else
                            console.log("Component is not mounted")
                    }
                    // The callback executed when user makes a selection in the tree
                    _self.children[arrSitePath[_siteLev]].selectFunc = async function () {
                        if (componentIsMounted.current === true) {
                            dispatch(setSelectedSite(Number(e.siteId)))
                        } else
                            console.log("Component is not mounted")
                    }
                } else { // Mette solo i dati aggiornati 
                    _self.children[arrSitePath[_siteLev]].labelText = arrSitePath[arrSitePath.length - 1]
                    _self.children[arrSitePath[_siteLev]].labelInfo = "Alerts: " + e.numberCurrentAlerts
                    _self.children[arrSitePath[_siteLev]].labelIcon = severity_colors[e.maxSeverity].icon
                    _self.children[arrSitePath[_siteLev]].color = severity_colors[e.maxSeverity].color
                    _self.children[arrSitePath[_siteLev]].bgColor = severity_colors[e.maxSeverity].bgColor
                    _self.children[arrSitePath[_siteLev]].isLeaf = e.isLeaf
                    _self.children[arrSitePath[_siteLev]].lat = e.lat
                    _self.children[arrSitePath[_siteLev]].lon = e.lon
                    _self.children[arrSitePath[_siteLev]].alerts = e.numberCurrentAlerts
                    _self.children[arrSitePath[_siteLev]].maxSeverity = e.maxSeverity
                }
            })
        }
        dispatch(setIsLoading(false)) // Disable loading spinner
    }
    /**
     * This function initialize the cachedData content with the parent data
     *
     */
    async function initTree() {
        // console.log(childSiteLevel, "is the cild site level")
        let dataFromDb = await GetSiteAnalytics(userSite)
        dispatch(setUserAlerts(dataFromDb.analytics.numberCurrentAlerts))
        dispatch(setUserMaxSeverity(dataFromDb.analytics.maxSeverity))
        defaultTree.children[site_label] = {
            children: [],
            level: userSiteLev,
            childSiteLevel: childSiteLevel,
            alerts: dataFromDb.analytics.numberCurrentAlerts,
            bgColor: severity_colors[dataFromDb.analytics.maxSeverity].bgColor,
            color: severity_colors[dataFromDb.analytics.maxSeverity].color,
            expandFunc: () => {
                //console.log("EXPAND FUNC")
                let exp = expanded
                if (exp.indexOf(userSite.toString()) === -1) {
                    exp.push(userSite.toString());
                } else {
                    exp.pop(userSite.toString());
                }
                setExpanded(exp)
                forceUpdate()
            },
            isLeaf: userSiteIsLeaf,
            siteType: userSiteType,
            labelIcon: severity_colors[dataFromDb.analytics.maxSeverity].icon,
            labelInfo: "Alerts: " + dataFromDb.analytics.numberCurrentAlerts,
            labelText: userSiteName,
            lat: userCoordinates.lat,
            lat_bl_planimetry: userSitePlanimetry.lat_bl,
            lat_br_planimetry: userSitePlanimetry.lat_br,
            lat_tl_planimetry: userSitePlanimetry.lat_tl,
            lat_tr_planimetry: userSitePlanimetry.lat_tr,
            lon: userCoordinates.lon,
            lon_bl_planimetry: userSitePlanimetry.lon_bl,
            lon_br_planimetry: userSitePlanimetry.lon_br,
            lon_tl_planimetry: userSitePlanimetry.lon_tl,
            lon_tr_planimetry: userSitePlanimetry.lon_tr,
            maxSeverity: dataFromDb.analytics.maxSeverity,
            name: userSiteName,
            nodeId: Number(userSite),
            planimetry_file_name: userSitePlanimetry.file_name,
            selectFunc: () => {
                dispatch(setSelectedSite(Number(userSite)))
            },
            sitePath: userSitePath,
        }
        //await getData(userSite, userSiteLev)
        setDataReady(true)
        dispatch(setSelectedSite(Number(userSite)))
    }

    /**
     * Funtion to map an object:
     * returns an array with the lambda function f() related to every obj elements
     *
     * @param {*} obj
     * @param {*} f
     * @return {*} 
     */
    function map_obj(obj, f) {
        let ret = []
        for (let el in obj) {
            ret.push(f(obj[el]))
        }
        return ret
    }

    /**
     * This function calls the map_obj function to create a React elemets Array:
     * Every array element is a <StyledTreeItem /> component with the parameters
     * of the treeView Data. 
     * If a child is found it calls itself recursively.
     *
     * @param {*} treeItems
     * @return {*} 
     */
    const getTreeItemsFromData = treeItems => {
        return map_obj(treeItems.children, treeItemData => {
            let children = undefined;
            if (treeItemData.children && countProperties(treeItemData.children) > 0) {
                children = getTreeItemsFromData(treeItemData);
            }
            return (
                <StyledTreeItem
                    key={treeItemData.nodeId}
                    nodeId={treeItemData.nodeId.toString()}
                    labelText={treeItemData.labelText}
                    labelInfo={treeItemData.labelInfo}
                    labelIcon={treeItemData.labelIcon}
                    color={treeItemData.color}
                    bgColor={treeItemData.bgColor}
                    expandFunc={treeItemData.expandFunc}
                    selectFunc={treeItemData.selectFunc}
                    children={children}
                    isLeaf={treeItemData.isLeaf}
                />
            );
        });
    }

    /**
     * This is the Main TreeView component.
     * It uses the getTreeItemsFromData(treeItems) to generate his children.
     * The only input is the cachedTree collection (treeItems)
     *
     * @param {*} { treeItems }
     * @return {*} 
     */
    const DataTreeView = ({ treeItems }) => {
        return (
            <TreeView
                className={classes.root}
                /*defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}*/
                defaultEndIcon={<div style={{ width: 24 }} />}
                expanded={expanded}
                selected={selectedSite.toString()}
                multiSelect={false}
            >
                {getTreeItemsFromData(treeItems)}
            </TreeView>
        );
    };

    return (
        <>
            <Fab color="primary" aria-label="add" disabled={dataReady ? false : true} className={"btnOpenTreeView"} onClick={() => setOpenPanel(!openPanel)}>
                <AccountTreeIcon />
            </Fab>
            <SwipeableDrawer
                onBackdropClick={() => setOpenPanel(false)}
                anchor={'left'}
                swipeAreaWidth={16}
                open={openPanel}
                onOpen={() => setOpenPanel(true)}
                onClose={() => setOpenPanel(false)}
                className={'sliding-panel-container'}
            >
                <Typography variant="h5" className={classes.h5}>Select a location</Typography>
                <DialogContent dividers>
                    <DataTreeView treeItems={cachedTree} />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        //className={classes.button}
                        startIcon={<CloseIcon />}
                        onClick={() => setOpenPanel(false)}
                    >
                        Close
                    </Button>
                </DialogActions>
            </SwipeableDrawer>
        </>
    );
}