import * as React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CellParams } from '@material-ui/x-grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      fontVariantNumeric: 'tabular-nums',
    }
  }),
);

interface NormalNumberProps {
  value: number;
}

const NormalNumber = React.memo(function Pnl(props: NormalNumberProps) {
  const { value } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root)}
    >
      {value}
    </div>
  );
});

export function renderNormalNumber(params: CellParams) {
  return <NormalNumber value={params.value as any} />;
}
