import * as React from 'react';
import clsx from 'clsx';
import { CellParams } from '@material-ui/x-grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import SignalCellular0BarIcon from '@material-ui/icons/SignalCellular0Bar';
import SignalCellular1BarIcon from '@material-ui/icons/SignalCellular1Bar';
import SignalCellular2BarIcon from '@material-ui/icons/SignalCellular2Bar';
import SignalCellular3BarIcon from '@material-ui/icons/SignalCellular3Bar';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
    },
    value: {
      lineHeight: '35px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    widget: {
      fontSize: '60px',
      lineHeight: '50px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '&.low': {
        color: '#EA4228',
      },
      '&.medium': {
        color: '#F5CD19',
      },
      '&.high': {
        color: '#5BE12C',
      },
    },
    label: {
      fontSize: '35px',
      justifyContent: 'center',
      textAlign: 'center'
    }
  }),
);

function map(x: number, in_min: number, in_max: number, out_min: number, out_max: number) {
  return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

interface ProgressBarProps {
  value: number;
  widget: boolean;
}

const ProgressBar = React.memo(function ProgressBar(props: ProgressBarProps) {
  const { value, widget } = props;
  const max = 31
  const min = 0
  const valueInPercent = Number(map(value, min, max, 0, 100).toFixed(0))
  const classes = useStyles();

  function getIcon(val: number) {
    if (val < 6.2)
      return <SignalCellular0BarIcon fontSize={widget ? 'inherit' : 'small'} />;
    if (val < 12.4)
      return <SignalCellular1BarIcon fontSize={widget ? 'inherit' : 'small'} />;
    if (val < 18.6)
      return <SignalCellular2BarIcon fontSize={widget ? 'inherit' : 'small'} />;
    if (val < 24.8)
      return <SignalCellular3BarIcon fontSize={widget ? 'inherit' : 'small'} />;
    if (val < 32)
      return <SignalCellular4BarIcon fontSize={widget ? 'inherit' : 'small'} />;
    return null;
  }

  if (widget) {
    return (
      <div className={classes.root}>
        <div className={clsx(classes.widget, {
          low: valueInPercent < 20,
          medium: valueInPercent >= 20 && valueInPercent <= 60,
          high: valueInPercent > 60,
        })}><span className={classes.label}>{`${valueInPercent.toLocaleString()} %`}</span><span>{getIcon(value)}</span></div>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.value}>{`${valueInPercent.toLocaleString()} %`}<span>{getIcon(value)}</span></div>
    </div>
  );
});

export function renderUMTS(params: CellParams) {
  return <ProgressBar value={Number(params.value)!} widget={false} />;
}

export function RenderUMTS(params: CellParams) {
  return <ProgressBar value={Number(params.value)!} widget={true} />;
}