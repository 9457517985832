import * as React from 'react';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';
import { CellParams } from '@material-ui/x-grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import getRenderPropsFromDeviceName from './deviceTypeRenderUtility'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'left',
      '& .icon': {
        color: 'inherit',
      },
    },
    blank: {
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.primary}`,
    },
    info: {
      color: theme.palette.info.dark,
      border: `1px solid ${theme.palette.info.main}`,
    },
    warning: {
      color: theme.palette.warning.dark,
      border: `1px solid ${theme.palette.warning.main}`,
    },
    error: {
      color: theme.palette.error.dark,
      border: `1px solid ${theme.palette.error.main}`,
    },
    success: {
      color: theme.palette.success.dark,
      border: `1px solid ${theme.palette.success.main}`,
    }
  }),
);

interface DeviceTypeProps {
  deviceType: string;
};

const DeviceType = React.memo((props: DeviceTypeProps) => {
  const { deviceType } = props;
  let icon: any = null;
  let label = deviceType;
  const classes: any = useStyles();
  let colorClass = classes.blank

  let renderProps = getRenderPropsFromDeviceName(deviceType)
  label = renderProps.label
  icon = renderProps.icon
  
  return (
    <Chip
      className={clsx(classes.root, colorClass)}
      icon={icon}
      size="small"
      label={label}
      variant="outlined"
    />
  );
});

export function renderDeviceTypeById(params: CellParams) {
  return <DeviceType deviceType={params.value!.toString()} />;
}
