import ReactDOM from 'react-dom'
import React, { useState } from "react"
import store from '../redux/store';
import { Provider } from 'react-redux';
import Dashboard from './Dashboard'
import Device from './Device'
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormGroup, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { TryLogin, StartSocketConnection } from "../BkConnect"
import { SnackbarProvider } from 'notistack';
import posed from "react-pose";
import "./Login.css";
import "../App.css";

const ShakePose = posed.div({
  shake: {
    applyAtEnd: { x: 0 },
    applyAtStart: { x: -10 },
    x: 0,
    transition: {
      type: "spring",
      stiffness: 1000,
      damping: 10,
      duration: 4
    }
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "url('../images/EL_03043_WM_WM.jpg')",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  button: {
    width: '95%'
  }
}));

export default function Login(props) {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const classes = useStyles();
  const palletType = "dark"
  const [guesses, setGuesses] = useState(3);

  const darkTheme = createMuiTheme({
    palette: {
      type: palletType,
    }
  });

  function validateForm() {
    return username.length > 0 && password.length > 0
  }

  const handleChange = (prop) => (event) => {
    switch (prop) {
      case 'password':
        setPassword(event.target.value)
        break
      case 'username':
        setUsername(event.target.value)
        break
      default:
        break
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  };

  async function handleSubmit(event) {
    event.preventDefault();
    let response = await TryLogin(username.toLowerCase(), password)
    if (response.accepted === true) {
      if (username !== '' && response.user_data.site_id !== '') StartSocketConnection(username.toLowerCase(), response.user_data.site_id, response.user_data.session_key)
      let coords = { lat: response.user_data.lat, lon: response.user_data.lon }
      let planimetry = {
        file_name: response.user_data.planimetry_file_name,
        lat_bl: response.user_data.lat_bl_planimetry,
        lat_br: response.user_data.lat_br_planimetry,
        lat_tl: response.user_data.lat_tl_planimetry,
        lat_tr: response.user_data.lat_tr_planimetry,
        lon_bl: response.user_data.lon_bl_planimetry,
        lon_br: response.user_data.lon_br_planimetry,
        lon_tl: response.user_data.lon_tl_planimetry,
        lon_tr: response.user_data.lon_tr_planimetry
      }
      localStorage.setItem("session_key", response.user_data.session_key)
      localStorage.setItem("isUserAutenticated", true)
      localStorage.setItem("username", username.toLowerCase())
      localStorage.setItem("email", response.user_data.email)

      localStorage.setItem("permissions", JSON.stringify({
        users_management: response.user_data.users_management,
        sites_management: response.user_data.sites_management,
        devices_management: response.user_data.devices_management,
        notifications_management: response.user_data.notifications_management
      }))

      //console.log(response)
      localStorage.setItem("sitesCollection", response.user_data.site_id)
      localStorage.setItem("siteName", response.user_data.name)
      localStorage.setItem("sitePath", response.user_data.path)
      localStorage.setItem("siteLevel", response.user_data.level)
      localStorage.setItem("childSiteLevel", response.user_data.childSiteLevel)
      localStorage.setItem("siteType", response.user_data.siteType)
      localStorage.setItem("siteIsLeaf", JSON.stringify(response.user_data.isLeaf))
      localStorage.setItem("siteCoordinates", JSON.stringify(coords))
      localStorage.setItem("sitePlanimetry", JSON.stringify(planimetry))
      switch (props.page) {
        case "/":
          ReactDOM.render(
            <Provider store={store}>
              <SnackbarProvider
                preventDuplicate
                iconVariant={{
                  success: '✅',
                  error: '✖️',
                  warning: '⚠️',
                  info: 'ℹ️',
                }}
              >
                <Dashboard
                  username={username}
                  email={response.user_data.email}
                  permissions={{
                    users_management: response.user_data.users_management,
                    sites_management: response.user_data.sites_management,
                    devices_management: response.user_data.devices_management,
                    notifications_management: response.user_data.notifications_management
                  }}
                  siteId={Number(response.user_data.site_id)}
                  siteName={response.user_data.name}
                  sitePath={response.user_data.path}
                  siteLevel={response.user_data.level}
                  childSiteLevel={response.user_data.childSiteLevel}
                  siteIsLeaf={response.user_data.isLeaf}
                  siteType={response.user_data.siteType}
                  siteCoordinates={coords}
                  sitePlanimetry={planimetry}
                />
              </SnackbarProvider>
            </Provider>
            , document.getElementById('root'));
          break;
        case '/device':
          ReactDOM.render(
            <Provider store={store}>
              <SnackbarProvider
                preventDuplicate
                iconVariant={{
                  success: '✅',
                  error: '✖️',
                  warning: '⚠️',
                  info: 'ℹ️',
                }}
              >
                <Device
                  username={username}
                  siteId={Number(response.user_data.site_id)}
                  sitePath={response.user_data.path}
                  email={response.user_data.email}
                  permissions={{
                    users_management: response.user_data.users_management,
                    sites_management: response.user_data.sites_management,
                    devices_management: response.user_data.devices_management,
                    notifications_management: response.user_data.notifications_management
                  }}
                />
              </SnackbarProvider>
            </Provider>
            , document.getElementById('root'));
          break;
        default:
          break;
      }

    } else {
      ReactDOM.render(<><span style={{ color: '#cc0000' }}>Errore:</span> Username o password non validi.</>, document.getElementById('error'));
      setGuesses(guesses - 1)
    }
  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <div className={classes.root}>
        <Dialog aria-labelledby="customized-dialog-title" open={true} hideBackdrop={true}>
          <Typography className="title" variant="h5">Safety Device Manager</Typography>
          <DialogContent dividers>
            <ShakePose pose={["shake"]} id="shake" poseKey={guesses}>
              <FormGroup onKeyDown={_handleKeyDown}>
                <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                  <InputLabel htmlFor="outlined-start-adornment">Username</InputLabel>
                  <OutlinedInput
                    id="outlined-start-adornment"
                    type="text"
                    value={username}
                    tabIndex={1}
                    onChange={handleChange('username')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex={5}
                          edge="end"
                        >
                          <AccountCircle />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={72}
                  />
                </FormControl>
                <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    tabIndex={2}
                    onChange={handleChange('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          tabIndex={3}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </FormGroup>
            </ShakePose>
            <div id="error"></div>
            <p className="copytext text-center text-muted">&copy; 2020 &middot; T3LAB v{process.env.REACT_APP_VERSION}</p>
          </DialogContent>
          <DialogActions>
            <Button tabIndex={4} type="submit" className={clsx(classes.margin, classes.button)} size="large" variant="contained" color="primary" onClick={handleSubmit} disabled={!validateForm()}>Login</Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
}