import { GetStringifiedNFCSStruct } from "../BkConnect";

class NFC {
    constructor() {
        this.nfcAvailable = 'NDEFReader' in window;

        if (!this.nfcAvailable) {
            console.info('NFC non disponibile.');
            return;
        }
        try {
            this.writer = new window.NDEFReader();
        } catch (error) {
            console.error('Errore durante l\'inizializzazione del modulo NFC:', error);
        }
    }

    /**
     * 
     * @returns 
     */
    async stringifyNFCSStruct(sn) {
        return new Promise((resolve, reject) => {
            try {
                // call the API to get the configuration of the device using "get_serialized_configuration"
                GetStringifiedNFCSStruct(sn)
                    .then(cfg => {
                        resolve(cfg);
                    })
                    .catch(error => {
                        console.error('Errore durante il recupero della configurazione del dispositivo:', error);
                        reject(error);
                    });
            } catch (error) {
                console.error('Errore durante la conversione della configurazione in stringa:', error);
                reject(error);
            }
        });
    }

    async scanTags() {
        return new Promise(async (resolve, reject) => {
            try {
                const ctlr = new window.AbortController();
                ctlr.signal.onabort = () => reject("Time is up, bailing out!");
                setTimeout(() => ctlr.abort(), 10000);

                this.writer.addEventListener("readingerror", () => {
                    console.log("Argh! Cannot read data from the NFC tag. Try another one?");
                    reject('Errore durante la lettura del tag NFC.');
                });

                this.writer.addEventListener("reading", async ({ message, serialNumber }) => {
                    console.log(`> Serial Number: ${serialNumber}`);
                    console.log(`> Records: (${message.records.length})`);
                    resolve({ message, serialNumber, ctlr });
                }, { once: true });

                await this.writer.scan();
                console.log('Scansione NFC avviata.');

            } catch (error) {
                console.error('Errore durante la scrittura della configurazione sul tag NFC:', error);
                reject(error);
            }
        });
    }

    async writeConfigToTag(sn) {
        return new Promise(async (resolve, reject) => {
            this.scanTags().then(async ({ message, serialNumber, ctlr }) => {
                const cfg = await this.stringifyNFCSStruct(sn) // Buffer
                console.log(`> Config to write: ${cfg}`);
                const cfgArrayBuffer = new Uint8Array(cfg.data).buffer;
                // for testing reasons fill all the cfg with 0xDD
                // const cfgArrayBuffer = new Uint8Array(64).fill(0xDD).buffer;
                this.writer
                    .write({
                        records: [
                            { recordType: "url", data: `https://iot.eye-core.it/device/${sn}` },
                            { recordType: "text", data: `CFG:` },
                            { recordType: "unknown", data: cfgArrayBuffer },
                        ],
                    }, { signal: ctlr.signal })
                    .then(() => {
                        console.log('Configurazione scritta sul tag NFC.');
                        resolve({ message, serialNumber });
                    })
                    .catch((error) => {
                        console.error('Errore durante la scrittura della configurazione sul tag NFC:', error);
                        reject(error);
                    });
            }).catch(error => {
                console.error('Errore durante la scrittura della configurazione sul tag NFC:', error);
                reject(error);
            });
        });
    }
}

export default NFC;
