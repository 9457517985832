import React, { useState, useEffect } from "react";
import { GetLogs, GetUserProfilePic, Logout, UpdateUserProfile } from "../BkConnect";
import { useSelector, useDispatch } from 'react-redux';
import {
    selectUserAlerts,
    selectUserSite,
    selectNavbarSelectionPath,
    selectUserProfilePic,
    setSelectedSite,
    setUserProfilePic,
    setAlertPanelOpen,
    selectUsername,
    selectUserSettingsDialogOpen,
    setUserSettingsDialogOpen,
    setIsLoading,
    selectEmail,
    setEmail,
    selectPermissions,
} from '../redux/reducers/dashboardSlice';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import WarningIcon from '@material-ui/icons/Warning';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SpeakerPhoneIcon from '@material-ui/icons/SpeakerPhone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import "../Components/MyNavBar.css"
import { Badge, TextField, FormControlLabel, Checkbox, Switch, Grid } from '@material-ui/core';
import { useSnackbar } from "notistack";
import { DropzoneDialog } from "material-ui-dropzone";
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { capitalize, startCase } from "lodash"
import BugReportIcon from '@material-ui/icons/BugReport';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    userForm: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(2),
        },
        buttons: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        checkbox: {
            margin: theme.spacing(2)
        },
    },
    '& .boxPermissions': {
        marginTop: theme.spacing(2),
    },
}));

function PaperComponent(props) {
    return (
        <Draggable handle="#responsive-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const error = {
    txtEmail: false,
    txtOldPassword: false,
    txtNewPassword1: false,
    txtNewPassword2: false,
}

export default function AccountSettingsDialog() {
    const open = useSelector(selectUserSettingsDialogOpen)
    const [openUploader, setOpenUploader] = React.useState(false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch()
    const username = useSelector(selectUsername)
    const userEmail = useSelector(selectEmail)
    const userProfilePic = useSelector(selectUserProfilePic)
    const userPermissions = useSelector(selectPermissions)
    const [newUserProfilePic, setNewUserProfilePic] = React.useState("")
    const [userProfilePicChanged, setUserProfilePicChanged] = React.useState("")
    const [localEmail, setLocalEmail] = React.useState("")
    const [oldPassword, setOldPassword] = React.useState("")
    const [newPassword1, setNewPassword1] = React.useState("")
    const [newPassword2, setNewPassword2] = React.useState("")
    const [changePassword, setChangePassword] = React.useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
        return re.test(String(email).toLowerCase());
    }

    const updateForm = (e) => {
        switch (e.target.id) {
            case 'txtEmail':
                setLocalEmail(e.target.value)
                if (validateEmail(e.target.value)) {
                    error[e.target.id] = false
                }
                else {
                    error[e.target.id] = true
                }
                break
            case 'txtOldPassword':
                setOldPassword(e.target.value)
                if (e.target.value.length > 3) {
                    error[e.target.id] = false
                }
                else {
                    error[e.target.id] = true
                }
                break
            case 'txtNewPassword1':
                setNewPassword1(e.target.value)
                if (e.target.value.length > 3 && e.target.value === newPassword2) {
                    error[e.target.id] = false
                }
                else {
                    error[e.target.id] = true
                }
                break
            case 'txtNewPassword2':
                setNewPassword2(e.target.value)
                if (e.target.value.length > 3 && e.target.value === newPassword1) {
                    error[e.target.id] = false
                    error['txtNewPassword1'] = false
                }
                else {
                    error[e.target.id] = true
                }
                break
            default:
                break
        }
    }

    const handleClose = async (action) => {
        switch (action) {
            case 'cancel':
                break
            case 'submit':
                if (localEmail.length === 0 || (changePassword && newPassword2.length === 0) || oldPassword.length === 0) {
                    enqueueSnackbar('You have to fill the form as well!', { variant: 'error' });
                    return
                }
                for (let er in error) {
                    if (error[er]) {
                        enqueueSnackbar('You have to fill the form as well!', { variant: 'error' });
                        return
                    }
                }
                dispatch(setIsLoading(true))
                const res = await UpdateUserProfile(username, localEmail, changePassword ? newPassword2 : undefined, oldPassword, userProfilePicChanged ? newUserProfilePic : undefined)
                if (res.accepted) {
                    if (userProfilePicChanged) {
                        dispatch(setUserProfilePic(newUserProfilePic))
                    }
                    dispatch(setEmail(localEmail))
                    localStorage.setItem("email", localEmail)
                    enqueueSnackbar('Profile Updated!', { variant: 'success' });
                } else {
                    enqueueSnackbar('Error! Wrong Password Maybe...', { variant: 'error' });
                    error['txtOldPassword'] = true
                    dispatch(setIsLoading(false))
                    return
                }
                dispatch(setIsLoading(false))
                dispatch(setUserSettingsDialogOpen(false))
                break
            default:
                break
        }
        setUserProfilePicChanged(false)
        setNewUserProfilePic(userProfilePic)
        setLocalEmail("")
        setOldPassword("")
        setNewPassword1("")
        setNewPassword2("")
        dispatch(setUserSettingsDialogOpen(false))
    }

    useEffect(() => {
        setNewUserProfilePic(userProfilePic)
    }, [userProfilePic])

    useEffect(() => {
        setLocalEmail(userEmail)
    }, [userEmail])

    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        })
    }

    const addProfilePictureFile = async (files) => {
        if (files.length > 0) {
            try {
                let file = files[0];
                const _base64Planimetry = await readFileAsync(file)
                setNewUserProfilePic(_base64Planimetry)
                setUserProfilePicChanged(true)
                setOpenUploader(false)
            } catch (err) {
                console.log(err);
            }
        }
    }

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"xs"}
                fullScreen={fullScreen}
                PaperComponent={!fullScreen ? PaperComponent : undefined}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{`Account Settings For ${capitalize(username)}`}</DialogTitle>
                <DialogContent className={classes.userForm}>
                    <Avatar className="largeAvatar" alt={username} src={newUserProfilePic.indexOf('base64,') > -1 ? newUserProfilePic : `data:image/jpeg;base64,${newUserProfilePic}`} />
                    <Button tabIndex={0} className="btnUploadPicture" variant="outlined" fullWidth onClick={() => setOpenUploader(true)}>Change Profile Picture</Button>
                    <DropzoneDialog
                        open={openUploader}
                        onClose={() => setOpenUploader(false)}
                        filesLimit={1}
                        dropzoneText={"Drop here the profile picture [.JPG] if you want"}
                        onSave={addProfilePictureFile}
                        acceptedFiles={['image/jpeg']}
                        showPreviewsInDropzone={true}
                        showPreviews={false}
                        maxFileSize={2000000}
                    />
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <FormControlLabel
                                key="sw1_new"
                                control={<Switch checked={userPermissions.users_management} onChange={() => { }} id="swRole_users_management" />}
                                label="Edit Users"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                key="sw2_new"
                                control={<Switch checked={userPermissions.sites_management} onChange={() => { }} id="swRole_sites_management" />}
                                label="Edit Sites"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                key="sw3_new"
                                control={<Switch checked={userPermissions.devices_management} onChange={() => { }} id="swRole_devices_management" />}
                                label="Edit Devices"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                key="sw4_new"
                                control={<Switch checked={userPermissions.notifications_management} onChange={() => { }} id="swRole_notifications_management" />}
                                label="Receive Emails"
                                labelPlacement="start"
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        tabIndex={1}
                        autoFocus
                        margin="dense"
                        id="txtEmail"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={localEmail}
                        onChange={updateForm}
                        aria-errormessage="Incorrect Email Address"
                        error={error["txtEmail"]}
                    />
                    <TextField
                        tabIndex={2}
                        autoFocus
                        margin="dense"
                        id="txtOldPassword"
                        label="Old Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={oldPassword}
                        onChange={updateForm}
                        aria-errormessage="Insert the actual Password"
                        error={error["txtOldPassword"]}
                    />
                    <FormControlLabel
                        className={classes.userForm.checkbox}
                        control={<Checkbox icon={<VpnKeyOutlinedIcon />} checkedIcon={<VpnKeyIcon />} name="checked" onChange={(e) => setChangePassword(e.target.checked)} />}
                        label="Change Password"
                    />
                    <TextField
                        tabIndex={3}
                        autoFocus
                        margin="dense"
                        id="txtNewPassword1"
                        label="Type New Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={newPassword1}
                        onChange={updateForm}
                        aria-errormessage="Insert the new Password"
                        error={error["txtNewPassword1"]}
                        disabled={!changePassword}
                    />
                    <TextField
                        tabIndex={4}
                        autoFocus
                        margin="dense"
                        id="txtNewPassword2"
                        label="Type New Password Again"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={newPassword2}
                        onChange={updateForm}
                        aria-errormessage="New password should be the same"
                        error={error["txtNewPassword2"]}
                        disabled={!changePassword}
                    />
                </DialogContent>
                <DialogActions>
                    <Button tabIndex={4} className={classes.userForm.buttons} color="secondary" onClick={() => handleClose('submit')} variant="contained">Save User Profile</Button>
                    <Button tabIndex={5} autoFocus onClick={() => handleClose('cancel')} color="primary" variant="contained">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export function MyNavBar(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [small, setSmall] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch()
    const username = useSelector(selectUsername)
    const selectionPath = useSelector(selectNavbarSelectionPath)
    const userAlerts = useSelector(selectUserAlerts)
    const userSite = useSelector(selectUserSite)
    const userProfilePic = useSelector(selectUserProfilePic)
    const userPermissions = useSelector(selectPermissions)

    async function storeUserProfilePic(_username) {
        dispatch(setUserProfilePic(await GetUserProfilePic(_username)))
    }

    useEffect(() => {
        storeUserProfilePic(username)
        const mediaQuery = window.matchMedia('(min-width: 880px)');
        //console.log(mediaQuery)
        if (mediaQuery.matches) {
            setSmall(false)
        } else {
            setSmall(true)
        }
        mediaQuery.addEventListener("change", (mq) => {
            if (mq.matches) {
                setSmall(false)
            } else {
                setSmall(true)
            }
        });
    }, [username]) // eslint-disable-line react-hooks/exhaustive-deps

    const downloadLogs = () => {
        GetLogs().then((file) => {
            // download as txt file
            const response = {
                file: 'data:text/plain;charset=utf-8,' + encodeURIComponent(file),
            };
            const link = document.createElement('a');
            link.href = response.file;
            link.setAttribute('download', 'logs.txt');
            document.body.appendChild(link);
            link.click();
        })
    }

    const handleSelection = (id) => {
        dispatch(setSelectedSite(Number(id)))
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openAlerts = () => {
        dispatch(setSelectedSite(Number(userSite)))
        dispatch(setAlertPanelOpen(true))
    };

    const renderDashboard = () => {
        window.location.replace("/")
    };

    const renderDevice = () => {
        window.location.replace("/device")
    };

    let navbarSelectionPath = selectionPath.map(el => {
        if (selectionPath.indexOf(el) === selectionPath.length - 1) {
            return (
                <Typography key={el} color="textPrimary">{startCase(el.name)}</Typography>
            )
        } else {
            return (
                <Link key={el} color="inherit" href="#" onClick={() => handleSelection(el.siteId)}>{startCase(el.name)}</Link>
            )
        }
    })

    return (
        <div className={classes.root}>
            <AppBar id={props.id} position="relative" color="default">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} aria-label="menu">
                        <WhatshotIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>{!small ? props.active : ""}</Typography>
                    {props.active === 'Device' ? (<></>) : (
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} className="selectionPath" maxItems={small ? 2 : 5} aria-label="breadcrumb">
                            {navbarSelectionPath}
                        </Breadcrumbs>
                    )}
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        color="default"
                        size="large"
                        onClick={handleClick}
                    >
                        {!small ? <Typography variant="button" className="iconUser">Hello {username}</Typography> : ""}
                        <Badge badgeContent={userAlerts} color="error">
                            <AccountCircle />
                        </Badge>
                    </Button>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <StyledMenuItem>
                            <ListItemAvatar className="user-pic">
                                <Avatar className="avatar" alt={username} src={userProfilePic.indexOf('base64,') > -1 ? userProfilePic : `data:image/jpeg;base64,${userProfilePic}`} />
                            </ListItemAvatar>
                        </StyledMenuItem>

                        <StyledMenuItem onClick={props.active === 'Dashboard' ? renderDevice : renderDashboard}>
                            <ListItemIcon>
                                {props.active === 'Dashboard' ? <SpeakerPhoneIcon fontSize="small" /> : <DashboardIcon fontSize="small" />}
                            </ListItemIcon>
                            <ListItemText primary={props.active === 'Device' ? "Go to dashboard" : "Go to device page"} />
                        </StyledMenuItem>
                        {props.active === 'Dashboard' ? (
                            <StyledMenuItem onClick={openAlerts}>
                                <ListItemIcon>
                                    <WarningIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={(userAlerts > 0 ? `You have to check ${userAlerts} alerts` : "No active alerts found!")} />
                            </StyledMenuItem>
                        ) : (undefined)}
                        <StyledMenuItem onClick={e => dispatch(setUserSettingsDialogOpen(true))}>
                            <ListItemIcon>
                                <AccountCircle fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Account Settings" />
                        </StyledMenuItem>
                        {userPermissions.devices_management ? (
                            <StyledMenuItem onClick={downloadLogs}>
                                <ListItemIcon>
                                    <BugReportIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Download Logs" />
                            </StyledMenuItem>
                        ) : (undefined)}
                        <StyledMenuItem onClick={e => Logout(true)}>
                            <ListItemIcon>
                                <ExitToApp fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </StyledMenuItem>
                    </StyledMenu>
                </Toolbar>
            </AppBar>
            <AccountSettingsDialog></AccountSettingsDialog>
        </div>
    );
}