import * as React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CellParams } from '@material-ui/x-grid';
import { GetRealTimeStamp } from "../../utils/date_utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingRight: 8,
    },
  }),
);

interface DateTimeProps {
  value: Date;
}

const DateTime = React.memo(function DateTime(props: DateTimeProps) {
  const { value } = props;
  const formattedValue = value ? GetRealTimeStamp(value) : ""
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root)}
    >
      {formattedValue.toLocaleString()}
    </div>
  );
});

export function renderDateTime(params: CellParams) {
  return <DateTime value={params.value as any} />;
}
