import * as React from 'react';
import { CellParams } from '@material-ui/x-grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CountUp from 'react-countup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.divider}`,
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      height: 26,
      borderRadius: 2,
    },
    value: {
      //position: 'absolute',
      lineHeight: '24px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    bar: {
      height: '100%',
      '&.low': {
        backgroundColor: '#f44336',
      },
      '&.medium': {
        backgroundColor: '#efbb5aa3',
      },
      '&.high': {
        backgroundColor: '#088208a3',
      },
    },
  }),
);

function map(x: number, in_min: number, in_max: number, out_min: number, out_max: number) {
  return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

interface LabelValueProps {
  table: boolean;
  value: number;
}

interface RenderDistanceProps {
  value: number;
}

const LabelValue = React.memo(function Distance(props: LabelValueProps) {
  const { value, table } = props;
  /*const max = 32767
  const min = 0*/
  const formattedValue = value.toFixed(0)
  const classes = useStyles();

  let ret = table ?
    <div className={classes.value}>{`${formattedValue.toLocaleString()} mm`}</div>
    :
    <CountUp redraw preserveValue delay={0.5} duration={3} end={Number(formattedValue)} formattingFn={(n) => {return `${n} mm`}} /> 
  return ret
});

export function renderDistance(params: CellParams) {
  return <LabelValue value={Number(params.value)!} table={true} />;
}

export function RenderDistanceValue(props: RenderDistanceProps) {
  return <LabelValue value={Number(props.value)!} table={false} />;
}