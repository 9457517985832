import * as React from 'react';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import SmokeFreeIcon from '@material-ui/icons/SmokeFree';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import HelpIcon from '@material-ui/icons/Help';

export function getDeviceList() {
    return [
        'not_defined',
        'fire_extinguisher_prox_sensor',
        'fire_extinguisher_no_prox_sensor',
        'hydrant',
        'open_rei_door',
        'closed_rei_door',
        'open_closed_rei_door',
        'above_below_ground_hydrant',
        'rei_shutter',
        'fire_engine_connection',
        'smoke_evacuator',
        'filter_room_pressurizer',
        'emergency_lights',
        'smoke_detector',
        'fire_equipment_cabinet',
        'first_aid_kit',
        'fire_fighting_station',
        'sprinkler_bell',
        'emergency_exit'  
    ]
}

export default function getRenderPropsFromDeviceType(deviceType: string, severity: string | undefined = undefined) {
    let icon: any = null;
    let label = deviceType;
    let svgPathUrl = ""
    let colorFolder = "/green"
    let defaultValues = {
        server_ip_address: process.env.REACT_APP_BK_IPV4_ADDRESS_OLD,
        server_port: 3200,
        heartbeat_period: 1440,
        sample_period: 60
    }

    if (severity !== undefined) {
        switch (severity) {
            case "none":
                colorFolder = "/green"
                break
            case "warning":
                colorFolder = "/yellow"
                break
            case "alarm":
                colorFolder = "/orange"
                break
            case "critical":
                colorFolder = "/red"
                break
            default:
                break
        }
    }

    switch (deviceType) {
        case 'not_defined':
            label = "Not defined"
            icon = <HelpIcon className="icon" />
            svgPathUrl = "images/icons/help-marker.svg"
            break;
        case 'fire_extinguisher_prox_sensor':
            label = "Fire Extinguisher Prox Sensor"
            icon = <FireplaceIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/fireplace-24px.svg"
            break;
        case 'fire_extinguisher_no_prox_sensor':
            label = "Fire Extinguisher No Prox Sensor"
            icon = <FireplaceIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/fireplace-24px.svg"
            break;
        case 'open_rei_door':
            label = "Open Rei Door"
            icon = <MeetingRoomIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/meeting_room-24px.svg"
            break;
        case 'closed_rei_door':
            label = "Closed Rei Door"
            icon = <MeetingRoomIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/meeting_room-24px.svg"
            break;
        case 'open_closed_rei_door':
            label = "Open/Closed Rei Door"
            icon = <MeetingRoomIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/meeting_room-24px.svg"
            break;
        case 'emergency_exit':
            label = "Emergency Exit"
            icon = <ExitToAppIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/exit_to_app-24px.svg"
            break;
        case 'above_below_ground_hydrant':
            label = "Above/Below Ground Hydrant"
            icon = <InvertColorsIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/invert_colors-24px.svg"
            break;
        case 'hydrant':
            label = "Below Ground Hydrant"
            icon = <InvertColorsIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/invert_colors-24px.svg"
            break;
        case 'rei_shutter':
            label = "Rei Shutter"
            icon = <MeetingRoomIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/meeting_room-24px.svg"
            break;
        case 'fire_engine_connection':
            label = "Fire Engine Connection"
            icon = <FireplaceIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/fireplace-24px.svg"
            break;
        case 'smoke_evacuator':
            label = "Smoke Evacuator"
            icon = <SmokeFreeIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/smoke_free-24px.svg"
            break;
        case 'filter_room_pressurizer':
            label = "Filter room pressurized"
            icon = <FullscreenExitIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/fullscreen_exit-24px.svg"
            break;
        case 'emergency_lights':
            label = "Emergency Lights"
            icon = <WbIncandescentIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/wb_incandescent-24px.svg"
            break;
        case 'smoke_detector':
            label = "Smoke Detector"
            icon = <SmokeFreeIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/smoke_free-24px.svg"
            break;
        case 'fire_equipment_cabinet':
            label = "Fire Equipment Cabinet"
            icon = <FireplaceIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/fireplace-24px.svg"
            break;
        case 'first_aid_kit':
            label = "First Aid Kit"
            icon = <LocalHospitalIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/local_hospital-24px.svg"
            break;
        case 'fire_fighting_station':
            label = "Fire Fighting Station"
            icon = <FireplaceIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/fireplace-24px.svg"
            break;
        case 'sprinkler_bell':
            label = "Sprinkler Bell"
            icon = <NotificationsActiveIcon className="icon" />
            svgPathUrl = "images/icons" + colorFolder + "/notifications_active-24px.svg"
            break;
        default:
            label = "No deviceType"
            svgPathUrl = "images/icons/map-marker-green.svg"
            break;
    }
    return {
        icon,
        svg: svgPathUrl,
        label,
        defaultValues
    }
}
