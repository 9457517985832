import * as React from 'react';
import { CellParams } from '@material-ui/x-grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      height: 26,
    },
    value: {
      position: 'absolute',
      lineHeight: '24px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    bar: {
      height: '100%',
      '&.low': {
        backgroundColor: '#f44336',
      },
      '&.medium': {
        backgroundColor: '#efbb5aa3',
      },
      '&.high': {
        backgroundColor: '#088208a3',
      },
    },
  }),
);

interface ProgressBarProps {
  value: number;
}

const ProgressBar = React.memo(function ProgressBar(props: ProgressBarProps) {
  const { value } = props;
  const formattedValue = value;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.value}>{`${formattedValue.toLocaleString()} mBar`}</div>
    </div>
  );
});

export function renderPressure(params: CellParams) {
  return <ProgressBar value={Number(params.value)!} />;
}
