import * as React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '98%',
            border: `1ch solid ${theme.palette.divider}`,
            height: '10ch',
            margin: '0ch 0ch 0ch 0ch',
            marginTop: '1ch',
            marginRight: '0ch',
            marginBottom: '1ch',
            marginLeft: '0ch',
            position: 'relative',
            borderRadius: '1ch',
            boxSizing: 'border-box',
            '-moz-box-sizing': 'border-box',
            '-webkit-box-sizing': 'border-box',
            display: 'flex',
            alignItems: 'center', /* vertical */
            justifyContent: 'center', /* horizontal */

            '&::before': {
                right: '-2ch',
                width: '1ch',
                height: '100%',
                content: '""',
                display: 'block',
                position: 'absolute',
                background: `${theme.palette.divider}`,
                borderRadius: '0 6px 6px 0',
            },
            '&::after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: '-1px',
                left: '-1px',
                right: '-1px',
                bottom: '-1px',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '2px'
            }
        },
        value: {
            position: 'absolute',
            zIndex: 2,
            display: 'flex',
            justifyContent: 'center',
            textAnchor: 'middle',
            fontFamily: `'Roboto', sans-serif`,
            fontSize: '21px',
            fill: 'rgb(255, 255, 255)',
            color: 'rgb(255, 255, 255)',
            margin: 'auto'
        },
        bar: {
            height: '100%',
            position: 'absolute',
            bottom: '0px',
            left: 0,
            right: 0,
            '-webkit-transition': 'max-width 0.7s 0s ease',
            '-moz-transition': 'max-width 0.7s 0s ease',
            '-o-transition': 'max-width 0.7s 0s ease',
            'transition': 'max-width 0.7s 0s ease',
        },
    }),
);

const percentColors = [
    { pct: 0, color: { r: 0xEA, g: 0x42, b: 0x28 } },
    { pct: 50, color: { r: 0xF5, g: 0xCD, b: 0x19 } },
    { pct: 100, color: { r: 0x5B, g: 0xE1, b: 0x2C } }
];

const getColorForPercentage = (pct: number) => {
    for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break;
        }
    }
    const lower = percentColors[i - 1];
    const upper = percentColors[i];
    const range = upper.pct - lower.pct;
    const rangePct = (pct - lower.pct) / range;
    const pctLower = 1 - rangePct;
    const pctUpper = rangePct;
    const color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    // or output as hex if preferred
};

interface BatteryProps {
    value: string;
    percentage: number;
    height: number | undefined;
    border: number | undefined;
    labelSize: number | undefined;
    labelColor: string | undefined;
}

const Battery = React.memo(function Battery(props: BatteryProps) {
    const { value, percentage, height, border, labelSize, labelColor } = props;
    const classes = useStyles();
    const theme = useTheme()

    return (
        <div
            className={clsx(classes.root, {
                before: {
                    right: `-${(border || 1) *2}ch`,
                    width: `${border}ch`,
                },
            })}
            style={{
                height: `${height}ch`,
                border: `${border}ch solid ${theme.palette.divider}`,
                margin: `${border}ch 0ch ${border}ch 0ch`,
                marginTop: `0ch`,
                marginBottom: `0ch`,
            }}

        >
            <div 
                className={classes.value}
                style={{
                    fontSize: `${labelSize}px`,
                    fill: labelColor,
                    color: labelColor,
                }}
                >{`${value.toLocaleString()}`}</div>
            <div
                className={clsx(classes.bar)}
                style={{
                    maxWidth: `${percentage}%`,
                    backgroundColor: getColorForPercentage(percentage)
                }}
            />
        </div>
    );
});

export function BatteryGauge(params: BatteryProps) {
    return <Battery
        percentage={Number(params.percentage)!}
        value={params.value!}
        height={Number(params.height)!}
        border={Number(params.border)!}
        labelSize={Number(params.labelSize)!}
        labelColor={params.labelColor!.toLocaleString()}
    />;
}